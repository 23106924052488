<template>
  <div class="shop-cart--menuItem--1WhClHl shop-cart--withPadding--3bdg_Yv">
    <router-link :to="{ name: 'shopcart' }" class="aItem">
      <span class="comet-icon comet-icon-shoppingcart32 shop-cart--shoppingCartIcon--d5W36TW">
        <svg style="fill: var(--nav-color1);" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
          <path d="M128.64 112.32a64 64 0 0 0 52.16 73.984l15.04 2.624c4.8 0.832 8.384 4.8 8.8 9.632L239.04 625.92a74.656 74.656 0 0 0 74.4 68.672h460.384a74.656 74.656 0 0 0 73.6-62.08l50.24-293.216a74.656 74.656 0 0 0-73.6-87.264H273.152l-4.736-58.56A74.656 74.656 0 0 0 206.72 125.856l-78.08-13.504z m149.664 203.68h545.76c6.624 0 11.616 5.952 10.528 12.48l-50.24 293.216a10.656 10.656 0 0 1-10.528 8.864H313.44a10.656 10.656 0 0 1-10.624-9.792l-24.512-304.768zM280.384 800a53.344 53.344 0 1 0 106.688 0 53.344 53.344 0 0 0-106.688 0z m455.648 53.344a53.344 53.344 0 1 1 0-106.688 53.344 53.344 0 0 1 0 106.688z"></path>
        </svg>
      </span>
      <div class="shop-cart--text--i3avR3g">
        <div class="shop-cart--numberWrap--1vGN8le">
          <span class="shop-cart--number--axE62FE">0</span>
        </div>
        <b>{{ $t('_zddy._gwc') }}</b>
      </div>
      <div class="shop-cart--responsive--NnGUleD">
        <span class="shop-cart--number--axE62FE">0</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style></style>
