<template>
  <div class="store-info--wrap--BAKuRHf" id="nav-store">
    <div class="pdp-wrap">
      <div class="store-info--box--RlJ28Xa" style="background: rgb(245, 245, 245)">
        <div class="store-info--body--sg3UMmA">
          <router-link :to="{ name: 'storeHome', query: { id: store.id } }" class="store-info--logo--Xt7v1WL" target="_blank" :style="'background-image: url(' + store.avatar + ')'"> </router-link>
          <div class="store-info--content--qaMWQbD">
            <div class="store-info--name--E2VWTyv">
              <a target="_blank" data-pl="store-name">{{ store.name }}</a>
              <!-- 等级 -->
              <div>
                <!-- <div class="lev-bg" v-if="store.level > 10">
                      <div v-for="(vo, k) in store.level - 10" :key="k">
                        <img src="@/static/level/c.png" />
                      </div>
                    </div> -->
                <div class="lev-bg" v-if="store.level > 5">
                  <div v-for="(vo, k) in store.level - 5" :key="k">
                    <img src="@/static/level/c.png" />
                  </div>
                </div>
                <div class="lev-bg" v-else>
                  <div v-for="(vo, k) in store.level" :key="k">
                    <img src="@/static/level/b.png" />
                  </div>
                </div>
              </div>
            </div>
            <div class="store-info--desc--lBC4CUb">
              <!-- <strong>95.1%</strong> <span> {{$t('_xbb._zmfk')}}</span> | -->
              <strong>{{ store.follow_str }}</strong>
              <span> {{$t('_xbb._gzrs')}}</span>
            </div>
          </div>
        </div>
        <div class="store-info--operate--i3UCpC4">
          <!-- 未关注 -->
          <div class="store-info--btn--K2MQhmH" v-if="store.is_follow == 1" @click="onFollow()">
            <span class="comet-icon comet-icon-add">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path>
              </svg>
            </span>
            &nbsp;
            <span data-spm-anchor-id="a2g0o.detail.0.i0.1a63kZBkkZBkxS">{{$t('_xbb._gzrsan')}}</span>
          </div>
          <!-- 已关注 -->
          <div class="store-info--btn--K2MQhmH" v-if="store.is_follow == 2" @click="onFollow()">
            <span class="comet-icon comet-icon-wishlistfilled">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M165.589333 578.005333c-83.584-83.584-83.584-219.093333 0-302.677333 83.584-83.584 219.093333-83.584 302.677334 0l43.797333 43.797333 43.797333-43.797333c83.562667-83.584 219.093333-83.584 302.656 0 83.584 83.562667 83.584 219.093333 0 302.656l-323.84 323.84a32 32 0 0 1-45.226666 0l-323.84-323.84z"></path>
              </svg>
            </span>
            &nbsp;<span data-spm-anchor-id="a2g0o.detail.0.i0.1a63kZBkkZBkxS">{{$t('_xbb._ygzrsan')}}</span>
          </div>

          <!-- 联系他 -->
          <a class="store-info--btn--K2MQhmH" target="_blank" @click="onMessage(store.sid, goods_id)">
            <span class="comet-icon comet-icon-message" style="font-size: 24px">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M422.208 436.650667h-158.272v64h90.026667c37.674667 0 68.266667-28.650667 68.266666-64M571.178667 473.28a42.666667 42.666667 0 1 1-85.333334 0 42.666667 42.666667 0 1 1 85.333334 0zM715.925333 473.28a42.666667 42.666667 0 1 1-85.333333 0 42.666667 42.666667 0 1 1 85.333333 0z"></path>
                <path d="M96 215.893333a64 64 0 0 1 64-64h704a64 64 0 0 1 64 64v488.853334a64 64 0 0 1-64 64H640.938667l-234.688 126.016a32 32 0 0 1-47.146667-28.202667v-97.813333H160a64 64 0 0 1-64-64V215.893333z m64 0v488.853334h231.104a32 32 0 0 1 32 32v76.309333l194.645333-104.512c4.650667-2.496 9.856-3.797333 15.146667-3.797333H864V215.893333h-704z"></path>
              </svg>
            </span>
            &nbsp;
            <span>{{$t('_xbb._fsxx')}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    store_id: {
      type: Number,
      default: 0
    },
    goods_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      store: {}
    }
  },

  created() {
    let that = this
    this.$watch('store_id', () => {
      that.getStoreInfo(this.store_id)
    })
  },
  methods: {
    getStoreInfo(store_id) {
      // 获取店铺信息
      let that = this
      this.$api.storeInfo.getStoreInfo({ id: store_id }).then((dat) => {
        that.store = dat.data.store
        if (dat.data.content) {
          that.storeContent = dat.data.content
        }
        //顶部店铺
        // that.$refs.SearchBox.setStore(dat.data.store)
      })
    },
    /**
     * 添加到喜欢列表
     */
    onFollow() {
      if (!this.$common.isLogin()) {
        this.$message.warning('Please log in before contacting the merchant')
        return
      }

      let info = this.$common.getInfo()

      if (info.type == 2) {
        this.$message.warning('Unable to contact fellow merchants')
        return
      }

      let that = this
      this.$message.loading({ content: 'follow store...', key: 'msg' })
      this.$api.user
        .saveFollow({
          store_id: this.store.id
        })
        .then((res) => {
          //设置当前喜欢状态
          that.store.is_follow = res.data.code
          that.store.follow_str = res.data.num
          //提示成功
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
        })
    },
    onMessage(code, goods_id) {
      if (!this.$common.isLogin()) {
        this.$message.warning('Please log in before contacting the merchant')
        return
      }

      let info = this.$common.getInfo()

      if (info.type == 2) {
        this.$message.warning('Unable to contact fellow merchants')
        return
      }

      this.$router.push({ name: 'message', query: { code: code, goods_id: goods_id } })
    }
  }
}
</script>

<style>
.lev-bg {
  display: flex;
  gap: 3px;
  /* margin-left: 10px; */
}

.lev-bg img{
  width: 18px;
}
</style>
<style scoped src="@/static/css5/index/2.css"></style>
<style scoped src="@/static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="@/static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="@/static/css5/detail/214.css"></style>
<style scoped src="@/static/css5/detail/navigation.css"></style>
