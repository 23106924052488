<template>
  <!-- 推荐滚动商品栏 -->
  <!-- <div class="t1" v-if="recommend.length > 0">Recommend</div> -->
  <div class="bk01 nbg1" v-if="recommend.length > 0">
    <div class="sfeq">
      <div style="font-size: 3rem; font-weight: 700">
        {{ store.name }}
      </div>
    </div>
    <div class="rax-view-v2 h5-rax-fan-carousel--bottom_mask--12pQBu4"></div>
    <div class="slick-slider slick-initialized bk02">
      <button type="button" class="slick-arrow slick-prev sl-prev-g">Previous</button>
      <div class="slick-list">
        <div class="slick-track">
          <swiper :modules="modules" :spaceBetween="10" :slidesPerView="4" :pagination="{ el: '.banner-slick-dots', type: 'custom', renderCustom: onRenderCustom }" :navigation="{ nextEl: '.sl-next-g', prevEl: '.sl-prev-g' }" :loop="true" :autoplay="{ delay: 5000, disableOnInteraction: false }" class="bk03">
            <swiper-slide v-for="(item, i) in recommend" :key="i" style="padding: 0 5px">
              <div :class="[i % 2 == 0 ? 'donghua' : 'donghua2', 'slick-slide slick-active slick-current']" style="outline: none; width: 100%; height: 400px">
                <div class="gi-item" @click="$router.push({ name: 'detail', query: { id: item.id } })">
                  <div class="gi-img">
                    <img :src="item.goods.img" alt="" />
                  </div>
                  <div class="gi-price">US ${{ item.goods.price }}</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <button type="button" data-role="none" class="slick-arrow slick-next sl-next-g">Next</button>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      recommend: [],

      store: {},
      content: {},
      week: 0
    }
  },
  created() {
    this.getStoreRecommend()
    this.getStoreInfo()
  },
  methods: {
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    getStoreRecommend() {
      this.$api.storeInfo.getStoreRecommend({ id: this.store_id }).then((res) => {
        this.recommend = res.data.recommend
      })
    },
    getStoreInfo() {
      this.$api.storeInfo.getStoreInfo({ id: this.store_id }).then((res) => {
        this.store = res.data.store
        this.content = res.data.content
        this.week = res.data.week
      })
    }
  }
}
</script>
<style scoped src="@/static/css/index.css"></style>
<style scoped src="../../static/css5/store/1.css"></style>
<style scoped>
.t1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: #b78b1f;
  font-weight: 700;
  padding-top: 20px;
  line-height: 100px;
}
.bk01 {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  /* background-color: #f4f4f4; */
  position: relative;
  padding: 100px 0 50px 0;
}

.bk02 {
  width: 1600px;
}

.gi-item {
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

.gi-img {
  overflow: hidden;
  height: 100%;
}

.gi-img img {
  width: auto;
  height: 100%;
}

.gi-info {
  height: 30%;
}

.gi-name {
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  padding: 0 15px;
  overflow: hidden;
}

.gi-price-box {
  display: flex;
  padding: 0 0px;
  align-items: center;
  justify-content: center;
}

.gi-price {
  font-size: 15px;
  font-weight: 700;
  color: #020202;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #51f09573;
  padding: 5px 10px;
  border-radius: 10px;
}

.gi-cost-price {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  margin-left: 5px;
}

.gi-dist {
  padding: 0px 5px;
  background-color: #62c5c5;
  color: #fff;
  border-radius: 15px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.gi-btn {
  margin-top: 5px;
  cursor: pointer;
}

.gibox {
  background: linear-gradient(358deg, #e9c02b 5%, #f1d774 95%);
  width: 100px;
  padding: 3px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.nbg1 {
  background-image: url('../../static/template/nbg1.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 700px;
}

@keyframes h5-rax-fan-carousel--example--w0pJYkv {
  0% {
    transform: translateY(0) rotate(4.35deg);
  }
  25% {
    transform: translateY(-10px) rotate(4.35deg);
  }
  50% {
    transform: translateY(0) rotate(4.35deg);
  }
  75% {
    transform: translateY(10px) rotate(4.35deg);
  }
  to {
    transform: translateY(0) rotate(4.35deg);
  }
}

.donghua {
  animation: h5-rax-fan-carousel--example--w0pJYkv 3s linear infinite;
  margin: 10px 0;
  border-radius: 24px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

@keyframes h5-rax-fan-carousel--example2--2WMVLtQ {
  0% {
    transform: translateY(0) rotate(-4.35deg);
  }
  25% {
    transform: translateY(10px) rotate(-4.35deg);
  }
  50% {
    transform: translateY(0) rotate(-4.35deg);
  }
  75% {
    transform: translateY(-10px) rotate(-4.35deg);
  }
  to {
    transform: translateY(0) rotate(-4.35deg);
  }
}

.donghua2 {
  animation: h5-rax-fan-carousel--example2--2WMVLtQ 3s linear infinite;
  margin: 10px 0;
  border-radius: 24px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.bk03 {
  padding: 20px 0;
}

.sfeq {
  position: absolute;
  top: 5%;
  left: 10%;
}
</style>
