<template>
  <div>
    <div class="title--wrap--NWOaiSp">
      <div class="title--text--Otu0bLr">
        <h2 class="title--title--O6xcB1q">{{ $t('detail.top.selling.products') }}</h2>
      </div>
    </div>
    <div style="">
      <div data-spm="pcDetailTopMoreOtherSeller" data-spm-max-idx="12">
        <div class="slick-slider _2vJwD _1mKg7 slick-initialized" dir="ltr">
          <button type="button" data-role="none" class="slick-arrow slick-prev slick-prev-default gd-prev" style="display: block">Previous</button>
          <div class="slick-list">
            <!-- <div class="slick-track" style="opacity: 1;"> -->
            <swiper :modules="modules" :slidesPerGroup="1" :slidesPerView="6" :spaceBetween="0" :loopFillGroupWithBlank="true" :navigation="{ nextEl: '.gd-next', prevEl: '.gd-prev' }" :loop="true" class="slick-track">
              <swiper-slide v-for="(item, i) in topSellingList" :key="i">
                <div data-index="0" class="slick-slide slick-active slick-current" tabindex="-1" aria-hidden="false" style="outline: none; width: 202px">
                  <div>
                    <div class="_3D2WI" tabindex="-1" style="width: 100%; display: inline-block">
                      <div class="_1Hxqh">
                        <div class="SeJ8l -_1Kdtt card--out-wrapper" style="height: 280px">
                          <router-link class="_1UZxx _3uAF4 slider-card-item _1umT1" :to="{ name: 'detail', query: { id: item.id } }">
                            <div class="_2bIiW _2xJR2">
                              <img class="_1IH3l product-img" :src="item.goods && item.goods.img" />
                              <div class="_8Ut19"></div>
                              <!-- <div class="darm7 _1kiOX">
                                <div class="_1E-TZ">
                                  <span class="HQGC-"></span>
                                </div>
                              </div> -->
                            </div>
                            <div class="_11nFI">
                              <div class="G7dOC" style="margin-top: 8px">
                                <h3 class="nXeOv">{{ item.goods && item.goods.name }}</h3>
                              </div>
                              <div class="_3TqP9">
                                <div class="t9_CA">
                                  <div class="Ktfxu" v-for="star in parseInt(item.star)" :key="star">
                                    <div class="_2E4Wz" dir="ltr" style="width: 10px"></div>
                                    <img
                                      class="EYoOU"
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAAE6DSy/AAAABGdBTUEAALGPC/xhBQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAACCgAwAEAAAAAQAAAB4AAAAA2L2NEgAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAArdJREFUSA2tl81qFFEQhSeJEDEYQYT4g+DCEFwoPoQbA7pwl1UIeQ5fIA/gIyQEJDggKG7VbESEIEQ0hmyjO0FBicTvdN+6Vt++PdOdTkFN1a0659Ttnv6ZGRz/t6tKB2F9OMBiQQuzSUssThQwWyn6gnJRLjjAmxrCxgotWzGZclnu67qTqKcRWW8V6pV9G5j4PuJdUelGsj6uHOgEJqZApuBHPLQicdvlY1Ivl0LVK0YQ13LNotakQH1XvbhJ8kUVZEHtSVQoy/VPG0tntd4tK+mXGXFGtkhjOTZdIoFNA3WNcM/aDqa7koVHYGgCtqm5NkKAd4wQz3IgXmkjAOZ2xJmSYiyGhNIS/hmvDGL9GC8sPQSrW7xG8tIWuThKYNV2lCNarVHAyIqAV4yQxiaBZS8QRFJusc4JvE3JQeABjMNURQKXcoQ2NbgbbXCNGARuaQfDRsSIBrxpPD7RX4/A1lrwZkWW+ZO4U0NmCnDmCmb48AK1SznDF36tr8DdJoHd3MRczQv4u2zdgwFN4roTl3w9zb3AX2tC0ttH63l8nbVs0fo+Vl4+vjEmP6C/wLvqj3CI65H4Cb+BdzJ/BG2J+wDnbbhI5L9Vw9XrZF038BV1HflROiXUFqgL09q6bOALqtnhNs1tQthWZtfAL9AfcBH3XNxD9AfrExvXxyzkm8H1NSlXvIOf1wXU9k0G/nStmM3HED9zutLj1TQTf+GfZa8onBtP7YfQDFyzCvMbsNo7kov9xtTZ0sSlXbHcBgxw4h9t6XgEN000jaNuw+epUI91o5bdhqm2nuMz3IJ6wvU2jlqP6p/4VCrWdAa22gxHeEqeiqbroLWV1rVu2sCzHJhhOmOP8I+4fgHokXykHFNNveLfVIb/NFMr/3aJndg31vfwGfw+vo13NXH0Y/AyLq3veM3+AXQngyvegYcWAAAAAElFTkSuQmCC"
                                      width="10"
                                      height="10"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <span class="Ktbl2">{{ item.orders }} {{ $t('detail.orders') }}</span>
                              </div>
                              <div class="_1okBC">
                                <div class="U-S0j">
                                  <span style="font-size: 12px">$</span>
                                  <span style="font-size: 24px">{{ item.goods.price }}</span>
                                  <!-- <span style="font-size: 12px">.</span>
                                          <span style="font-size: 12px">07</span> -->
                                </div>
                                <div class="_1zEQq"></div>
                              </div>
                              <div class="T8SnT">
                                <span class="_1BSEX _3dc7w _1jUmO" title="This product is hot selling" style="color: rgb(253, 56, 79)">This product is hot selling</span>
                              </div>
                              <!-- <div class="T8SnT">
                                        <img aria-hidden="true" dir="" class="_1lYat _1jUmO" src="https://ae01.alicdn.com/kf/S7cd3a73cdfeb490bafdde288e0cb04bds/173x32.png" height="16" width="86" />
                                        <span class="_1juYg"></span>
                                        <img aria-hidden="true" dir="" class="_1lYat _1jUmO" src="https://ae01.alicdn.com/kf/S2fc9bb93b3a44cf0bdb96f76b3cee13bf/174x64.png" height="16" width="42.5" />
                                      </div> -->
                              <!-- <div class="_3vRdz">
                                        <span class="_1BSEX _3dc7w _1Z6Rx" title="Free shipping" style="color: rgb(25, 25, 25)">Free shipping</span>
                                      </div> -->
                            </div>
                            <div class="eFkEW rcmd-hover-more-action">
                              <div class="_3W0hl"><span title="See preview" class="_3bWs9">See preview</span></div>
                              <div class="_3W0hl"><span title="Similar items" class="_3bWs9">Similar items</span></div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <!-- </div> -->
          </div>
          <button type="button" data-role="none" class="slick-arrow slick-next slick-next-default gd-next" style="display: block">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
export default {
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      //置顶商品推荐
      topSellingList: []
    }
  },
  created() {
    let that = this
    this.$watch('store_id', () => {
      that.getTopSellingList(this.store_id)
    })
  },
  methods: {
    getTopSellingList(store_id) {
      //获取销量商品
      let that = this
      this.$api.goods.getTopSellingList({ store_id: store_id }).then((dat) => {
        that.topSellingList = dat.data
      })
    }
  }
}
</script>

<style></style>
<style scoped src="@/static/css5/index/2.css"></style>
<style scoped src="@/static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="@/static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="@/static/css5/detail/214.css"></style>
<style scoped src="@/static/css5/detail/navigation.css"></style>
