<template>
  <div v-for="(vo, vi) in groupGoods" :key="vi" class="common-floor" style="padding-top: 32px; padding-bottom: 0" data-spm="bigsave" data-component-id="9748127" data-floor-name="viva">
    <div class="row-container--root--33oSY1n">
      <div class="viva--box--1BT1PaC">
        <!-- 左侧 -->
        <a class="viva--titleW--3jOat-E" :style="'background-image:url(' + bgurl[vi] + ');background-size: 100% 100%;'" href="javascript:" target="_blank">
          <span class="viva--logo--27UVz2-">
            <!-- <img src="https://ae01.alicdn.com/kf/S706a075860a045888df0e2ab2923374dk/10x10.png" alt="" /> -->
          </span>
          <!-- <div class="viva--fTitle--1IWObWg">Christmas Specials</div> -->
          <div class="viva--arrowP--2qrQUwm">
            <div class="magic-pocket--arrowBox--2M5VtdA viva--arraw--3l4iNRb" style="background: #ffffff80">
              <img src="@/static/css5/svg/S3347f8236df444fbb7ab8b25afd3d992J.svg" alt="icon" />
            </div>
          </div>
        </a>
        <!-- 右侧商品 -->
        <div class="viva--pWrap--2iLGiQV">
          <div class="loop-container--loopWrap--3ZcoevZ viva--loop--39AN7wO">
            <div :class="['loop-container--loopBtn--3gWQkZj loop-container--prev--ICO9fI1', `gg-prev_${vi}`]"></div>
            <div class="es--root--3PUGUTI loop-container--container--3Jbe2M5" style="display: flex; flex-direction: row">
              <swiper :modules="modules1" :slidesPerGroup="4" :slidesPerView="4" :spaceBetween="10" :navigation="{ nextEl: `.gg-next_${vi}`, prevEl: `.gg-prev_${vi}` }" :loop="true" style="width: 100%; height: 100%">
                <swiper-slide v-for="(item, i) in vo" :key="i">
                  <div class="viva--pItem--2ukiYZz">
                    <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                      <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f viva--image--yXXLDF5" title="">
                        <img class="rc-modules--image--juUYhtJ" :src="item.img" :alt="item.name" />
                      </div>
                      <div class="viva--priceW--2Xh8MCn">
                        <span class="viva--priceMin--3NjAsCU rc-modules--price--1NNLjth" title="">
                          <span>$</span><span>{{ item.price }}</span>
                        </span>
                        <span class="viva--priceOrigin--I3bv3wG rc-modules--price--1NNLjth" title="">
                          <span>$</span><span>{{ item.cost_price }}</span>
                        </span>
                      </div>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div :class="['loop-container--loopBtn--3gWQkZj loop-container--next--3Piz8g5', `gg-next_${vi}`]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
export default {
  data() {
    return {
      modules1: [Navigation, Pagination],
      bgurl: [require('@/static/css5/imgs/a1.png'), require('@/static/css5/imgs/a2.png'), require('@/static/css5/imgs/a3.png')],
      groupGoods: []
    }
  },
  created() {
    this.getActivity()
  },
  methods: {
    getActivity() {
      //获取活动
      this.$api.home
        .getActivity({
          key: ['group_goods']
        })
        .then((res) => {
          this.groupGoods = res.data
        })
    }
  }
}
</script>

<style></style>
