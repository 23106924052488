<template>
  <div class="review--wrap--U5X0TgT" id="nav-review" data-spm="1000024">
    <!-- 没有评论 -->
    <template v-if="commentInfo.avg == '0.0'">
      <div class="title--wrap--NWOaiSp">
        <div class="title--text--Otu0bLr">
          <h2 class="title--title--O6xcB1q">{{ $t('_xbb._khpl') }} (0)</h2>
        </div>
      </div>
      <div>{{ $t('_xbb._myrpl') }}</div>
      <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>
    </template>
    <template v-else>
      <div class="title--wrap--NWOaiSp">
        <div class="title--text--Otu0bLr">
          <h2 class="title--title--O6xcB1q">{{ $t('_xbb._khpl') }} ({{ commentInfo.total }})</h2>
        </div>
      </div>
      <div>
        <div class="header--wrap--BgjROgu">
          <div class="header--box--HKhV4Wt">
            <div class="header--blockWrap1--S_r1OlE">
              <div class="header--block1--fkqnhnV">
                <div class="header--num--XJ6wKJ5">{{ commentInfo.avg }}</div>
                <div class="header--star--DyVGXQd">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 27px" v-for="star in commentInfo.avg_str" :key="star">
                      <svg style="fill: var(--m-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                    <span class="comet-icon comet-icon-starhalfreview" style="font-size: 27px" v-if="commentInfo.decimal > 0">
                      <svg style="fill: var(--m-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M512 204.992v-0.021333l84.266667 184.32a53.333333 53.333333 0 0 0 41.6 30.72l181.952 23.68-131.882667 142.698666a53.333333 53.333333 0 0 0-13.290667 45.738667l33.792 185.642667-172.8-85.397334A53.333333 53.333333 0 0 0 512 726.826667V205.013333z m48.512-47.850667c-18.986667-41.557333-78.016-41.557333-97.024 0l-91.498667 200.170667-196.544 25.6c-42.922667 5.589333-61.653333 57.28-32.277333 89.088l142.293333 153.92-36.650666 201.258667c-7.872 43.136 36.778667 76.8 76.096 57.386666L512 792.064l187.093333 92.458667c39.296 19.413333 83.946667-14.229333 76.096-57.365334L738.56 625.92l142.272-153.92c29.376-31.786667 10.645333-83.498667-32.298667-89.088l-196.522666-25.6-91.52-200.170667z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <!-- <div class="header--numText--plcx4m_">All from verified purchases</div> -->
              </div>
            </div>
            <div class="header--blockWrap2--QzjtGBH">
              <div class="header--block2--Eb5M0XL">
                <!-- 5星 -->
                <div class="header--progressWrap--pP3MjOb">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="header--progressBox--qgVUii4"><div class="header--progressRate--p4AIVxD" :style="'width:' + commentInfo?.s5?.rate + '%'"></div></div>
                  <div class="header--progressNum--WdikB1F">{{ commentInfo?.s5?.num }}</div>
                </div>
                <!-- 4星 -->
                <div class="header--progressWrap--pP3MjOb">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div class="header--progressBox--qgVUii4"><div class="header--progressRate--p4AIVxD" :style="'width:' + commentInfo?.s4?.rate + '%'"></div></div>
                  <div class="header--progressNum--WdikB1F">{{ commentInfo?.s4?.num }}</div>
                </div>
                <!-- 3星 -->
                <div class="header--progressWrap--pP3MjOb">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div class="header--progressBox--qgVUii4"><div class="header--progressRate--p4AIVxD" :style="'width:' + commentInfo?.s3?.rate + '%'"></div></div>
                  <div class="header--progressNum--WdikB1F">{{ commentInfo?.s3?.num }}</div>
                </div>
                <!-- 2星 -->
                <div class="header--progressWrap--pP3MjOb">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div class="header--progressBox--qgVUii4"><div class="header--progressRate--p4AIVxD" :style="'width:' + commentInfo?.s2?.rate + '%'"></div></div>
                  <div class="header--progressNum--WdikB1F">{{ commentInfo?.s2?.num }}</div>
                </div>
                <!-- 1星 -->
                <div class="header--progressWrap--pP3MjOb">
                  <div class="stars--box--vHzUWQ9">
                    <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path></svg></span
                    ><span class="comet-icon comet-icon-starreview" style="font-size: 16px"
                      ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path
                          d="M512 204.970667l-84.266667 184.32a53.333333 53.333333 0 0 1-41.6 30.72l-181.952 23.68 131.882667 142.698666a53.333333 53.333333 0 0 1 13.290667 45.738667l-33.792 185.642667 172.8-85.397334a53.333333 53.333333 0 0 1 47.274666 0l172.8 85.397334-33.792-185.642667a53.333333 53.333333 0 0 1 13.290667-45.76L819.84 443.733333l-181.930667-23.701333a53.333333 53.333333 0 0 1-41.621333-30.72L512 204.970667z m-48.512-47.829334c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div class="header--progressBox--qgVUii4"><div class="header--progressRate--p4AIVxD" :style="'width:' + commentInfo?.s1?.rate + '%'"></div></div>
                  <div class="header--progressNum--WdikB1F">{{ commentInfo?.s1?.num }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="filter--wrap--OOoCWDA" style="max-height: unset">
          <div>
            <div class="filter--filterItem--WGNBYnm filter--active--hfp934x">All(20)</div>
            <div class="filter--filterItem--WGNBYnm">Pic review(0)</div>
            <div class="filter--filterItem--WGNBYnm">Additional review(1)</div>
            <div class="filter--filterItem--WGNBYnm">Local review(0)</div>
            <div class="filter--filterItem--WGNBYnm">5 stars(19)</div>
            <div class="filter--filterItem--WGNBYnm">4 stars(2)</div>
            <div class="filter--filterItem--WGNBYnm">3 stars(0)</div>
            <div class="filter--filterItem--WGNBYnm">2 stars(0)</div>
            <div class="filter--filterItem--WGNBYnm">1 star(1)</div>
            <div class="filter--filterItem--WGNBYnm">quality is excellent(3)</div>
            <div class="filter--filterItem--WGNBYnm">like it(2)</div>
            <div class="filter--filterItem--WGNBYnm">Beautiful(1)</div>
            <div class="filter--filterItem--WGNBYnm">good(1)</div>
            <div class="filter--filterItem--WGNBYnm">Top(1)</div>
            <div class="filter--filterItem--WGNBYnm">higher quality(1)</div>
          </div>
        </div> -->
        <div class="filter--bottom--YCE3he3" style="margin-top: 32px">
          <button type="button" class="comet-v2-btn comet-v2-btn-link filter--sort--PWBW3oZ comet-v2-btn-important">
            <span>
              Sort by default<span class="comet-icon comet-icon-chevrondown" style="font-size: 16px">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                  <path d="M296.256 354.922667l224 224 224-224a74.666667 74.666667 0 0 1 0 105.6l-197.589333 197.610666a37.333333 37.333333 0 0 1-52.8 0l-197.589334-197.610666a74.666667 74.666667 0 0 1 0-105.6z"></path>
                </svg>
              </span>
            </span>
          </button>
          <!-- <div class="filter--translate--tfeB5bx">Show original translate</div> -->
        </div>
        <div class="list--wrap--KBzXADx">
          <div class="list--box--L2Vw5GB">
            <!-- 评论边框 -->
            <div class="list--itemWrap--TMqSVfA" v-for="(item, i) in commentList" :key="i">
              <div class="list--itemBox--zF4Z5NT">
                <div class="list--itemPhoto--KYC7RkG">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="list--itemContent--yx8Cwlj">
                  <div>
                    <div class="stars--box--vHzUWQ9">
                      <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg>
                      </span>
                      <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg>
                      </span>
                      <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg>
                      </span>
                      <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg>
                      </span>
                      <span class="comet-icon comet-icon-starreviewfilled" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path></svg>
                      </span>
                    </div>
                    <div class="list--itemSku--o3EjCHG" style="display: flex">
                      <div v-for="(svo, sk) in item.detail && item.detail.sku" :key="sk" style="margin-left: 10px">{{ sk }}:{{ svo }}</div>
                    </div>
                    <div class="list--itemReview--hBFPNly">{{ item.comment }}</div>
                    <div class="list--itemThumbnails--KZo9BN_"></div>
                    <div class="list--itemThumbnails--KZo9BN_"></div>
                  </div>
                  <dd class="r-photo-list" data-role="photo-list" v-if="item.imgs.length > 0">
                    <ul class="util-clearfix">
                      <li :class="['pic-view-item', img[i] == k ? 'active' : '']" v-for="(vo, k) in item.imgs" :key="k" @click="onImg(i, k)">
                        <img :src="vo" />
                      </li>
                    </ul>
                    <div class="big-photo-view" v-if="img[i] >= 0" style="display: inline-block">
                      <a href="javascript:;" class="close-view" data-role="close" @click="onImgClose(i)"></a>
                      <div class="photo-view-main">
                        <div class="photo-view-content" data-role="photo-view" data-curr-index="">
                          <img :src="item.imgs[img[i]]" />
                        </div>
                        <a href="javascript:;" class="fb-arrow fb-left-arrow" data-role="prev"></a>
                        <a href="javascript:;" class="fb-arrow fb-right-arrow" data-role="next"></a>
                      </div>
                    </div>
                  </dd>
                  <div class="list--itemDesc--qDy1zbp">
                    <div class="list--itemInfo--fb1A_M1">{{ item.nickname }} | {{ item.create_time }}</div>
                    <!-- <div class="list--itemHelp--W_KfMB2">
                      <span class="comet-icon comet-icon-like list--itemHelpIcon--Zdn2GWF" style="font-size: 24px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path
                            d="M450.261333 169.28A74.666667 74.666667 0 0 1 517.034667 128H533.333333a74.666667 74.666667 0 0 1 74.666667 74.666667v170.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h228.138666a74.666667 74.666667 0 0 1 73.92 85.226667l-51.818666 362.666666A74.666667 74.666667 0 0 1 794.986667 896H202.666667A74.666667 74.666667 0 0 1 128 821.333333v-362.666666A74.666667 74.666667 0 0 1 202.666667 384h133.632a10.666667 10.666667 0 0 0 9.536-5.888l104.426666-208.853333zM517.034667 192a10.666667 10.666667 0 0 0-9.536 5.888l-104.426667 208.853333A74.666667 74.666667 0 0 1 336.298667 448H202.666667a10.666667 10.666667 0 0 0-10.666667 10.666667v362.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h592.32a10.666667 10.666667 0 0 0 10.56-9.173333l51.818666-362.666667a10.666667 10.666667 0 0 0-10.56-12.16H618.666667a74.666667 74.666667 0 0 1-74.666667-74.666667v-170.666666A10.666667 10.666667 0 0 0 533.333333 192h-16.298666z"
                          ></path>
                          <path d="M298.666667 768V512a64 64 0 0 1 64 64v192h-64z"></path>
                        </svg>
                      </span>
                      &nbsp;
                      <span class="list--itemHelpText--cvon2jc">Helpful (0)</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="loadMore()" type="button" class="comet-v2-btn comet-v2-btn-slim comet-v2-btn-large comet-v2-btn-important" style="display: block; margin: 32px auto 0px; min-width: 160px">
          <span data-spm-anchor-id="a2g0o.detail.1000024.i0.639b3qel3qeljb">{{ $t('_xbb._llgd') }}</span>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    goods_id: {
      type: Number,
      default: 0
    }
  },
  emits: ['setReviewsNum'],
  data() {
    return {
      commentInfo: {},
      commentList: [],

      //选中查看
      img: {},

      page: 1
    }
  },
  created() {
    let that = this
    that.getCommentInfo()
    that.getCommentList(1)
  },
  methods: {
    /**
     * 评论详情
     */
    getCommentInfo() {
      let that = this
      this.$api.goods
        .getCommentInfo({
          id: this.goods_id
        })
        .then((res) => {
          this.commentInfo = res.data
          that.$emit('setReviewsNum', this.commentInfo.total)
        })
    },
    getCommentList(page) {
      //获取商品
      // let hide = this.$message.loading({ content: "loading...", key: "msg" });
      this.$api.goods
        .getCommentList({
          id: this.goods_id,
          page: page,
          size: 6
        })
        .then((res) => {
          // hide();
          this.page++
          res.data.data.forEach((item) => {
            this.commentList.push(item)
          })
        })
    },
    onImg(i, k) {
      this.img[i] = k
    },
    onImgClose(i) {
      delete this.img[i]
    },
    loadMore() {
      this.getCommentList(this.page)
    }
  }
}
</script>

<style></style>
<style scoped src="@/static/css5/index/2.css"></style>
<style scoped src="@/static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="@/static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="@/static/css5/detail/214.css"></style>
<style scoped src="@/static/css5/detail/navigation.css"></style>

<style scoped src="@/static/css/extend.css"></style>
<style scoped src="@/static/css/index-new.css"></style>
<style scoped>
.comet-icon svg {
  fill: var(--m-color1);
}
</style>
