import request from '@/utils/request'

const api = {
  getDocs: 'docs/getDocs',
  getDocsAll: 'docs/getDocsAll',
  getDocsCompany: 'docs/getDocsCompany',
  getDocsByGroup: 'docs/getDocsByGroup',
  getSetting: 'docs/getSetting'
}

export default {
  getDocs(parameter) {
    return request({
      url: api.getDocs,
      method: 'post',
      data: parameter
    })
  },
  getDocsAll(parameter) {
    return request({
      url: api.getDocsAll,
      method: 'post',
      data: parameter
    })
  },
  getDocsCompany(parameter) {
    return request({
      url: api.getDocsCompany,
      method: 'post',
      data: parameter
    })
  },
  getDocsByGroup(parameter) {
    return request({
      url: api.getDocsByGroup,
      method: 'post',
      data: parameter
    })
  },
  getSetting(parameter) {
    return request({
      url: api.getSetting,
      method: 'post',
      data: parameter
    })
  }
}
