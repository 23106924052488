<template>
  <div :style="{ padding: '0 20px' }">
    <div class="nav-header">
      <span class="nv-title">{{ $t('store.dashboard') }}</span>
      <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
    </div>
  </div>
  <!-- 店铺信息 -->
  <a-row>
    <div style="padding: 20px 24px 8px; width: 100%; margin-bottom: 10px; background-color: #fff; height: 200px">
      <div style="display: flex">
        <div class="sd-head">
          <div class="sd-avatar">
            <img :src="store.avatar" alt="" />
          </div>
          <div style="margin-left: 10px">
            <!-- 店铺名称 -->
            <div class="sd-hbox">
              <div class="sd-name">
                {{ store.name }}
              </div>
              <div class="sd-level">Lv{{ store.level }}</div>
            </div>
            <!-- SID -->
            <div class="sd-hbox">
              <div>SID:{{ store.sid }}</div>
            </div>
            <!-- 等级 -->
            <div>
              <div class="lev-bg" v-if="store.level > 5">
                <div v-for="(vo, k) in store.level - 5" :key="k">
                  <img src="@/static/level/c.png" />
                </div>
                <div v-for="(vo, k) in 5 - (store.level - 5)" :key="k">
                  <img src="@/static/level/c1.png" />
                </div>
              </div>
              <div class="lev-bg" v-else>
                <div v-for="(vo, k) in store.level" :key="k">
                  <img src="@/static/level/b.png" />
                </div>
                <div v-for="(vo, k) in store.level != undefined && 5 - store.level" :key="k">
                  <img src="@/static/level/b1.png" />
                </div>
              </div>
            </div>
            <!--  实名认证 -->
            <div class="sd-head">
              <div class="c-title">{{ $t('auth.smrz') }}:</div>
              <div style="">
                <div v-if="store.auth == 0">
                  <div class="sd-auth-0">{{ $t('auth.wrz') }}</div>
                </div>
                <div v-if="store.auth == 1">
                  <div class="sd-auth-1">{{ $t('auth.shz') }}</div>
                </div>
                <div v-if="store.auth == 2">
                  <div class="sd-auth-2">{{ $t('auth.ytg') }}</div>
                </div>
                <div v-if="store.auth == 3">
                  <div class="sd-auth-3">{{ $t('auth.ybh') }}</div>
                </div>
              </div>
              <div v-if="store.auth == 0 || store.auth == 3" class="go-auth" @click="onAuth()">{{ $t('credit.qrz') }}</div>
            </div>
          </div>
        </div>
        <div style="padding-left: 69px">
          <!-- 权益等级 -->
          <div class="sd-head">
            <div class="c-title">{{ $t('store.qydj') }}:</div>
            <div style="margin-left: 10px" class="sd-bg">{{ store.level }} {{ $t('store.level') }}</div>
            <!-- <div class="go-auth" @click="$router.push({ name: 'storeBenefit' })">{{ $t('credit.qts') }}</div> -->
            <div style="margin-left: 10px">
              <a-popover>
                <template #content>
                  <div style="display: flex">
                    <div style="display: flex; color: #8bc34a">
                      <div>
                        {{ $t('desc.dqqy') }}:
                        <!-- <p style="white-space: nowrap">1.{{ $t('desc.sjqy_0') }}: ${{ current.now.credit }}</p> -->
                        <p style="white-space: nowrap">1.{{ $t('desc.sjqy_1') }}: {{ current.now.dividends }}%</p>
                        <p style="white-space: nowrap">2.{{ $t('desc.sjqy_2') }}: {{ current.now.goodsNum == 99999 ? $t('store.wxz') : current.now.goodsNum }}</p>
                      </div>
                    </div>
                    <div style="display: flex; color: #f44336; margin-left: 15px">
                      <div>
                        {{ $t('desc.xjqy') }}:
                        <!-- <p style="white-space: nowrap">1.{{ $t('desc.sjqy_0') }}: ${{ current.next.credit }}</p> -->
                        <p style="white-space: nowrap">1.{{ $t('desc.sjqy_1') }}: {{ current.next.dividends }}%</p>
                        <p style="white-space: nowrap">2.{{ $t('desc.sjqy_2') }}: {{ current.next.goodsNum == 99999 ? $t('store.wxz') : current.next.goodsNum }}</p>
                      </div>
                    </div>
                  </div>
                </template>
                <info-circle-outlined color="#F44336" />
              </a-popover>
            </div>
          </div>
          <!-- 信用额度 -->
          <div class="sd-head" hidden>
            <div class="c-title">{{ $t('credit.title') }}:</div>
            <div style="margin-left: 10px" class="sd-bg">$ {{ credit.max }}</div>
          </div>
          <!-- 店铺分红 -->
          <div class="sd-head">
            <div class="c-title">{{ $t('store.dpfh') }}:</div>
            <div style="margin-left: 10px" class="sd-bg">{{ dividends.max }} %</div>
            <div style="margin-left: 10px" v-if="dividends.is_allow == 1">
              <a-tooltip>
                <template #title>
                  <div style="display: flex; color: #ff0015">
                    <div>
                      <p style="white-space: nowrap">{{ $t('setting.fhbky') }}</p>
                    </div>
                  </div>
                </template>
                <info-circle-outlined :style="{ color: '#ff0015' }" />
              </a-tooltip>
            </div>
          </div>
          <!-- 商品限额 -->
          <div class="sd-head">
            <div class="c-title">{{ $t('store.spss') }}:</div>
            <div style="margin-left: 10px" class="sd-bg">
              <span>{{ goods.current }} </span> / <span>{{ goods.max == 99999 ? $t('store.wxz') : goods.max }}</span> {{ $t('store.jian') }}
            </div>
          </div>
        </div>
        <div style="padding-left: 69px">
          <!-- 关注量 -->
          <div class="sd-head">
            <div class="c-title">{{ $t('store.gzl') }}:</div>
            <div style="margin-left: 10px">{{ store.follow }}</div>
          </div>
          <!-- 店铺状态 -->
          <a-tooltip>
            <template #title>{{ store.operate_status && operate_status[store.operate_status].desc }} <img width="25" height="25" :src="require('@/static/status/' + (store.operate_status && store.operate_status) + '.png')" /></template>
            <div class="sd-head">
              <div class="c-title">{{ $t('store.st.zt') }}:</div>
              <div :style="'margin-left: 10px; font-weight: 700;' + (store.operate_status ? 'color:' + operate_status[store.operate_status].color + ';' : '')">{{ store.operate_status && operate_status[store.operate_status].text }}</div>
            </div>
          </a-tooltip>
        </div>
      </div>
    </div>
  </a-row>
  <div style="display: flex; gap: 10px; margin-bottom: 10px">
    <!-- 产品总数 -->
    <div class="d-box">
      <chart-card :loading="loading" :title="$t('dashboard.product.total')" :total="product.total" style="width: 100%">
        <template #action>
          <a-tooltip>
            <template #title>product</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <template #content> </template>
        <template #footer>
          <div>{{ $t('dashboard.product.today') }}:</div>
          <div style="margin-left: 10px">{{ product.today }}</div>
        </template>
      </chart-card>
    </div>
    <!-- 访问量 -->
    <div class="d-box">
      <chart-card :loading="loading" :title="$t('dashboard.store.today.views')" :total="view.total" style="width: 100%">
        <template #action>
          <div>
            <router-link :to="{ name: 'storeAdvertiseFlow' }" style="margin-right: 10px">{{ $t('_index._gm') }}>></router-link>
            <a-tooltip>
              <template #title>{{ $t('_index._sm') }}</template>
              <info-circle-outlined />
            </a-tooltip>
          </div>
        </template>
        <template #content>
          <div id="view" style="width: 100%; height: 100%"></div>
        </template>
        <template #footer>
          <div>{{ $t('dashboard.store.hour.views') }}:</div>
          <div style="margin-left: 10px">{{ view.today }}</div>
        </template>
      </chart-card>
    </div>
    <!-- 总订单 -->
    <div class="d-box">
      <chart-card :loading="loading" :title="$t('dashboard.order.total')" :total="order.total" style="width: 100%">
        <template #action>
          <a-tooltip>
            <template #title>order</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <template #content>
          <div id="order" style="width: 100%; height: 100%"></div>
        </template>
        <template #footer>
          <div style="display: flex">
            <span style="flex: 1">{{ $t('member.order.unpaid') }}: {{ order.unpaid }}</span>
            <span style="flex: 1">{{ $t('member.order.procurement') }}: {{ order.purchase }}</span>
            <span style="flex: 1">{{ $t('member.order.processing') }}: {{ order.processing }}</span>
            <span style="flex: 1">{{ $t('member.order.shipped') }}: {{ order.shipped }}</span>
            <span style="flex: 1">{{ $t('member.order.completed') }}: {{ order.completed }}</span>
          </div>
        </template>
      </chart-card>
    </div>
    <!-- 总收益 -->
    <div class="d-box">
      <chart-card :loading="loading" :title="$t('dashboard.commission.total')" :total="'$ ' + profit.total" style="width: 100%">
        <template #action>
          <a-tooltip>
            <template #title>prompt text</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <template #content>
          <div id="profit" style="width: 100%; height: 100%"></div>
        </template>
        <template #footer>
          <div>{{ $t('dashboard.commission.today') }}:</div>
          <div style="margin-left: 10px">$ {{ profit.today }}</div>
        </template>
      </chart-card>
    </div>
  </div>
  <a-row>
    <div style="width: 100%; margin-bottom: 10px">
      <!-- 营业额统计 -->
      <div class="d-box">
        <chart-card :loading="loading" :title="$t('dashboard.sales.total')" :total="'$ ' + sales.total" style="width: 100%">
          <template #action>
            <a-tooltip>
              <template #title>prompt text</template>
              <info-circle-outlined />
            </a-tooltip>
          </template>
          <template #content>
            <div id="sales" style="width: 100%; height: 100%"></div>
          </template>
          <template #footer>
            <div style="display: flex; gap: 10px">
              <div>
                {{ $t('dashboard.sales.real') }}:<span style="margin-left: 5px">$ {{ sales.real_total }}</span>
              </div>
              <div>
                {{ $t('dashboard.sales.pay') }}:<span style="margin-left: 5px">$ {{ sales.purchase }}</span>
              </div>
              <div>
                {{ $t('dashboard.sales.profit') }}:<span style="margin-left: 5px">$ {{ sales.profit }}</span>
              </div>
            </div>
          </template>
        </chart-card>
      </div>
    </div>
  </a-row>
  <!-- 销售额柱形图 -->
  <a-row>
    <a-col :sm="12" :md="12" :xl="24" :style="{ marginBottom: '24px' }">
      <a-card :bodyStyle="{ padding: '0px 0px 0px 0px' }">
        <div style="display: flex">
          <div style="flex: 1; padding-right: 50px">
            <div class="box-title">
              {{ $t('dashboard.order.sales') }}
            </div>
            <div style="padding: 24px">
              <div class="meta"></div>
              <div style="height: 250px">
                <div id="orderChart" style="width: 100%; height: 100%"></div>
              </div>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
  </a-row>
  <!-- 销售额柱形图 -->
  <!-- <a-row>
    <a-col :sm="12" :md="12" :xl="24" :style="{ marginBottom: '24px' }">
      <a-card :bodyStyle="{ padding: '0px 0px 0px 0px' }">
        <div style="display: flex">
          <div style="width: 100%">
            <div class="box-title">
              {{ $t("dashboard.sales.list") }}
            </div>
            <div class="cate-goods" style="padding-top: 0">
              <ul>
                <li v-for="(item, i) in goods.goods" :key="i">
                  <div class="box-main">
                    <div class="box-left">
                      <div :class="[i < 3 ? 'active' : '', 's-title']">
                        {{ i + 1 }}
                      </div>
                      <div class="title">{{ item.name }}</div>
                    </div>
                    <div>{{ item.total_num }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
  </a-row> -->
  <!-- 商品分类饼图 -->
  <a-row>
    <a-col :sm="24" :md="24" :xl="24" :style="{ marginBottom: '24px' }">
      <div class="cg-box" style="display: flex">
        <div class="cg-item" style="flex: 1; margin-right: 20px">
          <a-card :bodyStyle="{ padding: '0px 0px 0px 0px' }">
            <div style="display: flex">
              <div style="flex: 1; padding-right: 50px">
                <div class="box-title">
                  {{ $t('dashboard.goods.cate.rate') }}
                </div>
                <div style="padding: 24px">
                  <div class="meta"></div>
                  <div style="height: 260px">
                    <div id="categoryChart" style="width: 100%; height: 100%"></div>
                  </div>
                </div>
              </div>
            </div>
          </a-card>
        </div>
        <div class="cg-item" style="width: 50%">
          <a-card :bodyStyle="{ padding: '0px 0px 0px 0px' }">
            <div style="display: flex">
              <div style="width: 100%">
                <div class="box-title">
                  {{ $t('dashboard.goods.wish') }}
                </div>
                <div class="cate-goods" style="padding-top: 0">
                  <ul>
                    <li v-for="(item, i) in wish" :key="i">
                      <div class="box-main">
                        <div class="box-left">
                          <div :class="[i < 3 ? 'active' : '', 's-title']">
                            {{ i + 1 }}
                          </div>
                          <div class="title">{{ item.name }}</div>
                        </div>
                        <div>{{ item.wish_num }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { ChartCard } from '@/components/store/dashboard'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import * as echarts from 'echarts'
export default {
  name: 'walletVue',
  components: {
    ChartCard,
    // Trend,
    InfoCircleOutlined
  },
  data() {
    return {
      operate_status: {
        1: {
          color: '#4CAF50',
          text: this.$t('store.st.zc'),
          desc: this.$t('store.st.zc.desc')
        },
        2: {
          color: '#FFC107',
          text: this.$t('store.st.yc'),
          desc: this.$t('store.st.yc.desc')
        },
        3: {
          color: '#5fd5b6',
          text: this.$t('store.st.dj'),
          desc: this.$t('store.st.dj.desc')
        },
        4: {
          color: '#F44336',
          text: this.$t('store.st.jy'),
          desc: this.$t('store.st.jy.desc')
        }
      },
      loading: false,
      //pieData,
      //pieScale,
      //产品
      product: { total: 0, today: 0 },
      order: { total: 0, today: 0 },
      profit: { total: 0, today: 0 },
      sales: {
        total: 0,
        unsettled: 0,
        pending: 0,
        settled: 0
      },
      wish: {},
      view: {},

      store: {},
      credit: {},
      dividends: {},
      goods: {},
      current: {}
    }
  },
  created() {
    const that = this
    // 获取产品
    this.$api.store.getProduct().then((res) => {
      that.product = res.data
    })

    // 获得访问量
    this.$api.store.getOrder().then((res) => {
      that.order = res.data
    })

    // 获得收益
    this.$api.store.getProfit().then((res) => {
      that.profit = res.data
    })

    // 获得营业额
    this.$api.store.getSales().then((res) => {
      this.sales = res.data
    })

    // 商品排行
    this.$api.store.getWishGoods().then((res) => {
      this.wish = res.data
    })

    //获取店铺信息
    this.$api.store.getStoreInfo().then((res) => {
      this.store = res.data
    })

    //获取店铺信息
    this.$api.store.getShopWallet().then((res) => {
      this.credit = res.data.credit
      this.dividends = res.data.dividends
      this.goods = res.data.goods
      this.current = res.data.current
    })
  },
  mounted() {
    const that = this

    // 访问量
    this.$api.store.getView().then((res) => {
      const data = res.data
      that.setInterview(data.title, data.value)
      that.view = {
        total: data.total,
        today: data.today
      }
    })

    this.$api.store.getChartData().then((res) => {
      const data = res.data
      that.setOrder(data.order.title, data.order.value)
      that.setProfit(data.profit.title, data.profit.value)
      that.setSales(data.sales.title, data.sales.value)
      that.setOrderChart(data.goods.title, data.goods.value)
      that.setCategoryChart(data.categorys)
    })

    // window.onresize = () => {
    //   myChart.resize()
    // }
  },
  methods: {
    onAuth() {
      this.$router.push({ name: 'storeAuth' })
    },
    /**
     * 订单量
     */
    setInterview(title, value) {
      // 基于准备好的dom，初始化echarts实例
      var myChartOrder = echarts.init(document.getElementById('view'))
      // 绘制图表
      const option1 = {
        color: '#5fd5b6',
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: title,
          //x轴指示器
          axisPointer: {
            type: 'line',
            show: true,
            label: {
              show: true,
              margin: -55,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: [
                10, // 上
                10, // 右
                10, // 下
                10 // 左
              ],
              formatter: function (params) {
                console.log(params)
                // 假设此轴的 type 为 'time'。
                return params.value + ' \nTotal: ' + params.seriesData[0].value
              }
            }
          }
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [
          {
            data: value,
            type: 'bar',
            areaStyle: {},
            symbol: 'none', //取消折线点
            smooth: true, //平滑
            lineStyle: {
              width: 0 //折线宽度
            }
          }
        ],
        // 撑满容器
        grid: {
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px'
        }
      }
      myChartOrder.setOption(option1)
    },
    /**
     * 订单量
     */
    setOrder(title, value) {
      // 基于准备好的dom，初始化echarts实例
      var myChartOrder = echarts.init(document.getElementById('order'))
      // 绘制图表
      const option1 = {
        color: '#5fd5b6',
        xAxis: {
          show: true,
          type: 'category',
          boundaryGap: false,
          data: title,
          //x轴指示器
          axisPointer: {
            type: 'line',
            show: true,
            label: {
              show: true,
              margin: -55,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: [
                10, // 上
                10, // 右
                10, // 下
                10 // 左
              ],
              formatter: function (params) {
                console.log(params)
                // 假设此轴的 type 为 'time'。
                return params.value + 'M \nTotal: ' + params.seriesData[0].value
              }
            }
          }
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [
          {
            data: value,
            type: 'line',
            areaStyle: {},
            symbol: 'none', //取消折线点
            smooth: true, //平滑
            lineStyle: {
              width: 0 //折线宽度
            }
          }
        ],
        // 撑满容器
        grid: {
          top: '0px',
          bottom: '17px',
          left: '10px',
          right: '10px'
        }
      }
      myChartOrder.setOption(option1)
    },
    /**
     * 销售额
     */
    setProfit(title, value) {
      // 基于准备好的dom，初始化echarts实例
      var myChartSales = echarts.init(document.getElementById('profit'))
      // 绘制图表
      const option2 = {
        color: '#5fd5b6',
        xAxis: {
          show: false,
          type: 'category',
          data: title,
          //x轴指示器
          axisPointer: {
            type: 'shadow',
            show: true,
            label: {
              show: true,
              margin: -55,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: [
                10, // 上
                10, // 右
                10, // 下
                10 // 左
              ],
              formatter: function (params) {
                console.log(params)
                // 假设此轴的 type 为 'time'。
                return params.value + 'M \nTotal: $' + params.seriesData[0].value
              }
            }
          }
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [
          {
            data: value,
            type: 'bar'
          }
        ],
        // 撑满容器
        grid: {
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px'
        }
      }
      myChartSales.setOption(option2)
    },
    /**
     * 佣金
     */
    setSales(title, value) {
      // 基于准备好的dom，初始化echarts实例
      var myChartCommission = echarts.init(document.getElementById('sales'))
      const option3 = {
        color: '#5fd5b6',
        xAxis: {
          show: false,
          type: 'category',
          data: title,
          //x轴指示器
          axisPointer: {
            type: 'line',
            show: true,
            label: {
              show: true,
              margin: -50,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: [
                15, // 上
                10, // 右
                15, // 下
                10 // 左
              ],
              formatter: function (params) {
                console.log(params)
                // 假设此轴的 type 为 'time'。
                return params.value + 'M \nTotal: $' + params.seriesData[0].value
              }
            }
          }
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [
          {
            data: value,
            type: 'line',
            smooth: true,
            symbol: 'none', //取消折线点
            lineStyle: {
              width: 3 //折线宽度
            }
          }
        ],
        // 撑满容器
        grid: {
          top: '0px',
          bottom: '5px',
          left: '0px',
          right: '0px'
        }
      }

      myChartCommission.setOption(option3)
    },
    /**
     * 销量统计图
     */
    setOrderChart(title, value) {
      const month = {
        1: this.$t('dashboard.january'),
        2: this.$t('dashboard.february'),
        3: this.$t('dashboard.march'),
        4: this.$t('dashboard.april'),
        5: this.$t('dashboard.may'),
        6: this.$t('dashboard.june'),
        7: this.$t('dashboard.july'),
        8: this.$t('dashboard.august'),
        9: this.$t('dashboard.september'),
        10: this.$t('dashboard.october'),
        11: this.$t('dashboard.november'),
        12: this.$t('dashboard.december')
      }
      for (var i in title) {
        title[i] = month[title[i]]
      }

      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('orderChart'))
      // 绘制图表
      const option = {
        color: '#5fd5b6',
        xAxis: {
          show: true,
          type: 'category',
          data: title,
          //x轴指示器
          axisPointer: {
            type: 'shadow',
            show: true,
            label: {
              show: true,
              margin: -55,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: '#444',
              padding: [
                15, // 上
                10, // 右
                15, // 下
                10 // 左
              ],
              formatter: function (params) {
                console.log(params)
                // 假设此轴的 type 为 'time'。
                return params.value + ' \nTotal: ' + params.seriesData[0].value
              }
            }
          }
        },
        yAxis: {
          show: true,
          type: 'value',
          //x轴指示器
          axisPointer: {
            type: 'line',
            show: false
          }
        },
        series: [
          {
            data: value,
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: '{@value}'
            }
          }
        ],
        // 撑满容器
        grid: {
          top: '20px',
          bottom: '25px',
          left: '35px',
          right: '10px'
        }
      }
      myChart.setOption(option)
    },
    /**
     * 商品分类饼图
     */
    setCategoryChart(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('categoryChart'))
      // 绘制图表
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} <br/>{c} ({d}%)'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 'left',
          textStyle: {
            overflow: 'truncate',
            width: 200
          }
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '80%'],
            center: ['70%', '50%'],
            selectedMode: 'single',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 12,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
<style scoped src="@/static/css/store-css.css"></style>
<style lang="less" scoped>
.box-title {
  border-bottom: 1px solid #ebebeb;
  padding: 20px 24px;
  font-size: 16px;
}
.chart-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  .meta {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
}

.chart-number {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 38px;
  height: 38px;
}

.chart-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.meta {
  position: relative;
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
}

.box-main {
  display: flex;
  align-items: center;
}
.box-left {
  display: flex;
  width: 90%;
}

.cate-goods {
  padding: 24px;
}

.cate-goods li {
  list-style: none;
  margin-top: 16px;
}

.cate-goods .title {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
}

.cate-goods .s-title:first-child {
  background-color: #f5f5f5;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  padding: 6px;
  display: flex;
  align-items: center;
}
.cate-goods .s-title.active {
  background-color: #314659;
  color: #fff;
}

// .cate-goods li span:last-child {
//   float: right;
// }

.d-box {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
}

.sd-head {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.sd-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
}
.sd-avatar img {
  width: 100%;
  height: 100%;
}

.sd-name {
  width: 200px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sd-hbox {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.sd-level {
  padding: 0px 5px;
  background-color: var(--bg-color1);
  color: var(--font-color1);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.sd-bg {
  padding: 0px 5px;
  background-color: var(--bg-color1);
  color: var(--font-color1);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  margin-left: 10px;
}

.sd-auth-0 {
  // padding: 0px 5px;
  // background-color: #f7f7f7;
  border-radius: 5px;
  font-size: 15px;
  color: #ababab;
  // border: 1px solid #f7f7f7;
  margin-left: 10px;
}

.sd-auth-1 {
  // padding: 0px 5px;
  // background-color: #fff2d0;
  border-radius: 5px;
  font-size: 15px;
  color: #bb741c;
  // border: 1px solid #fff2d0;
  margin-left: 10px;
}

.sd-auth-2 {
  // padding: 0px 5px;
  // background-color: #d0ffd3;
  border-radius: 5px;
  font-size: 15px;
  color: #1cbb6a;
  // border: 1px solid #c2ffe7;
  margin-left: 10px;
}

.sd-auth-3 {
  // padding: 0px 5px;
  // background-color: #ffd0d0;
  border-radius: 5px;
  font-size: 15px;
  color: #bb1c1c;
  // border: 1px solid #ffd0d0;
  margin-left: 10px;
}

.go-auth {
  font-size: 15px;
  margin-left: 10px;
  color: var(--font-color1);
  text-decoration: underline;
  cursor: pointer;
}

.c-title {
  font-size: 15px;
  font-weight: 700;
}
</style>
