<template>
  <div class="description--wrap--LscZ0He" id="nav-description">
    <div class="title--wrap--NWOaiSp description--title--NMh4UiD">
      <div class="title--text--Otu0bLr">
        <h2 class="title--title--O6xcB1q">{{ $t('_xbb._mswa') }}</h2>
      </div>
      <!-- <a class="description--report--_8LzMSh" href="https://report.aliexpress.com/health/reportIndex.htm?product_id=1005007407996842&amp;b_login_id=cn1083984334nupae" target="_blank" rel="nofollow noopener noreferrer" clk_trigger="" ae_page_type="detail_page" ae_button_type="detail_report_detail" st_page_id="rxlthylkqgcasoeu191ef3ad3b610d16914fc2174c" data-aplus-clk="x1_1245d926" data-spm-anchor-id="a2g0o.detail.0.0">Report Item</a> -->
    </div>
    <div>
      <div class="extend--content--VuIQZia" :style="viewMore ? '' : 'max-height: 1200px'">
        <div>
          <div id="product-description" data-spm="1000023" class="description--product-description--Mjtql28" data-pl="product-description">
            <div class="description--origin-part--rWy05pE" v-html="content"></div>
          </div>
        </div>
      </div>
      <button type="button" class="comet-v2-btn comet-v2-btn-slim comet-v2-btn-large extend--btn--TWsP5SV comet-v2-btn-important" style="min-width: 160px" @click="onMore()" v-if="!viewMore">
        <span>{{ $t('_xbb._llgd') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viewMore: false
    }
  },
  methods: {
    onMore() {
      this.viewMore = true
    }
  }
}
</script>

<style></style>
<style scoped src="@/static/css5/index/2.css"></style>
<style scoped src="@/static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="@/static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="@/static/css5/detail/214.css"></style>
<style scoped src="@/static/css5/detail/navigation.css"></style>
