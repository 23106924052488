export default {
  'nav.account': 'Kişisel Merkez',
  'nav.language': 'Basitleştirilmiş Çince',
  'signup.success': 'Kayıt başarılı',

  //hata
  'contact.empty': 'İletişim bilgileri boş geçilemez',
  'phone.country.empty': 'Telefon öneki boş olamaz',
  'phone.empty': 'Telefon boş olamaz',
  'email.valid': 'Lütfen doğru e-postayı girin',

  // temel
  'base.success': 'başarı',
  'base.save': 'Kaydet',
  'base.slide': 'Doğrulamak için lütfen kaydırıcıyı çekin',
  'base.send.code': 'Doğrulama kodu gönder',
  'base.send.time': '({time}) doğrulama kodunu tekrar gönder',
  'base.send.success': 'Başarıyla gönder',
  'base.view.more': 'Daha fazlasını görüntüle',
  'base.Language': 'Dil',
  'base.previous': 'Önceki sayfa',
  'base.next': 'Sonraki sayfa',
  'base.total.page': 'Toplam {page} sayfa',
  'base.go.to.page': 'Sayfaya git',
  'base.go': 'git',
  'base.followers': 'Takipçiler',
  'base.follow': 'takip et',
  'base.following': 'Takip edildi',
  'base.visit.store': 'Mağazaya gözat',
  'base.contact': 'Bize ulaşın',
  'base.delivery': 'Tahmini teslim süresi: 1-7 gün',
  'base.search': 'Ara',
  'base.search.text': 'Anahtar kelime aramayı girin',
  'base.server': 'Çevrimiçi müşteri hizmetleri',

  //dizin
  'text.categories.title': 'Ürün Kategorisi',
  'text.welcome': 'Alışverişe hoş geldiniz!',
  'text.wishlist': 'Beğeni listesi',
  'text.account': 'Kişisel Merkez',
  'text.orders': 'Siparişler',
  'text.messages': 'Mesajlar',
  'text.suggest.title': 'Özel teklif',
  'text.suggest.content': 'Yalnızca yeni global premium üyeler için!',
  'text.super.deals': 'En iyi ürünler, inanılmaz fiyatlar.',

  //üye olmak
  'signup.register': 'Kaydol',
  'signup.signin': 'Giriş',
  'signup.store.signin': 'İş girişi',
  'signup.sign.out': 'Çıkış',
  'signup.email': 'E-posta adresi',
  'signup.password': 'Şifre',
  'signup.qr.password': 'Şifreyi onaylayın',
  'signup.confirm.password': 'Şifreyi Onaylayın',
  'signup.forgot.password': 'Şifremi unuttum',
  'signup.invitation': 'Davetiye kodu',
  'signup.char': 'Karakter',
  'signup.contains': 'Rakam, harf veya sembol içeriyor',
  'signup.qr.contains': 'İki şifre tutarsız',
  'signup.create.account': 'Hesap oluştur',
  'signup.agree': 'Hesap oluştur, kabul ediyorsun',
  'signup.member.agreement': 'Üyelik Sözleşmesi',
  'signup.and': 've',
  'signup.privacy.policy': 'Gizlilik Politikası',
  'signup.email.code': 'E-posta doğrulama',
  'signup.last.step': 'Son adım',
  'signup.send.email': 'Lütfen {email} adresine gönderilen 4 haneli kodu giriniz',
  'signup.modify.email': 'E-postayı değiştir',
  'signup.verify.email': 'E-postayı doğrula',
  'signup.have.store': 'Satıcı hesabı var',
  'signup.goto.signin': 'İş girişi',
  'signup.no.store': 'Satıcı hesabı yok',
  'signup.goto.store': 'Kayıt Tüccarı',
  'signup.next': 'Sonraki',
  'signup.your.email': 'e-postanız',
  'signup.code.text': 'Doğrulama kodu',
  'signup.submit.signup': 'Şimdi kaydolun',
  'signup.smrz': 'Gerçek ad kimlik doğrulaması',
  'signup.derb': 'İkinci adım',
  'signup.qsrxm': 'Lütfen belgenin adını girin',
  'signup.qsrhm': 'Lütfen kimlik numarasını giriniz',

  //unutmuş olmak
  'forgot.title': 'Şifreyi Sıfırla',
  'forgot.btn.check': 'E-postayı doğrula',
  'forgot.reset.now': 'Şimdi sıfırla',

  //mağaza
  'store.info.open': '{y} hafta açık',

  // altbilgi
  'footer.great.value': 'Büyük değer',
  'footer.great.value.desc': '100 milyondan fazla ürün için rekabetçi fiyatlar sunuyoruz.',
  'footer.shopping': 'Global Alışveriş',
  'footer.shopping.desc': "200'den fazla ülke ve bölgeye gönderi yapıyoruz ve web sitemiz 7 dilde hizmet veriyor.",
  'footer.safe.payment': 'Güvenli Ödeme',
  'footer.safe.payment.desc': 'Dünyanın en popüler ve en güvenli ödeme yöntemleriyle ödeme yapın.',
  'footer.shop.with.confidence': 'Güvenle alışveriş yapın',
  'footer.shop.with.confidence.desc': 'Alıcı koruma politikamız tüm satın alma sürecinizi kapsar.',
  'footer.help.center': 'Yardım Merkezi',
  'footer.help.center.desc': 'Sorunsuz bir alışveriş deneyimi yaratmak için tüm hava koşullarında yardım.',
  'footer.terms.conditions': 'Şartlar ve Koşullar',
  'footer.return.policy': 'İade Politikası',
  'footer.support.policy': 'Destek Politikası',
  'footer.privacy.policy': 'Gizlilik Politikası',
  'footer.be.seller': 'Satıcı ol',
  'footer.apply.now': 'Şimdi başvur',
  'footer.stay.connected': 'Bağlı kalın',

  'footer.about.us': 'Hakkımızda',
  'footer.about.company': 'Şirket Profili',
  'footer.about.video': 'Video Tanıtımı',
  'footer.contact': 'İletişim bilgileri',

  'footer.my.account': 'Hesabım',
  'footer.my.logout': 'Çıkış',
  'footer.my.order': 'Sipariş Geçmişi',
  'footer.my.wish': 'Dilek listem',
  'footer.my.join': 'Üye ortak olun',
  'footer.email': 'E-posta',
  'footer.gfemail': 'Resmi e-posta',
  'footer.fwemail': 'Hizmet E-postası',
  'footer.address': 'Adres',

  'apply.success': 'Uygulama başarılı',
  'apply.success.desc': 'Başarıyla uygulandı, oturum açma hesabına gidin',

  // kategoriler
  'category.title': 'İlgili kategoriler',
  'category.all': 'Tüm kategoriler',

  //detay
  'detail.store.home': 'Mağaza Ana Sayfası',
  'detail.sale.items': 'Ürün Listesi',
  'detay.recommend': 'Öner',
  'detay.orders': 'Satış',
  'detay.miktar': 'Miktar',
  'detail.pieces.available': 'Mevcut',
  'detay.delivery': 'Teslimat',
  'detail.free.shipping': 'Ücretsiz Gönderim',
  'detail.estimated.delivery': 'Tahmini varış',
  'detay.günler': 'günler',
  'detay.buy.now': 'Satın Al',
  'detail.add.to.cart': 'Sepete ekle',
  'detail.buyer.protection': 'Alıcı Koruması',
  'detail.money.guarantee': 'Para iade garantisi',
  'detail.refund.desc': 'Ürün açıklandığı gibi değilse veya teslim edilmemişse tam para iadesi alın',
  'detail.description': 'Ürün açıklaması',
  'detail.customer.reviews': 'Müşteri Yorumları',
  'detail.specations': 'Ürün Özellikleri',
  'detail.top.selling.products': 'En Çok Satan Ürünler',
  'detail.recommended.for.you': 'Sizin için önerilir',
  'detay.sold': 'Satış',
  'detail.receipt': 'Alındığını onayla',
  'detail.receipt.title': 'Siparişin alındığını onaylıyor musunuz? ',
  'detail.receipt.content': 'Onaylandıktan sonra işlem emri tamamlanır',
  'detay.comment': 'Yorum',
  'detail.refund.title': 'Geri ödeme başvurusunu onaylayın',
  'detail.refund.content': 'Onaylandıktan sonra sipariş iade için geçerli olacaktır',
  'detail.sqtk': 'Geri ödeme için başvur',

  //onaylamak
  'confirm.shipping.address': 'Alıcı adresi',
  'confirm.change': 'Değiştir',
  'confirm.payment.methods': 'Ödeme yöntemleri',
  'confirm.summary': 'Hesaplaşma',
  'confirm.total.item.costs': 'Toplam kalemler',
  'confirm.total': 'Toplam',
  'confirm.checkout': 'Ödemeye git',
  'confirm.place.order': 'Şimdi sipariş ver',
  'confirm.pay.now': 'Şimdi öde',
  'confirm.order.desc': "'Sipariş Ver'e tıkladıktan sonra okuduğumu ve onayladığımı onaylıyorum",
  'confirm.order.policy': 'Tüm şartlar ve politikalar',
  'confirm.payment': 'Global Shopping, bilgilerinizi ve ödeme güvenliğinizi sağlar',

  // adres
  'address.title': 'Makbuz adresi',
  'adres.varsayılan': 'varsayılan',
  'adres.edit': 'Düzenle',
  'adres.delete': 'Sil',
  'address.new': 'Yeni bir adres oluştur',
  'adres.iletişim': 'İletişim',
  'adres.adres': 'Adres',
  'adres.telefon': 'Telefon numarası',
  'address.set.default': 'Varsayılanı ayarla',
  'adres.onayla': 'Onayla',
  'adres.cancel': 'İptal',
  'address.del.title': 'Adresi silmek için onaylansın mı?',
  'address.del.content': 'Bu gönderim adresini silmek istediğinizden emin misiniz?',

  'payment.method': 'Ödeme yöntemi',

  // Alışveriş Sepeti
  'shopping.title': 'Alışveriş Sepeti',
  'shopping.back.buy': 'Özel alışverişe dön',
  'shopping.start.shopping': 'Alışverişe başla',

  // üye
  'member.account': 'Kişisel Merkez',
  'member.overview': 'Genel Bakış',
  'üye.siparişler': 'Siparişler',
  'member.payment': 'Cüzdanım',
  'member.address': 'Makbuz adresi',
  'member.wishlist': 'Beğeni listesi',
  'member.followlist': 'Takip listesi',
  'member.message.center': 'Mesaj Merkezi',
  'member.setting': 'Kişisel Ayarlar',
  'member.shop.info': 'Mağaza Bilgileri',
  'member.shop.setting': 'Mağaza Ayarları',
  'member.order.notify': 'Yeni sipariş bildirimi',
  'member.order.tips': 'Satıcı hesabı e-postasına e-posta göndererek',

  // üye.sipariş
  'member.order.title': 'Siparişim',
  'member.order.view.all': 'Tümünü Görüntüle',
  'member.order.all': 'Tümü',
  'üye.sipariş.ücretsiz': 'ücretsiz',
  'member.order.wed': 'Ücretli',
  'member.order.procurement': 'satın alınacak',
  'member.order.seller.made': 'Satın Alındı',
  'member.order.processing': 'işlenecek',
  'member.order.shipped': 'gönderildi',
  'member.order.return': 'Geri Dönüyor',
  'member.order.completed': 'Tamamlandı',
  'member.order.refund': 'İade Edildi',
  'member.order.all.time': 'hepsi',
  'member.order.empty': 'Henüz sipariş yok',
  'üye.sipariş.tarihi': 'tarih',
  'member.order.purchase.date': 'Satın Al',
  'member.order.cpoy': 'Kopyala',
  'member.order.id': 'Sipariş',
  'member.order.detail': 'Sipariş Detayları',
  'member.order.logistics': 'Lojistik bilgileri',

  // üye.ödeme
  'member.payment.title': 'Cüzdanım',
  'member.wallet.balance': 'cüzdan bakiyesi',
  'member.crypto.recharge': 'Yeniden şarj et',
  'member.crypto.withdrawal': 'Çekilme',
  'member.crypto.bank': 'Banka Kartı',
  'member.wallet.record': 'para yatırma kaydı',
  'member.bankcard.record': 'Banka kartı kaydı',
  'member.withdrawal.record': 'Çekilme kaydı',
  'member.income.record': 'gelir kaydı',
  'member.transaction.record': 'İşlem Kaydı',
  'member.wallet.freeze': 'Paraları dondur',
  'member.wallet.profit': 'Tahmini kazançlar',

  //şarj
  'recharge.currency': 'Para Birimi',
  'recharge.protocol': 'Protokol',
  'recharge.qrcode': 'QR kodu',
  'recharge.address': 'Cüzdan adresi',
  'recharge.copy': 'Adresi kopyala',
  'recharge.ok.text': 'Onayla',
  'recharge.cancel.text': 'İptal',

  //banka
  'bank.recharge.title': 'Banka kartı yükleme',
  'bank.title': 'Banka Adı',
  'bank.name': 'isim',
  'bank.account': 'Hesap',
  'bank.routing': 'Yönlendirme',
  'bank.code': 'kod',
  'bank.bankname': 'Banka adı',
  'bank.bankaddress': 'Banka adresi',
  'bank.ok.text': 'İleri',
  'bank.ok.prev': 'Önceki adım',
  'bank.submit': 'Şimdi gönder',
  'bank.amount': 'Yükleme tutarı',
  'bank.amount.desc': 'Lütfen yükleme tutarını giriniz',
  'bank.type': 'tür',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Fiş numarası',
  'bank.receipt.number.desc': 'İşlem fişi numarasını giriniz',
  'bank.credential.picture': 'Kimlik bilgisi resmi',
  'bank.credential.picture.desc': 'Lütfen kimlik bilgisi resmini yükleyin',
  'bank.remark': 'Açıklama',
  'bank.upload': 'Resim yükle',
  'bank.text.title': 'Notlar',
  'bank.text.t1': 'Havale için yoruma gerek yok, boş bırakın',
  'bank.text.t2': "Hong Kong hane halkının ülkesi olarak Hong Kong'u seçin, Çin'i eklemeyin",
  'bank.text.t3': "ABD havaleleri, hafta içi, Avrupa'ya saat 15:00'ten önce havaleler, banka çalışma saatlerinde mevcuttur!",
  'bank.text.t4': "Avrupa'da ödeme süresi T+1, son ödeme T+3'tür",
  'bank.text.t5': 'Hesabın müsait olup olmadığını doğrulamak için para göndermeden önce platformu bilgilendirin. Hesap kapatılırsa herhangi bir tazminat ödenmez.',
  'bank.text.t6': 'Banka kartı ile para yatırma. Müşteri hizmetleri bilgileri geçerli olacaktır.',

  // banka listesi
  'bank.list.title': 'Banka Adı',
  'bank.list.code': 'Banka Kodu',
  'bank.list.amount': 'Havale tutarı',
  'bank.list.number': 'Fiş numarası',
  'bank.list.img': 'Fiş resmi',
  'bank.list.status': 'Durum',
  'bank.list.time': 'zaman',
  'bank.list.status1': 'İnceleniyor',
  'bank.list.status2': 'Geçti',
  'bank.list.status3': 'Reddedildi',

  // para çekme
  'withdrawal.address.desc': 'Lütfen cüzdan adresini giriniz!',
  'çekilme.number': 'Numara',
  'withdrawal.real.number': 'gerçek varış',
  'withdrawal.number.desc': 'Lütfen para çekme tutarını giriniz!',
  'withdrawal.btn.all': 'hepsi',
  'drawal.balance': 'Bakiye',
  'drawal.commission': 'komisyon',
  'withdrawal.actual.amount': 'Hesaplandı',
  'withdrawal.notice': 'Sor',
  'withdrawal.notice.text': 'Aktarmadan önce lütfen alıcı adres bilgilerinin doğru olduğunu onaylayın. Varlıklar devredildikten sonra iade edilemez.',
  'withdrawal.notice.content': '{name}({cp_name}) komisyon: geçerli piyasa değeri {fee_rate} %/kalem, minimum standart: {fee_min} {ad}/kalem',
  'withdrawal.submit': 'Gönder',
  'withdrawal.choice': 'Bir kripto para birimi seçin',
  'withdrawal.yzm': 'Doğrulama kodu',
  'drawal.fs': 'Gönder',
  'withdrawal.qsryzm': 'Lütfen e-posta doğrulama kodunu girin',

  // al
  'recive.method': 'Protokol',
  'recive.amount': 'Tutar',
  'recive.address': 'adres',
  'recive.date': 'zaman',
  'recive.status': 'Durum',
  'recive.create.at': 'İşlem süresi',
  'recive.type': 'Tür',
  'recive.befor': 'işlemden önce',
  'recive.balance': 'Bakiye',
  'recive.freeze': 'Dondur',
  'recive.review': 'İnceleniyor',
  'recive.success': 'Geçti',
  'recive.reject': 'Reddedildi',

  // reklam ver
  'advertise.title': 'Tanıtım Merkezi',
  'advertise.shop.title': 'Mağaza Promosyonu',
  'advertise.shop.status': 'Durum',
  'advertise.shop.expired': 'Süresi doldu',
  'advertise.shop.promotion': 'Tanıtım',
  'advertise.shop.expire.date': 'Son kullanma tarihi',
  'advertise.shop.renew': 'Reklam yenileme',
  'advertise.shop.payable': 'Ödenecek tutar',
  'advertise.shop.explanation': 'Reklam açıklaması',
  'advertise.shop.text': 'Platform reklam tanıtımına katılın, mağaza gösterimini artırın ve işlem siparişlerini tanıtın',
  'advertise.shop.paynow': 'Şimdi Yenile',
  'advertise.shop.modal.title': 'Ödemeyi onayla',
  'advertise.shop.modal.desc': 'Promosyon ücretinin ödenmesi onaylandı',
  'advertise.shop.modal.btn': 'Ödemeyi onayla',

  //Dilek Listesi
  'wishlist.title': 'Dilek listesi',
  'wishlist.delete': 'Sil',
  'wishlist.orders': 'Satış',

  //Takip Listesi
  'followlist.title': 'Takip Listesi',
  'followlist.delete': 'Sil',
  'followlist.follow': 'Takip Et',

  // mağaza
  'store.dashboard': 'Gösterge Panosu',
  'store.products': 'Ürünler',
  'store.products.list': 'Ürün Listesi',
  'store.products.reviews': 'Ürün İncelemeleri',
  'store.orders': 'Siparişler',
  'store.wallet': 'Cüzdan',
  'store.message': 'Mesaj Merkezi',
  'store.setting': 'ayar',
  'store.order.total.profit': 'Tahmini toplam işletme geliri',

  //Gösterge Paneli
  'dashboard.store.hour.views': 'Gerçek zamanlı ziyaretler',
  'dashboard.store.today.views': 'Bugünün ziyaretleri',
  'dashboard.product.total': 'Toplam ürün',
  'dashboard.product.today': 'Bugün yeni',
  'dashboard.order.total': 'Toplam Sipariş',
  'dashboard.sales.total': 'Tahmini ciro',
  'dashboard.sales.real': 'Gerçek satışlar',
  'dashboard.sales.pay': 'Toplam Ödeme',
  'dashboard.sales.profit': 'Toplam Kâr',
  'dashboard.commission.total': 'Toplam gelir',
  'dashboard.commission.today': 'Bugünün kazançları',
  'dashboard.order.sales': 'Emtia Satışları',
  'dashboard.sales.list': 'Satış Sıralaması',
  'dashboard.goods.cate.rate': 'Ürün sınıflandırma oranı',
  'dashboard.goods.wish': 'Ürün sıralamasını beğen',
  'dashboard.january': 'Ocak',
  'dashboard.february': 'Şubat',
  'dashboard.march': 'Mart',
  'dashboard.april': 'Nisan',
  'dashboard.may': 'Mayıs',
  'dashboard.june': 'Haziran',
  'dashboard.july': 'Temmuz',
  'dashboard.august': 'Ağustos',
  'dashboard.september': 'Eylül',
  'dashboard.october': 'Ekim',
  'dashboard.november': 'Kasım',
  'dashboard.december': 'Aralık',

  // ürünler
  'products.add.new': 'Yeni ürün ekle',
  'products.add.from.warehouse': 'Depodan ürün ekle',
  'products.delete': 'Sil',
  'products.add': 'Ekle',
  'products.table.img': 'resim',
  'products.table.name': 'ürün adı',
  'products.table.category': 'Kategori',
  'products.table.wish': 'beğen',
  'products.table.stock': 'stok',
  'products.table.price': 'Fiyat',
  'products.table.profit': 'Kar',
  'products.table.action': 'İşlem',
  'products.search.category': 'Kategoriye göre ara',
  'products.back.product': 'Ürün listesine geri dön',
  'products.total': 'Toplam mal',
  'products.yes': 'Evet',
  'products.no': 'İptal',
  'products.batch.add': 'Toplu artış',
  'products.ask.add': 'Ürün eklemek istediğinizden emin misiniz?',
  'products.batch.delete': 'Toplu silme',
  'products.ask.delete': 'Ürünü silmek istediğinizden emin misiniz?',
  'products.top': 'Üst',
  'products.syzd': 'Ana Sayfa Üstü',
  'products.zdwz': 'Üst konum',
  'products.t1': 'Promosyon',
  'products.t2': 'Tercih edilen',
  'products.t3': 'Butik',
  'products.t4': 'yok',

  // incelemeler
  'reviews.title': 'Ürün İncelemeleri',
  'reviews.product.name': 'Ürün',
  'reviews.user.name': 'Kullanıcı',
  'reviews.star': 'Derecelendirme',
  'reviews.comment': 'Yorum içeriği',
  'reviews.sku': 'Ürün Özellikleri',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Tarih',

  // mağaza.sipariş
  'store.order.purchase': 'Şimdi satın al',
  'store.order.purchase.desc': 'Bu ürün için ödeme yapmak istediğinizden emin misiniz?',
  'store.order.purchase.yes': 'Şimdi öde',
  'store.order.purchase.no': 'İptal',
  'store.order.desc': 'Bu siparişi satın almak için ödeme yapılması gerekir ve avantajlardan sipariş tamamlandıktan sonra yararlanırsınız. ',
  'store.order.no': 'Sipariş numarası',
  'store.order.number': 'Miktar',
  'store.order.buyer': 'Alıcı',
  'store.order.total': 'Toplam tutar',
  'store.order.will.earning': 'Gelir',
  'store.order.profit': 'Kar',
  'store.order.temettü': 'Temettü',
  'store.order.payment.status': 'Ödeme Durumu',
  'store.order.seller.buy.status': 'Satın Alma Durumu',
  'store.order.status': 'Sipariş Durumu',
  'store.order.date': 'sipariş tarihi',
  'store.order.purchase.date': 'Satın alma tarihi',
  'store.order.action': 'İşlem',
  'store.order.purchase.amount': 'Satın alma tutarı',

  //gelir
  'income.create.at': 'Rekor zamanı',
  'income.order.sn': 'Sipariş numarası',
  'income.realpay': 'Sipariş Tutarı',
  'gelir.kar': 'gelir',

  //Ayar
  'setting.avatar': 'İş Avatarı',
  'setting.upload': 'Yükle',
  'setting.shop.name': 'Dükkan Adı',
  'setting.shop.phone': 'İrtibat Telefonu',
  'setting.shop.address': 'Mağaza Adresi',
  'setting.shop.save': 'Kaydet',
  'setting.upload.pic': 'Resim yükle',
  'setting.send.pic': 'Resim gönder',

  // satıcı
  'beseller.title': 'Tüccar olmak için başvur',
  'beseller.account': 'Hesap Bilgileri',
  'beseller.store': 'Mağaza Bilgileri',
  'beseller.store.name': 'Mağaza Adı',
  'beseller.store.address': 'Mağaza adresi',

  //evini kurtar
  'store.home.title': 'Ana Sayfa Ayarları',
  'store.home.topimg': 'üst resim',
  'store.home.banner': 'Karusel resmi',
  'store.home.up3': 'Açıklamalar: En az 3 yükleme',
  'store.upload.more': 'Daha fazla resim yükle',
  'store.home.columns': 'Sütunlar',
  'store.home.bgimg': 'Arka Plan Resmi',
  'store.goods.remark': 'Açıklama: Satır başına 5 öğe, tabak başına en fazla 10 öğe',
  'store.home.select': 'Ürün seç',
  'store.home.add': 'Sütun ekle',

  //kişisel bilgi
  'setting.update.user': 'Kişisel Bilgiler',
  'setting.update.info': 'Bilgileri değiştir',
  'setting.user.avatar': 'Kullanıcı Avatarı',
  'setting.user.nickname': 'Kullanıcı Takma Adı',
  'setting.user.nickname.desc': 'Lütfen kullanıcı takma adını girin',

  'setting.safe.title': 'Güvenlik Bilgileri',
  'setting.user.passwd': 'Şifreyi değiştir',
  'setting.passwd.title1': 'Eski Şifre',
  'setting.passwd.desc1': 'Lütfen eski şifreyi giriniz',
  'setting.passwd.title2': 'Yeni Şifre',
  'setting.passwd.desc2': 'Lütfen yeni bir şifre girin',
  'setting.passwd.title3': 'Şifreyi Doğrulayın',
  'setting.passwd.desc3': 'Lütfen bir onay şifresi girin',
  'setting.invitation': 'Arkadaşlarını davet et',
  'setting.copy': 'Kopyala',

  'adv.add.products': 'Promosyon ürünleri ekleyin',
  'adv.list.title': 'Ürün tanıtımı',
  'adv.type': 'Promosyon Türü',
  'adv.begin.time': 'Promosyon zamanı',
  'adv.end.time': 'Bitiş zamanı',
  'adv.status': 'Durum',
  'adv.status.s1': 'Yükseltme',
  'adv.status.s2': 'Son',
  'adv.add.back': 'Yükseltilenler listesine geri dön',
  'adv.recharge': 'Promosyon puanlarını yeniden yükle',
  'adv.select.goods': 'Mal Seçin',
  'adv.select.btn': 'Tanıtılan Ürünler',
  'adv.recharge.title': 'Yükleme Promosyon Puanları',
  'adv.recharge.balance': 'Puan bakiyesi',
  'adv.nokta': 'nokta',
  'adv.point.rate': 'Puan oranı',
  'adv.recharge.amount': 'Yükleme miktarı',
  'adv.recharge.input.amount': 'Yükleme miktarını girin',
  'adv.select.mod': 'Panel seç',
  'adv.mod1': 'Önerilen sütunlar',
  'adv.mod2': 'Tercih edilen sütun',
  'adv.mod3': 'Sıcak satış sütunu',
  'adv.mod4': 'Mükemmel liste',
  'adv.mod5': 'Sınıflandırma listesi',
  'adv.mod6': 'Ayrıntılı Promosyon',
  'adv.ge': 'Miktar',
  'adv.xs': 'saat',
  'adv.xzbk': 'Plaka seç',
  'adv.syme': 'Kalan kota',
  'adv.tgsc': 'Promosyon süresi',
  'adv.tgjg': 'Promosyon fiyatı',
  'adv.syye': 'Mevcut bakiye',
  'adv.tgye': 'Promosyon bakiyesi',
  'adv.yj1': 'beklenen',
  'adv.yj2': 'Yükseltmeyi başlat',
  'adv.pay.point': 'Tüketim puanları',

  'wallet.ykcsxf': 'İşlem ücreti düşüldü',
  'wallet.sxf': 'Hizmet Ücreti',
  'wallet.zxje': 'Minimum tutar',
  'wallet.24hxe': '24 saat sınırı',
  'wallet.desc.text': 'Hiçbir koşulda, yetkili sizden bir hesaba para aktarmanızı veya bir doğrulama kodu istemeyecektir. Başkaları adına satın alma, kara para aklama ve yasa dışı bağış toplama gibi yasa dışı faaliyetlere katılmayın ve çevrimiçi dolandırıcılığa dikkat edin',
  'wallet.cunbi.text': 'Bu adrese yalnızca {name} para yatırabilirsiniz, diğer varlıklar alınmayacaktır',

  'credit.title': 'Kredi Limiti',
  'credit.ed': 'Kota',
  'credit.hk': 'Geri Ödeme',
  'credit.kyye': 'Kullanılabilir bakiye',
  'credit.dqqk': 'Cari borçlar',
  'credit.hkje': 'Geri ödeme tutarı',
  'credit.hkje.desc': 'Lütfen geri ödeme tutarını giriniz',
  'credit.cgje': 'Satın Alma Tutarı',
  'credit.qrz': 'Kimlik doğrulamaya git',
  'credit.srxyj': 'Kredi ödeme tutarını girin',

  'store.gzl': 'Dikkat miktarı',
  'store.wxz': 'Sınırsız',

  'auth.smrz': 'Gerçek ad kimlik doğrulaması',
  'auth.rzlx': 'Tür',
  'auth.sfzm': 'Kimlik kartı önü',
  'auth.sffm': 'Kimlik kartının arka yüzü',
  'auth.zsxm': 'gerçek ad',
  'auth.zjhm': 'Belge numarası',
  'auth.yyzz': 'İşletme Ruhsatı',
  'auth.gsmc': 'Şirket Adı',
  'auth.shz': 'Denetim',
  'auth.ytg': 'Geçti',
  'auth.ybh': 'Reddedildi',
  'auth.zt': 'durum',
  'auth.gr': 'Kişisel',
  'auth.gs': 'Kuruluş',
  'auth.ljtj': 'Kimlik doğrulama için başvur',
  'auth.wrz': 'Kimliği doğrulanmamış',

  'credit.qts': 'Açıklamayı görüntüle',
  'store.spss': 'Emtia üst limiti',
  'store.dpfh': 'Mağaza temettüleri',
  'store.qydj': 'Öz sermaye düzeyi',
  'store.level': 'seviye',
  'store.jian': 'parçalar',

  'order.notify.email': 'E-posta risk kontrolü nedeniyle, platform siparişi hatırlatıcı e-postaları sık sık gönderilir ve engellenir. Bunları zamanında alabilmek için oturum açma bilgilerine {e} adresinin eklenmesi önerilir. e-posta beyaz listesi',

  'setting.sjxx': 'İş bilgileri',
  'setting.smrz': 'Gerçek ad kimlik doğrulaması',
  'setting.aqzx': 'Güvenlik Merkezi',
  'setting.tzxx': 'Bildirim bilgisi',
  'setting.dpzx': 'Dekorasyon ana sayfası',
  'setting.sjqy': 'İş çıkarları',
  'setting.sjdjqy': 'İş seviyesi avantajlarının açıklaması',

  'setting.fhbky': 'Mevcut temettü mevcut değil',
  'setting.spgzyzy': 'Mağaza kuralları ve yönergeleri',

  'desc.dqqy': 'Mevcut hisse',
  'desc.xjqy': 'Alt çıkarlar',
  'desc.sjqy_0': 'Mağaza kredi limiti',
  'desc.sjqy_1': 'Temettüleri depola',
  'desc.sjqy_2': 'Yayınlanabilecek ürün sayısı',

  'share.yqlb': 'Davet listesi',
  'share.zmj': 'Toplam satıcı',
  'share.zdd': 'Toplam sipariş',
  'share.zsy': 'Toplam aracılık geliri',
  'share.wcl': 'işlenmemiş',
  'share.ycl': 'İşlendi',
  'share.ywc': 'Tamamlandı',
  'share.dpmc': 'Mağaza Adı',
  'share.ddl': 'Sipariş Miktarı',
  'share.jjsy': 'Aracılık geliri',
  'share.zts': 'Toplam sayı',

  'chat.state': 'durum',
  'chat.zx': 'Çevrimiçi',
  'chat.ljz': 'Bağlanıyor',

  'bind.wallet.title': 'Para çekme adresi',
  'bind.address.title': 'Adres ekle',
  'bind.bj': 'Düzenle',
  'bind.sc': 'Sil',
  'bind.qrsc': 'Bu adresi silmek istediğinizden emin misiniz?',
  'bind.qbdz': 'Cüzdan adresi',
  'bind.tjsj': 'Zaman ekle',
  'bind.cz': 'İşlem',
  'withdrawal.address.choice': 'Lütfen para çekme adresini seçin!',

  'order.sxsj': 'Geçerlilik süresi',
  'order.ljsj': 'hemen etkili',
  'order.dssj': 'Zamanlanmış etkili',

  'store.st.zt': 'Mağaza durumu',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Mağazanız normal, lütfen devam edin',
  'store.st.yc': 'İstisna',
  'store.st.yc.desc': 'Mağazanızda bazı anormal siparişler veya müşteri şikayetleri var',
  'store.st.dj': 'Dondur',
  'store.st.dj.desc': 'Mağazanızın belirli bir işletme riski var ve fonlar donduruldu',
  'store.st.jy': 'devre dışı',
  'store.st.jy.desc': 'Mağazanız platformla ilgili sözleşmeleri ve kuralları ihlal etti ve devre dışı bırakıldı',

  'task.sy': 'Ana sayfa',
  'task.rwlbo': 'Görev listesi',
  'task.rwxqo': 'Görev ayrıntıları',
  'task.ljcy': 'Şimdi katılın',
  'task.gdrw': 'Daha fazla görev',
  'task.rwlb': 'Görev kapma listesi',
  'task.qbrw': 'Tüm görevler',
  'task.wcyd': 'Katıldım',
  'task.qdz': 'Emir alma',
  'task.ywc': 'Tamamlandı',
  'task.djy': 'takas edilecek',
  'task.ddje': 'Sipariş Tutarı',
  'task.cyrs': 'Katılımcı sayısı',
  'task.ewsy': 'Ek avantajlar',
  'task.jzsj': 'Son tarih',
  'task.ckxq': 'Ayrıntıları görüntüle',
  'task.rwxq': 'Görev ayrıntıları',
  'task.cylb': 'Katılım listesi',
  'task.qdcyrw': 'Bu sipariş görevine katılmak istediğinizden emin misiniz?',
  'task.sd': 'Evet',
  'task.bue': 'hayır',

  'task.wdsy': 'Gelir',
  'task.wdfh': 'Temettüler',
  'task.wdqd': 'Sipariş al',
  'task.dd.leixin': 'Sipariş Türü',
  'task.dd.qdrw': 'Hızlı sipariş görevi',
  'task.dd.ptdd': 'Olağan sıra',

  'flow.lltg': 'Akış Promosyonu',
  'flow.gmll': 'Veri paketini satın al',
  'flow.dqtc': 'Geçerli paket',
  'flow.tc1': 'Paket',
  'flow.sy2': 'kalan',
  'flow.tian3': 'gün',
  'flow.jlzs': 'Toplam kayıt sayısı',
  'flow.lltc': 'Akış paketi',
  'akış.ll': 'akış',
  'flow.gmsj': 'Satın alma zamanı',
  'flow.tcjg': 'Paket fiyatı',
  'flow.sjzf': 'gerçek ödeme',
  'flow.tcsm': 'Paket açıklaması',
  'flow.tcsm.sm': 'Bu paketin satın alınması mağazanın görünürlüğünü artıracak ve daha fazla müşteri trafiği kazanacaktır. Daha fazla sipariş almak ve mağaza gelirini artırmak için. ',

  'flow.tcmc': 'Paket Adı',
  'flow.gmsc': 'Satın alma süresi',
  'flow.zje': 'Toplam tutar',
  'flow.jzsj': 'Son tarih',
  'flow.gmrq': 'Satın alma tarihi',
  'flow.tian1': 'gün',
  'flow.zhou1': 'hafta',
  'flow.yue1': 'ay',

  'share.dj': 'seviye',
  'share.hz': 'Aktar',
  'share.hzd': 'Şuraya aktar',
  'share.qbye': 'cüzdan bakiyesi',
  'share.tgje': 'Promosyon tutarı',
  'share.hzje': 'Aktarım tutarı',
  'share.qsrhzje': 'Lütfen transfer tutarını girin',

  '_inv._title': 'Davetiye Açıklaması',
  '_inv._t1': '1. Davet ve davet ilişkisi',
  '_inv._desc1': "A, B'yi davet eder, B, C'yi davet eder, C, D'yi davet eder. En yüksek seviye 4'tür, eğer D, E'yi tekrar davet ederse, E'nin A ile hiçbir ilgisi yoktur. ",
  '_inv._t2': '2. Davet eden ödül alır',
  '_inv._desc2': "A, B siparişi tarafından tamamlanan toplam tutarın %4'ünü alır, A, C siparişi tarafından tamamlanan toplam tutarın %2'sini alır ve A, D siparişi tarafından tamamlanan toplam tutarın %1'ini alır",

  '_index._gm': 'Satın Al',
  '_index._sm': 'Açıklama: Mağaza teşhirini artırın ve trafik çekin',

  '_shop._update': 'Ayda {n} kez, geçerli ayda {m} kez değiştirildi',

  '_chat._hc': 'Çek',

  '_footer._suom': 'Politika Açıklaması',

  '_footer._platform._qualification': 'Şirket yeterliliği',
  '_store._ptzz': 'Sonraki Mağaza platformuyla ilgili nitelikler',

  '_abt._gywm': 'Hakkımızda',
  '_abt._syms': 'İş modeli',
  '_abt._qyzr': 'Kurumsal Sorumluluk',
  '_abt._lxwm': 'Bize ulaşın',

  '_wsj._wsjms': 'Ürkütücü Cadılar Bayramı mağazamızda Cadılar Bayramını şık bir şekilde kutlayın. Bu ürkütücü sezonda ister bir numara ister bir hediye arıyor olun, Cadılar Bayramı kostümleri, dekorasyonları ve aksesuarları koleksiyonumuzda tüm aile için bir şeyler var. Kadın ve erkek koleksiyonlarımızla tüyler ürpertici bir açıklama yapın veya süslü elbise kostümleriyle çocuğunuzun hayal gücünü serbest bırakın. Unutulmaz sevimli ev eşyaları ve oyuncaklardan göz alıcı güzellik ve kozmetik ürünlerine kadar, yılın en heyecan verici tatilini kutlamak için mükemmel parçaları bulun. ',

  '_wsj._b1': 'Sonbahar Ev Dekorasyonu',
  '_wsj._b2': 'Kabak',
  '_wsj._b3': 'Ana Sayfa',
  '_wsj._b4': 'Çocuklar için',
  '_wsj._b5': 'Bebeğin',
  '_wsj._b6': 'Kadınlar',
  '_wsj._b7': 'Süslü Elbise',
  '_wsj._b8': 'Kişiselleştirilmiş',

  '_wsj._cj': 'Katıl',
  '_wsj._gz': 'Etkinlik kuralları',
  '_wsj._flss': 'Kategori arama',
  '_wsj._wsnz': 'Cadılar Bayramı kadın kıyafetleri',
  '_wsj._wsn': 'Cadılar Bayramı erkek kıyafetleri',
  '_wsj._wst': 'Cadılar Bayramı çocuk kıyafetleri',
  '_wsj._wszs': 'Cadılar Bayramı dekorasyonu',
  '_wsj._wslp': 'Cadılar Bayramı hediyesi',

  '_wsj._desc1': '"Cadılar Bayramı Özel, Şeker mi Şaka mı!" Bu Ekim ayında sizin için bir karnaval partisi hazırladık! Gelin, birbirinden korkutucu ve şok edici ürünleri hemen yakalayın, büyük indirimler sizi bekliyor! ',
  '_wsj._lkm': 'Alışverişe git',

  '_wsj._wsj': 'Cadılar Bayramı',

  '_wsj._wsjjz': 'Cadılar Bayramı geliyor',
  '_wsj._mzbt': 'Güzellik varış takvimleri',
  '_wsj._mznr': 'Yılın en harika zamanına geri sayım, herkese uygun Noel takvimleriyle birlikte geldi.',

  '_chr._tt1': 'Tüm Noel alışverişi yap',
  '_chr._tt2': 'Noel Süslemeleri',
  '_chr._tt3': 'Tüm Hediyeleri Satın Al',
  '_chr._tt4': 'Tüm Noel Geceliklerini Satın Al',

  '_chr._dt1': "Noel'e yaklaşmak, büyük gün kadar büyülü. O halde hediyelerinizi düzenleyin, salonlarınızı süsleyin ve eşleşen pijamalarınızı Noel'in büyüsü için hazırlayın.",

  '_chr._dtt1': 'Noel Kıyafetleri',
  '_chr._dtt2': 'Eşleşen Aile Noeli',
  '_chr._dtt3': 'Advent Takvimleri',
  '_chr._dtt4': 'Evde Noel',
  '_chr._dtt5': 'Rahat Mağaza',
  '_chr._dtt6': 'Noel Zamanında Mobilyalar',

  '_chr._tre1': 'Şu anda trend',
  '_chr._tre2': 'Herkesin sevdiği trendlerle evinize biraz bayram eğlencesi katın.',
  '_chr._tre3': 'Zencefilli kurabiye',
  '_chr._tre4': 'Noel Pudingi',
  '_chr._tre5': 'Noel Şarkıları',

  '_chr._ttre1': 'Kartlar ve Ambalaj Kağıdı',
  '_chr._ttre2': 'Oyuncaklar ve Oyunlar',
  '_chr._ttre3': 'Yiyecek ve İçecek Hediyeleri',
  '_chr._ttre4': 'Çorap Dolguları',
  '_chr._ttre5': 'Gizli Noel Baba',
  '_chr._ttre6': 'Bebeğin İlk Noeli',
  '_chr._ttre7': 'Güzellik Hediyeleri',
  '_chr._ttre8': 'Premium Hediyeler',
  '_chr._ttre9': 'Kişiselleştirilmiş Hediyeler',

  '_sdj._sdnz': 'Kadın kıyafetleri',
  '_sdj._sdn': 'Erkek kıyafetleri',
  '_sdj._sdetlw': 'hediye',
  '_sdj._sdzs': 'Dekorasyon',
  '_sdj._sdjj': 'oyuncak',

  '_sdj._title1': 'Noel geliyor',
  '_sdj._desc1': '"Noel: verme ve şükran mevsimi." Bu Aralık ayında sizin için parlak dekorasyonlu ve sıcak bir aile partisi hazırladık! Gelin ve sizi bekleyen büyük indirimlerle birçok parlak ve sıcak ürünü yakalayın! ',

  '_ssd._lhpp': 'Esnek ödeme',
  '_ssd._lzhq': 'Küresel Kaynak',
  '_ssd._pwyx': 'mükemmel olarak değerlendirildi',
  '_ssd._qsth': 'Kolay dönüş',
  '_ssd._jgppcn': 'Fiyat Eşleştirme ve Taahhüt',
  '_ssd._ztbt': 'Küresel tek noktadan alışveriş oluşturun',
  '_ssd._yzspl': "Doğrudan 8.000'den fazla bağımsız markadan envanter tedarik etme",
  '_ssd._zcgm': 'Satın almak için kaydolun',
  '_ssd._zccs': 'Satılık olarak kaydolun',

  '_ssd._dlwz': 'Giriş yapın ve kaydolun',

  '_ssd._qjrgwc': 'Hepsini sepete ekle',

  '_zddy._gzlb': 'İzleme listesi',
  '_zddy._zsersxs': "shopifyoutlets'da Satılır",
  '_zddy._zccwsj': 'Tüccar olarak kaydolun',
  '_zddy._dlyh': 'Giriş Yap',
  '_zddy._zcyh': 'Kayıt Ol',
  '_zddy._xyh': 'Yeni kullanıcı',
  '_zddy._gwc': 'Alışveriş sepeti',
  '_zddy._znxx': 'Mesaj',
  '_zddy._zshy': 'En popüler',
  '_zddy._ddl': 'Sipariş',
  '_zddy._mysj': 'Veri yok',
  '_zddy._ckgd': 'Daha fazlasını gör',
  '_zddy._tjsp': 'Önerilen ürünler',
  '_zddy._rmsp': 'Popüler ürünler, yeni trendler, daha fazla satın alma fırsatı',
  '_zddy._xsp': 'Yeni ürün',
  '_zddy._jlzzr': 'Son iki haftanın en popüler öğeleri',
  '_zddy._ljgm': 'Hemen satın al',
  '_zddy._gdsp': 'Daha popüler ürünler',
  '_zddy._ssbt': 'Ara',
  '_zddy._ssgjz': 'Arıyorum..',

  '_zddy._bdhb': 'Yerel para birimi',
  '_zddy._xzbdhb': 'Yerel para birimini seçin',
  '_zddy._zje': 'Toplam tutar',
  '_zddy._xzzffs': 'Lütfen size uygun ödeme yöntemini seçin',
  '_zddy._jmhb': 'Kripto para birimi',

  '_zddy._cp': 'ürün',
  '_zddy._sj': 'İşadamı',
  '_zddy._mj': 'Satıcı',
  '_zddy._fhsy': 'Ana sayfaya dön',

  '_sjsy._ddpl': 'Sipariş listesi',
  '_yhqd._czbz': 'Yeniden şarj etmeden önce şarj bilgilerini onaylamak için lütfen özel müşteri hizmetleriyle iletişime geçin.',
  '_yhqd._lxkf': 'Müşteri Hizmetleriyle İletişime Geçin',
  '_yhqd._qsrje': 'Lütfen yükleme tutarını girin',
  '_yhqd._wrhyhtd': 'Banka erişimi yok',
  '_yhqd._yhzz': 'Banka transferi',
  '_yhbt._yhlx': 'Banka türü',
  '_zcyz._sjdxyz': 'Mobil SMS doğrulaması',
  '_zcyz._sjhm': 'telefon numarası',
  '_zcyz._srsjhm': 'Cep telefonu numarasını girin',
  '_zzsd._qsrzzje': 'Lütfen transfer tutarını girin',
  '_zzsd._zzje': 'transfer miktarı',
  '_txcd._qsrtxje': 'Lütfen çekim tutarını giriniz',
  '_txcd._skr': 'Alacaklı',
  '_txcd._skr._ms': 'Lütfen kart sahibinin adını girin',
  '_txcd._skzh': 'Ödeme hesap numarası',
  '_txcd._skzh._ms': 'Lütfen ödeme hesap numarasını girin',
  '_txcd._tjyhk': 'Banka kartı ekle',
  '_txcd._txje': 'Para Çekme Tutarı',
  '_txcd._wdyhdz': 'banka adresi',
  '_txcd._yhdm': 'kod',
  '_txcd._yhdm._ms': 'Lütfen banka kodunu girin',
  '_txcd._yhdz': 'adres',
  '_txcd._yhdz._ms': 'Lütfen banka adresini girin',
  '_txcd._yhlx': 'tip',
  '_txcd._yhlx._ms': 'Lütfen banka kartı türünü girin',
  '_txcd._yhly': 'yönlendirme',
  '_txcd._yhly._ms': 'Lütfen banka yönlendirmesini girin',
  '_txcd._yhmc': 'banka',
  '_txcd._yhmc._ms': 'Lütfen banka adını girin',
  '_txcd._yhtx': 'Bankadan nakit çekme',

  '_jys._gmjmhb': 'Kripto para birimi satın al',
  '_jys._gmzy': 'Aşağıdaki borsalara giderek ve mevcut ülkenizin politikalarına göre bir hesap kaydedebilir ve kripto para satın alabilirsiniz. Daha sonra platform hesabına yükleme yapın! ',

  '_kdydj._bt': 'Mağaza açın ve büyük ödüller kazanın',
  '_kdydj._xyzbt': 'Şans dönmeye devam ediyor',
  '_kdydj._yx': '{n} kez pompala',
  '_kdydj._xh': 'Tüketim {n} puanı',

  '_kdydj._jf': 'Puanlar:',
  '_kdydj._zjjl': 'Kazanan rekor',

  '_kdydj._hdgz': 'Etkinlik kuralları',
  '_kdydj._qx': 'Lütfen',
  '_kdydj._dl': 'Giriş Yap',

  '_kdydj._mjdl': 'Satıcı girişi',
  '_kdydj._yxh': 'E-posta',
  '_kdydj._yxhsm': 'Lütfen e-posta adresinizi girin',
  '_kdydj._yxhmm': 'Lütfen şifreyi girin',
  '_kdydj._rgmyzh': 'Hesabınız yoksa',
  '_kdydj._djzc': 'Kaydolmak için tıklayın',
  '_kdydj._wjmm': 'Şifremi unuttum',

  '_kdydj._wdjp': 'Ödülüm',
  '_kdydj._zjts': 'Kazanma İpuçları',
  '_kdydj._jx': 'Devam et',
  '_kdydj._czl': 'Kazanıldı',

  '_kdydj._ljjl': 'Kümülatif ödül',
  '_kdydj._ycy': 'Katılıldı: {n} kez',
  '_kdydj._cy': '{n} kez katıl',
  '_kdydj._lq': 'Al',
  '_kdydj._ylq': 'Alındı',
  '_kdydj._wdd': 'Ulaşılmadı',

  '_dk._dkbt1': 'Girişimcilik kredisi borç alma derdini çözüyor',
  '_dk._dkbt2': 'Girişimci kredileri ve sermaye dolaşımı hizmetleri sağlayın',
  '_dk._dkbt3': 'Sıkı fon, borç alamama, arkadaşlarınızdan borç istemeye utanma gibi kredi problemlerinizi çözmenize yardımcı olacağız ve sorularınızı istediğiniz zaman yanıtlayacağız. amaç her krediyi şeffaf hale getirmektir, müşterilerin kredileri hızlı ve güvenle kullanmalarına olanak tanır. ',
  '_dk._zxsq': 'Çevrimiçi başvuru',
  '_dk._wddk': 'Kredim',
  '_dk._aqwy': 'Güvenlik ve endişesiz',
  '_dk._lhed': 'Esnek kota',
  '_dk._lhedb': 'Tutar: 5,00 - 500,000, düşük faiz, esnek geri ödeme yöntemleri, peşin ücret yok, çevrimiçi borç verme, inceleme çağrısı yok, bilgi gizliliği ve aynı gün borç verme. ',
  '_dk._jsfk': 'Hızlı ödünç verme',
  '_dk._jsfkb': 'İncelemeyi en hızlı bir saat içinde geçin, ödeme incelemeden hemen sonra yapılacaktır. ',
  '_dk._aqww1': 'Güvenli ve endişesiz',
  '_dk._aqww1b': 'İpotek kredisi yok, depozito gerekmiyor, geri arama yok, bu özel bir mesele. ',

  '_dk._gywm': 'Hakkımızda',
  '_dk._gywm1': "'Önce müşteri' iş felsefesine dayanarak ve müşterilerin sermaye devir hızı sorunlarını çözme misyonuyla finansal hizmetleri kredilendirmeye kararlıyız, şirket kurulduğu günden bu yana sayısız müşterinin sermaye devir hızını çözmesine yardımcı oldu. sorunlar. ",
  '_dk._gywm2': 'Tutar: 500 ila 500.000, düşük faiz, esnek geri ödeme yöntemleri, peşin ücret yok, çevrimiçi borç verme, inceleme çağrısı yok, bilgi gizliliği ve aynı gün borç verme. ',
  '_dk._gywm3': 'Sınıf arkadaşlarınızdan ve arkadaşlarınızdan borç almak istemiyorsanız lütfen bize gelin. ',
  '_dk._gywm4': 'Girişimci kredi hizmetlerine bağlı',
  '_dk._gywm5': 'Finansal sorunlarınızı çözün',
  '_dk._hzhb': 'İş Ortağı - Çevrimiçi Borç Verme Platformu',
  '_dk._smwda': 'SeersGlobal Business küresel istasyonunun kullanıcıları dünya çapında 112 ülkeden geliyor ve ödeme için USDT/ETH/BTC/TRX kripto para birimini kullanıyor. Kripto para birimi, dünya çapında bekleme ve uluslararası ücret olmadan anında düşük maliyetli işlemlere olanak tanıyan, sınırsız bir ticaret yöntemidir. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Tüm Hakları Saklıdır',
  '_dk._yjwab': "ShopifyOutlets Holdings Global Sourcing Limited (0008945), bir kredi komisyoncusu olarak Financial Conduct Authority tarafından yetkilendirilmiş ve denetlenmektedir ve yalnızca shopifyoutlets Business Financial Services, shopifyoutlets Business Financial olarak ticaret yapan, borç veren Home Retail Group CardServices Limited ile birlikte çalışmaktadır. Hizmetler, kredi sağlama konusunda Mali Davranış Otoritesi tarafından yetkilendirilir ve düzenlenir. Ev Perakende GrubuCard Services Limited, İngiltere (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Birleşik Krallık'ta kayıtlıdır. MK9 2NW. ",

  '_dk._cyzjsq': 'İşletme kredisi başvurusunu doldurun',
  '_dk._dkll': 'Kredi faiz oranı',
  '_dk._dkll2': 'Kredi faiz oranı açıklaması (görüntülemek için tıklayın)',
  '_dk._gs1': 'Formül',
  '_dk._gs2': 'İlgi',
  '_dk._gs3': 'Kredi tutarı',
  '_dk._gs4': 'Yıllık faiz oranı',
  '_dk._gs5': 'Kredi gün sayısı',
  '_dk._gs6': 'örneğin',
  '_dk._gs7': 'Kredi tutarı',
  '_dk._gs8': 'Kredi döngüsü',
  '_dk._gs9': 'gün',
  '_dk._fkfs': 'Ödünç verme yöntemi',
  '_dk._zhye': 'Hesap bakiyesi',
  '_dk._dkqx': 'Kredi süresi',
  '_dk._dkqx1': 'Kredi süresini seçin',
  '_dk._sqje': 'Başvuru tutarı',
  '_dk._qsrdk': 'Lütfen krediyi girin',
  '_dk._dkje': 'Kredi tutarı',
  '_dk._gj': 'Milliyet',
  '_dk._qxzgj': 'Lütfen uyruğu seçin',
  '_dk._sqr': 'Başvuru Sahibi',
  '_dk._qsrsqsm': 'Lütfen başvuranın adını girin',
  '_dk._zjhm': 'Kimlik numarası',
  '_dk._qsrzjhm': 'Lütfen kimlik numarasını girin',
  '_dk._zjzp': 'Kimlik fotoğrafı',
  '_dk._zjzp1': 'Lütfen kimliğinizin ön yüzünün fotoğrafını yükleyin',
  '_dk._zjfm1': 'Lütfen kimliğinizin arka yüzünün fotoğrafını yükleyin',
  '_dk._zjsc1': 'Lütfen kimlik kartınızın fotoğrafını yükleyin',
  '_dk._zjzp2': 'Resim seç',
  '_dk._pzslt': 'Fotoğrafçılık örneği',
  '_dk._wytyb': 'Okudum ve kabul ediyorum',
  '_dk._jkxy': 'Kredi Anlaşması',
  '_dk._tjsq': 'Başvuruyu gönder',

  '_dk._wddk2': 'Kredim',
  '_dk._ydk': 'Kiralandı',
  '_dk._cslx': 'İlgi yarat',
  '_dk._yjhk': 'Tahmini geri ödeme',
  '_dk._dkjl': 'Kredi kaydı',
  '_dk._hkjl': 'Geri ödeme kaydı',
  '_dk._sqrq': 'Başvuru tarihi',
  '_dk._hkzq': 'Geri ödeme döngüsü',
  '_dk._gdll': 'Sabit faiz oranı',
  '_dk._hkz': 'Geri ödeme devam ediyor',
  '_dk._yhk': 'Geri Ödeme',
  '_dk._sqz': 'Uygulanıyor',
  '_dk._ytg': 'Geçti',
  '_dk._ybh': 'Reddedildi',
  '_dk._tqhk': 'Erken ödeme',

  '_dk._ljhk': 'Hemen geri öde',
  '_dk._qrytqhk': 'Önceden geri ödeme başvurusunda bulunmak istediğinizden emin misiniz?',
  '_dk._qrytqhk1': 'Not: Başvuru başarılı olduktan sonra yatırılan tutar öncelikli olarak doğrudan krediye iade edilecektir',
  '_dk._dkxq': 'Kredi ayrıntıları',
  '_dk._dkje1': 'Kredi tutarı',
  '_dk._yhlx': 'Vadesi gelen faiz',
  '_dk._hkze': 'Toplam geri ödeme',
  '_dk._sqhkz': 'Geri ödeme başvurusu yapılıyor',
  '_dk._zxydbty': 'Lütfen dikkatlice okuyun ve "Kredi Sözleşmesini" kabul etmek için kutuyu işaretleyin',
  '_dk._zzsqz': 'Uygulanıyor',

  '_dk._dksq': 'Kredi',

  '_dk._cs': 'Parametreler',
  '_dk._nllsm': 'Yüzde olarak ifade edilir, örneğin: %6,25',
  '_dk._dktssm': 'Kredi onayının başlangıcından bitişine kadar geçen toplam gün sayısı',
  '_dk._yndts': 'Bir yıldaki gün sayısı: genellikle 365 gün',
  '_dk._zlj': 'Gecikme ücreti',

  '_xbb._xz': 'İndir',
  '_xbb._xzapp': 'ShopifyOutlets uygulamasını indir',
  '_xbb._smewm': 'İndirmek için QR kodunu tarayın',
  '_xbb._syfl': 'Tüm kategoriler',
  '_xbb._gdfl': 'Daha Fazla',
  '_xbb._sbbt1': 'Hızlı teslimat',
  '_xbb._sbbt2': 'Herhangi bir sorun olması durumunda geri ödeme yapılabilir',
  '_xbb._sbbt3': 'Ücretsiz iade',
  '_xbb._sbbt4': '90 gün içinde',
  '_xbb._tjsp1': 'Önerilen ürünler',
  '_xbb._tjsp2': 'Birçok indirim',
  '_xbb._tjsp3': 'Sınırlı süreli indirim',

  '_xbb._asjgw': 'Mağazaya göre alışveriş',
  '_xbb._gdsp': 'Daha fazla ürün',
  '_xbb._ljgm': 'Hemen satın al',
  '_xbb._ptbz': 'ShopifyOutlets garantisi',
  '_xbb._aqzf': 'Güvenli ödeme',
  '_xbb._aqzf1': 'Birçok uluslararası alışveriş yapan kişinin kullandığı bir ödeme yöntemi',
  '_xbb._aqys': 'Güvenlik ve Gizlilik',
  '_xbb._aqys1': 'Gizliliğinize saygı duyuyoruz, dolayısıyla kişisel bilgileriniz güvende',
  '_xbb._mjbh': 'Alıcı Koruması',
  '_xbb._mjbh1': 'Siparişiniz beklenen tarihten önce teslim edilmezse veya siparişten memnun kalmazsanız para iadesi alabilirsiniz',
  '_xbb._mzsm': 'Uyarı/Sorumluluk Reddi',

  '_xbb._sjxx': 'İşadamı',
  '_xbb._ggms': 'Şartname',
  '_xbb._mswa': 'Açıklama',
  '_xbb._khpl': 'Müşteri yorumları',
  '_xbb._zmfk': 'Olumlu geribildirim',
  '_xbb._gzrs': 'Takip et',
  '_xbb._gzrsan': 'Takip Et',
  '_xbb._ygzrsan': 'Zaten takip ediliyordu',
  '_xbb._fsxx': 'Mesaj',
  '_xbb._myrpl': 'Henüz yorum yok',
  '_xbb._llgd': 'Daha fazlasını görüntüle',
  '_xbb._pdcl': 'Söz',
  '_xbb._myf': 'Ücretsiz gönderim',
  '_xbb._sdsj': 'Yaklaşık 5-10 içinde teslim edilir',
  '_xbb._ksjh': 'Hızlı teslimat',
  '_xbb._bgdstk': 'Kayıp paket için geri ödeme',
  '_xbb._shbgtk': 'Ürün hasar görmüşse para iadesi yapın',
  '_xbb._s30gtk': '30 gün içerisinde gönderilmezse para iadesi yapılır',
  '_xbb._aqyys': 'Güvenlik ve Gizlilik',
  '_xbb._wjtrxk': 'Güvenli ödeme: Kişisel bilgilerinizi izniniz olmadan hiçbir üçüncü tarafla paylaşmayacağız. ',
  '_xbb._sfgrxx': 'Kişisel ayrıntıların güvenliğini sağlayın: Gizliliğinizi koruyoruz ve kişisel bilgilerinizi güvende ve emniyette tutuyoruz. ',
  '_xbb._sl': 'Miktar',
  '_xbb._kcld': 'Envanter',

  '_xbb._sjrz': 'Satıcı yerleşti',
  '_xbb._hezjh': 'Ortak programımız size eksiksiz bir pazarlama desteği ve hizmetleri yelpazesi sunar ve müşteri hizmetleri ekibimiz, ürün teşhirinizi ve satış stratejinizi optimize etmenize yardımcı olmak için profesyonel destek ve tavsiyeler sağlayacaktır. Şimdi bize katılın! Daha büyük iş fırsatları yaratalım ve birlikte büyüyelim! ',
  '_xbb._xsje': 'İş bilgileri',
  '_xbb._rgsqjs': 'Zaten satıcıysanız',
  '_xbb._qni': 'Lütfen',
  '_xbb._djdl': 'Giriş yapmak için tıklayın',
  '_xbb._dplog': "LOGO'yu sakla",
  '_xbb._dpmc': 'Mağaza adı',
  '_xbb._srdpmc': 'Lütfen mağaza adını girin',
  '_xbb._zjhm': 'Kimlik numarası',
  '_xbb._srzjhm': 'Lütfen kimlik numarasını girin',
  '_xbb._zsxm': 'gerçek ad',
  '_xbb._srzsxm': 'Lütfen gerçek adınızı girin',
  '_xbb._srgj': 'Ülke',
  '_xbb._srsrgj': 'Lütfen ülkeyi girin',
  '_xbb._zjz': 'Kimlik fotoğrafı',
  '_xbb._zjzm': 'Belgenin ön yüzü',
  '_xbb._zjfm': 'Sertifikanın arka tarafı',
  '_xbb._sczjz': 'Kimlik fotoğrafını tut',
  '_xbb._pssl': 'Fotoğrafçılık örneği',
  '_xbb._dlyx': 'Giriş e-postası',
  '_xbb._srdlyx': 'Lütfen giriş e-postanızı girin',
  '_xbb._dlmm': 'Giriş şifresi',
  '_xbb._srdlmm': 'Lütfen giriş şifrenizi girin',
  '_xbb._qrmm': 'Şifreyi onayla',
  '_xbb._srqrmm': 'Lütfen onay şifresini girin',
  '_xbb._yqm': 'davetiye kodu',
  '_xbb._sryqm': 'Davet kodunu girin',
  '_xbb._yxyzm': 'E-posta doğrulama kodu',
  '_xbb._sryzm': 'E-posta doğrulama kodunu girin',
  '_xbb._yydbty': 'Okudum ve kabul ettim',
  '_xbb._rzxy': 'Yerleştirme Sözleşmesi',

  '_xbb._qgxty': 'Lütfen uzlaşma sözleşmesini dikkatlice okuyun ve kabul etmek için kutuyu işaretleyin',
  '_xbb._sryqmb': 'Lütfen davet kodunu girin'
}
