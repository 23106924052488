<template>
  <div style="background-color: #fff; padding: 50px 10px 10px 10px; margin-top: 10px">
    <div data-v-0d8380df="" data-v-18d90da2="" class="app-container loan">
      <div data-v-0d8380df="" class="mine">
        <div data-v-0d8380df="" class="left">
          <div data-v-0d8380df="" class="title">{{ $t('_dk._wddk2') }}</div>
          <div data-v-0d8380df="" class="money">{{ myLoan.amount }}</div>
          <div data-v-0d8380df="" class="details">
            <div data-v-0d8380df="">
              <span data-v-0d8380df="">{{ myLoan.day }}{{ $t('_dk._gs9') }}</span>
              <p data-v-0d8380df="">{{ $t('_dk._ydk') }}</p>
            </div>
            <div data-v-0d8380df="">
              <span data-v-0d8380df="">{{ myLoan.interest }}</span>
              <p data-v-0d8380df="">{{ $t('_dk._cslx') }}</p>
            </div>
            <div data-v-0d8380df="">
              <span data-v-0d8380df="">{{ myLoan.forfeit }}</span>
              <p data-v-0d8380df="">{{ $t('_dk._zlj') }}</p>
            </div>
            <div data-v-0d8380df="">
              <span data-v-0d8380df="">{{ myLoan.repay }}</span>
              <p data-v-0d8380df="">{{ $t('_dk._yjhk') }}</p>
            </div>
          </div>
        </div>
        <!---->
      </div>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div data-v-0d8380df="" class="record-title" style="display: flex; gap: 20px">
          <div :class="'sbt ' + (recordType == 1 ? 'active' : '')" @click="onRecord(1)">{{ $t('_dk._dkjl') }}</div>
          <div :class="'sbt ' + (recordType == 2 ? 'active' : '')" @click="onRecord(2)">{{ $t('_dk._hkjl') }}</div>
        </div>
        <div>
          <Customer></Customer>
        </div>
      </div>
      <!-- 贷款记录 -->
      <div data-v-0d8380df="" class="record" v-if="recordType == 1">
        <div data-v-0d8380df="" class="" v-for="(item, i) in loanList" :key="i">
          <div class="el-loading-mask" style="display: none">
            <div class="el-loading-spinner">
              <svg viewBox="25 25 50 50" class="circular">
                <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
              </svg>
            </div>
          </div>
          <div data-v-0d8380df="" class="list">
            <img data-v-0d8380df="" src="../../static/css5/img/a6.png" alt="" />
            <div data-v-0d8380df="" class="item date-box">
              <div data-v-0d8380df="" class="money">{{ item.amount }}</div>
              <div data-v-0d8380df="" class="date">{{ $t('_dk._sqrq') }}:{{ item.status == 2 ? item.confirm_time : item.create_time }}</div>
            </div>
            <div data-v-0d8380df="" class="item-container">
              <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.times }}{{ $t('_dk._gs9') }}</span
                ><span data-v-0d8380df="" class="date">{{ $t('_dk._hkzq') }}</span>
              </div>
              <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.rate }}%</span><span data-v-0d8380df="" class="date">{{ $t('_dk._gdll') }}</span>
              </div>

              <!----><!---->
              <template v-if="item.is_repay == 3">
                <div data-v-0d8380df="" class="overdue status application">{{ $t('_dk._hkz') }}</div>
              </template>
              <template v-else-if="item.is_repay == 2">
                <div data-v-0d8380df="" class="finish status application">{{ $t('_dk._yhk') }}</div>
              </template>
              <template v-else>
                <div data-v-0d8380df="" class="status application" v-if="item.status == 1">{{ $t('_dk._sqz') }}</div>
                <div data-v-0d8380df="" class="finish status application" v-if="item.status == 2">{{ $t('_dk._ytg') }}</div>
                <div data-v-0d8380df="" class="overdue status application" v-if="item.status == 3">{{ $t('_dk._ybh') }}</div>
              </template>
              <!----><!----><!----><!---->
              <div data-v-0d8380df="" class="default">
                <a @click="onRepay(item)" v-if="item.status == 2 && item.is_repay == 1">{{ $t('_dk._tqhk') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div data-v-0d8380df="" class="common-pagination">
          <div data-v-0d8380df="" class="el-pagination is-background">
            <button type="button" v-if="pagination.pageNo > 1" class="btn-prev" @click="getApplyLoanList(pagination.pageNo - 1)">
              <left-outlined class="ssdbx" style="font-size: 13px" />
            </button>
            <ul class="el-pager">
              <li @click="getApplyLoanList(page)" :class="'number ' + (pagination.pageNo == page ? 'active' : '')" v-for="(page, ii) in pagination.totalPage" :key="ii">{{ page }}</li>
              <!----><!----><!---->
            </ul>
            <button type="button" :disabled="pagination.pageNo == pagination.totalPage ? true : false" class="btn-next" @click="getApplyLoanList(pagination.pageNo + 1)">
              <right-outlined class="ssdbx" style="font-size: 13px" />
            </button>
          </div>
        </div>
      </div>

      <!-- 还款记录 -->
      <div data-v-0d8380df="" class="record" v-if="recordType == 2">
        <div data-v-0d8380df="" class="" v-for="(item, i) in repayList" :key="i">
          <div class="el-loading-mask" style="display: none">
            <div class="el-loading-spinner">
              <svg viewBox="25 25 50 50" class="circular">
                <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
              </svg>
            </div>
          </div>
          <div data-v-0d8380df="" class="list">
            <img data-v-0d8380df="" src="../../static/css5/img/a6.png" alt="" />
            <div data-v-0d8380df="" class="item date-box">
              <div data-v-0d8380df="" class="money">{{ (parseFloat(item.amount) + parseFloat(item.interest)).toFixed(2) }}</div>
              <div data-v-0d8380df="" class="date">{{ $t('_dk._sqrq') }}:{{ item.is_repay == 2 ? item.last_repay_time : item.start_repay_time }}</div>
            </div>
            <div data-v-0d8380df="" class="item-container">
              <!-- <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.times }}天</span><span data-v-0d8380df="" class="date">还款周期</span>
              </div>
              <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.rate }}%</span><span data-v-0d8380df="" class="date">固定利率</span>
              </div> -->
              <!----><!---->

              <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.forfeit }}</span>
                <span data-v-0d8380df="" class="date">{{ $t('_dk._zlj') }}</span>
              </div>
              <div data-v-0d8380df="" class="item">
                <span data-v-0d8380df="" class="date">{{ item.repaid }}</span>
                <span data-v-0d8380df="" class="date">{{ $t('_dk._yhk') }}</span>
              </div>
              <div data-v-0d8380df="" class="finish status application" v-if="item.is_repay == 2">{{ $t('_dk._yhk') }}</div>
              <div data-v-0d8380df="" class="overdue status application" v-if="item.is_repay == 3">{{ $t('_dk._hkz') }}</div>
              <!----><!----><!----><!---->
              <!-- <div data-v-0d8380df="" class="default">--</div> -->
            </div>
          </div>
        </div>
        <div data-v-0d8380df="" class="common-pagination">
          <div data-v-0d8380df="" class="el-pagination is-background">
            <button type="button" v-if="pagination.pageNo > 1" class="btn-prev" @click="getApplyLoanList(pagination.pageNo - 1)">
              <left-outlined class="ssdbx" style="font-size: 13px" />
            </button>
            <ul class="el-pager">
              <li @click="getApplyLoanList(page)" :class="'number ' + (pagination.pageNo == page ? 'active' : '')" v-for="(page, ii) in pagination.totalPage" :key="ii">{{ page }}</li>
              <!----><!----><!---->
            </ul>
            <button type="button" :disabled="pagination.pageNo == pagination.totalPage ? true : false" class="btn-next" @click="getApplyLoanList(pagination.pageNo + 1)">
              <right-outlined class="ssdbx" style="font-size: 13px" />
            </button>
          </div>
        </div>
      </div>

      <!-- <div data-v-0d8380df="" class="el-dialog__wrapper es-dialog" style="display: none">
        <div role="dialog" aria-modal="true" aria-label="预计还款" class="el-dialog" style="margin-top: 15vh; width: 442px">
          <div class="el-dialog__header">
            <span class="el-dialog__title">预计还款</span><button type="button" aria-label="Close" class="el-dialog__headerbtn"><i class="el-dialog__close el-icon el-icon-close"></i></button>
          </div>
        </div>
      </div>
      <div data-v-0d8380df="" class="el-dialog__wrapper es-dialog" style="display: none">
        <div role="dialog" aria-modal="true" aria-label="dialog" class="el-dialog el-dialog--center" style="margin-top: 15vh; width: 475px">
          <div class="el-dialog__header">
            <div data-v-0d8380df="" class="dialog-title">
              <span data-v-0d8380df="">输入交易密码</span
              >
            </div>
            <button type="button" aria-label="Close" class="el-dialog__headerbtn"><i class="el-dialog__close el-icon el-icon-close"></i></button>
          </div>
        </div>
      </div> -->
    </div>

    <!-- msg -->
    <a-modal v-model:visible="visible" :title="mdTitle" @ok="onRepayOk" :okText="$t('_dk._ljhk')" :cancelText="$t('store.order.purchase.no')">
      <p style="font-size: 18px; font-weight: 600; margin-bottom: 10px">{{ $t('_dk._qrytqhk') }}？</p>
      <p>{{ $t('_dk._qrytqhk1') }}</p>
      <div class="ct-box">
        <div style="font-weight: 700">{{ $t('_dk._dkxq') }}：</div>
        <div class="ct-title">{{ $t('_dk._dkje1') }}: ${{ currentRepay.amount }}</div>
        <div class="ct-desc">{{ $t('_dk._yhlx') }}: ${{ currentRepay.interest }}</div>
      </div>
      <div class="ct-box" style="margin-top: 10px; font-size: 16px">
        <div style="font-weight: 700">
          {{ $t('_dk._hkze') }}：<span style="color: #8bc34a">$ {{ (parseFloat(currentRepay.amount) + parseFloat(currentRepay.interest)).toFixed(2) }}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import Customer from '@/components/Customer.vue'
export default {
  name: 'LoanVue',
  components: {
    LeftOutlined,
    RightOutlined,
    Customer
  },
  data() {
    return {
      pagination: {},
      loanList: [],
      repayList: [],
      recordType: 1,

      myLoan: {
        amount: 0,
        interest: 0,
        day: 0
      },

      //确认框
      visible: false,
      currentRepay: {},

      //还款申请
      isRepay: false
    }
  },
  created() {
    this.getMyLoan()
    this.getApplyLoanList(1)
  },
  methods: {
    getMyLoan() {
      let that = this
      this.$api.loan.getMyLoan().then((res) => {
        that.myLoan = res.data
      })
    },
    onRecord(t) {
      this.recordType = t

      if (t == 1) {
        this.getApplyLoanList(1)
      } else if (t == 2) {
        this.getRepayLoanList(1)
      }
    },
    getApplyLoanList(page) {
      //获取商品
      let hide = this.$message.loading({ content: 'loading...', key: 'msg' })
      this.$api.loan
        .getApplyLoanList({
          page: page
        })
        .then((res) => {
          hide()
          this.loanList = res.data.data
          this.pagination = {
            pageNo: res.data.pageNo,
            pageSize: res.data.pageSize,
            totalCount: res.data.totalCount,
            totalPage: res.data.totalPage
          }
        })
    },
    getRepayLoanList(page) {
      //获取商品
      let hide = this.$message.loading({ content: 'loading...', key: 'msg' })
      this.$api.loan
        .getRepayLoanList({
          page: page
        })
        .then((res) => {
          hide()
          this.repayList = res.data.data
          this.pagination = {
            pageNo: res.data.pageNo,
            pageSize: res.data.pageSize,
            totalCount: res.data.totalCount,
            totalPage: res.data.totalPage
          }
        })
    },
    onRepay(item) {
      //当前贷款信息
      this.currentRepay = item
      this.visible = true
    },
    onRepayOk() {
      if (this.isRepay) {
        return
      }
      this.isRepay = true

      this.$message.loading({ content: this.$t('_dk._sqhkz') + '...', key: 'msg' })

      let that = this
      this.$api.loan
        .applyRepay({ id: this.currentRepay.id })
        .then((res) => {
          that.isRepay = false
          that.visible = false
          that.$message.success({ content: res.msg, key: 'msg' })
          setTimeout(() => {
            that.getApplyLoanList(1)
          }, 500)
        })
        .catch(() => {
          that.isRepay = false
          that.visible = false
        })
    }
  }
}
</script>

<style scoped>
@import '../../static/css4/chunk-641e069a.88806ab1.css';
</style>
<style scoped>
.ssdbx {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.sbt {
  height: 25px;
  cursor: pointer;
}
.sbt.active {
  border-bottom: var(--m-color1) solid 3px;
}
</style>
