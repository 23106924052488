<template>
  <div aria-hidden="true" style="height: 100%" class="md:block md:col-span-6 md:col-start-7 lg:col-span-5 lg:col-start-8 pb-6 relative md:max-h-[130vh] reduced-motion:translate-y-0 will-change-transform duration-1000 ease-in-out transition-all reduced-motion:opacity-100">
    <!-- box -->
    <div class="scroll-box flex w-full justify-between relative h-full px-5 ml-5 self-end opacity-0 mask-image-[linear-gradient(0deg,transparent,rgba(255,255,255,1)_20%,rgba(0,0,0,1)_75%,transparent)] transform-[translate3d(0,25%,0)] origin-top min-h-[calc(100%+72px)] animate-homepage-image-tracks">
      <div class="image-track-col1 top-0 z-0 w-1/2-gutter relative">
        <!-- 第一个 -->
        <div v-for="i in 2" :key="i" role="presentation" class="scrolling-box md:flex flex-col gap-gutter absolute -top-gutter pt-gutter will-change-transform w-full -hidden left-0 relative">
          <div role="presentation" class="hero-card relative">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <img sizes="100vw" srcset="../../../static/css5/imgs/ba1.png" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="../../../static/css5/imgs/ba1.svg" alt="Kirrin Finch logo" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Laura & Kelly Moffat</div>
                  <div class="text-black text-xs">Founder</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="rounded-lg shadow-homepage-card p-2 flex bg-white/[0.7]">
              <div class="shrink-0">
                <img srcset="../../../static/css5/imgs/ba2.png" alt="" class="w-16 h-16 rounded-md aspect-square" sizes="100vw" />
              </div>
              <div class="flex flex-col ml-2 w-full">
                <div class="w-full text-right flex justify-end mb-2">
                  <div class="bg-[#AEE9D1] rounded-full text-xs flex justify-center items-center min-w-[56px] px-1 py-[2px]">
                    <div class="w-3 h-3 bg-white rounded-full mr-1">
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" class="w-3 h-3 text-[#007F5F] shrink-0">
                        <path d="M4.55908 5.43505L6.01187 3.98226C6.18437 3.80976 6.46319 3.80976 6.6357 3.98226C6.8082 4.15476 6.8082 4.43358 6.6357 4.60608L4.87099 6.37079C4.78496 6.45682 4.67202 6.50005 4.55908 6.50005C4.44614 6.50005 4.33319 6.45682 4.24717 6.37079L3.36481 5.48844C3.19231 5.31594 3.19231 5.03711 3.36481 4.86461C3.53731 4.69211 3.81614 4.69211 3.98864 4.86461L4.55908 5.43505Z" fill="currentColor"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.588379 5.17641C0.588379 2.74377 2.5675 0.764648 5.00014 0.764648C7.43279 0.764648 9.41191 2.74377 9.41191 5.17641C9.41191 7.60906 7.43279 9.58818 5.00014 9.58818C2.5675 9.58818 0.588379 7.60906 0.588379 5.17641ZM1.47073 5.17641C1.47073 7.12244 3.05411 8.70582 5.00014 8.70582C6.94617 8.70582 8.52956 7.12244 8.52956 5.17641C8.52956 3.23038 6.94617 1.647 5.00014 1.647C3.05411 1.647 1.47073 3.23038 1.47073 5.17641Z" fill="currentColor"></path>
                      </svg>
                    </div>
                    Paid
                  </div>
                </div>
                <div class="product flex justify-between">
                  <div class="text-sm text-black font-bold">plant</div>
                  <div class="text-sm text-black">$175.25</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <img srcset="../../../static/css5/imgs/ba3-1.png" alt="" aria-hidden="true" class="w-full blur-sm object-cover z-10 absolute inset-0" sizes="100vw" />
              <img sizes="100vw" srcset="../../../static/css5/imgs/ba3.png" alt="The Sill 商家" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="../../../static/css5/imgs/ba3.svg" alt="The Sill logo" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Eliza Blank</div>
                  <div class="text-black text-xs">Founder and CEO</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="bg-gradient-to-t from-[#4a4b4b] via-[#050505] to-[#1d1e1e] rounded-lg p-6 flex flex-col">
              <div class="text-white/40 text-[10px] mb-1">Net Sales</div>
              <div class="text-white text-2xl font-medium mb-2 duration-1000 transition-opacity -opacity-0" data-testid="net-sales">
                <div class="flex -w-[134px] flex-row justify-start">
                  <span class="text-left">US $</span>
                  <span>60,243.82</span>
                </div>
              </div>
              <div class="text-[#21AF85] text-sm font-medium flex items-center transition-all duration-1000 opacity-0">
                <svg viewBox="0 0 20 20" class="-rotate-90 w-3 h-3 mr-1"><path d="m17.707 9.293-5-5a1 1 0 0 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a1 1 0 0 0 1.414 1.414l5-5a1 1 0 0 0 0-1.414z" fill="currentColor"></path></svg>3.5%
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <img srcset="../../../static/css5/imgs/ba4-1.png" alt="" aria-hidden="true" class="w-full blur-sm object-cover z-10 absolute inset-0" sizes="100vw" />
              <img sizes="100vw" srcset="../../../static/css5/imgs/ba4.png" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="../../../static/css5/imgs/ba4.svg" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Kristin & Kofi Essel</div>
                  <div class="text-black text-xs">Co-founder, Creative and Design Director</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="rounded-lg shadow-homepage-card px-2 py-4 flex bg-white/[0.7]">
              <div class="shrink-0 self-center ml-2">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" class="w-6 h-6 text-[#5C5F62]">
                  <path
                    d="M12.9193 0.680566C13.1631 0.924363 13.3 1.25502 13.3 1.5998V11.4616L14.9809 9.78071C15.2261 9.5439 15.5545 9.41287 15.8954 9.41583C16.2362 9.41879 16.5623 9.55551 16.8033 9.79654C17.0443 10.0376 17.1811 10.3636 17.184 10.7045C17.187 11.0453 17.056 11.3737 16.8191 11.6189L12.9191 15.5189C12.6754 15.7626 12.3448 15.8995 12 15.8995C11.6553 15.8995 11.3247 15.7626 11.0809 15.5189L7.18095 11.6189C6.94414 11.3737 6.81311 11.0453 6.81607 10.7045C6.81903 10.3636 6.95575 10.0376 7.19678 9.79654C7.43781 9.55551 7.76387 9.41879 8.10473 9.41583C8.44558 9.41287 8.77396 9.5439 9.01915 9.78071L10.7 11.4616V1.5998C10.7 1.25502 10.837 0.924363 11.0808 0.680566C11.3246 0.436769 11.6553 0.299805 12 0.299805C12.3448 0.299805 12.6755 0.436769 12.9193 0.680566Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M2.90005 4.1998V18.4998H7.45005C8.45243 18.4998 9.24635 19.3543 9.94318 20.1042L9.95385 20.1157L10.0981 20.2717C10.3411 20.5332 10.6354 20.7418 10.9627 20.8843C11.2899 21.0268 11.6431 21.1002 12 21.0998C12.3571 21.1005 12.7105 21.0274 13.038 20.8851C13.3655 20.7429 13.6601 20.5345 13.9032 20.273L14.0463 20.117C14.7457 19.3604 15.5426 18.4998 16.55 18.4998H21.1V4.1998H15.9V1.5998H21.75C22.2672 1.5998 22.7632 1.80525 23.1289 2.17095C23.4946 2.53664 23.7001 3.03263 23.7001 3.5498V23.0498C23.7001 23.567 23.4946 24.063 23.1289 24.4287C22.7632 24.7944 22.2672 24.9998 21.75 24.9998H2.25005C1.73288 24.9998 1.23689 24.7944 0.871191 24.4287C0.505495 24.063 0.300049 23.567 0.300049 23.0498V3.5498C0.300049 3.03263 0.505495 2.53664 0.871191 2.17095C1.23689 1.80525 1.73288 1.5998 2.25005 1.5998H8.10005V4.1998H2.90005Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div class="ml-2 w-full text-sm self-center items-center"><span class="font-semibold inline-block mr-1">41 orders </span>waiting shipped</div>
            </div>
          </div>
        </div>
      </div>

      <div class="image-track-col2 top-0 z-0 w-1/2-gutter relative">
        <!-- 第二个 -->
        <div v-for="i in 2" :key="i" role="presentation" class="scrolling-box md:flex flex-col gap-gutter absolute -top-gutter pt-gutter will-change-transform w-full -hidden right-0 -mt-24 relative">
          <div role="presentation" class="hero-card">
            <div class="notifications-wrapper overflow-hidden">
              <div class="w-full rounded-lg shadow-homepage-card p-3 flex bg-white/[0.7]">
                <div class="shrink-0 bg-black rounded-full -p-2 w-8 h-8 flex justify-center items-center" style="border-radius: 50%;overflow: hidden;">
                  <img src="../../../static/avatar.png">
                </div>
                <div class="ml-2 flex flex-col justify-center text-xs">
                  <div class="flex justify-between">
                    <div class="font-semibold mb-1">ShopifyOutlets</div>
                    <div class="text-shade-50">14 minutes ago</div>
                  </div>
                  <div class="text-black/40">Ding ding! You have a new order for 2 items totaling $140.</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <!-- <img srcset="../../../static/css5/imgs/b1.png" alt="" aria-hidden="true" class="w-full blur-sm object-cover z-10 absolute inset-0" sizes="100vw" /> -->
              <!-- 默认图 -->
              <img sizes="100vw" srcset="../../../static/css5/imgs/bb1.png" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <!-- 移动上背景图 -->
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="../../../static/css5/imgs/bb1.svg" alt="MrBeast logo" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Manabu Okada</div>
                  <div class="text-black text-xs">Founder</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="notifications-wrapper overflow-hidden">
              <div class="w-full rounded-lg shadow-homepage-card p-3 flex bg-white/[0.7]">
                <div class="shrink-0 bg-black rounded-full -p-2 w-8 h-8 flex justify-center items-center" style="border-radius: 50%;overflow: hidden;">
                  <img src="../../../static/avatar.png">
                </div>
                <div class="ml-2 flex flex-col justify-center text-xs">
                  <div class="flex justify-between">
                    <div class="font-semibold mb-1">ShopifyOutlets</div>
                    <div class="text-shade-50">34 minutes ago</div>
                  </div>
                  <div class="text-black/40">A new user has been successfully registered</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <img srcset="../../../static/css5/imgs/bb2-1.png" alt="" aria-hidden="true" class="w-full blur-sm object-cover z-10 absolute inset-0" sizes="100vw" />
              <img sizes="100vw" srcset="../../../static/css5/imgs/bb2.png" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="https://cdn.shopify.com/shopifycloud/brochure/assets/home/redesign2022/huron-ac2e8dd2f1f3156b9079c429de05bf9a9917fcdd49dc7f0aa0a6fee13b43eef9.svg" alt="Huron logo" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Matt Mullenax & Matt Teri</div>
                  <div class="text-black text-xs">Co-founder, CEO and Chief Growth Officer</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="rounded-lg shadow-homepage-card p-2 flex bg-white/[0.7]">
              <div class="shrink-0">
                <img srcset="../../../static/css5/imgs/bb3.png" alt="" class="w-12 h-12 rounded-md aspect-square" sizes="100vw" />
              </div>
              <div class="flex ml-2 w-full text-sm self-center items-center">
                <div class="bg-[#72f8a8] rounded-full px-4 py-1 grow-0 text-xs">Available</div>
                <div class="ml-2 text-black font-normal">In stock 10 pieces</div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div role="presentation" class="w-full aspect-[0.706] relative z-10 transition-all duration-500 will-change-transform rounded-lg overflow-hidden shadow-homepage-card">
              <img srcset="../../../static/css5/imgs/bb4-1.png" alt="" aria-hidden="true" class="w-full blur-sm object-cover z-10 absolute inset-0" sizes="100vw" />
              <img sizes="100vw" srcset="../../../static/css5/imgs/bb4.png" class="w-full aspect-[0.706] relative z-20 mt-auto scale-105" />
              <div class="absolute inset-0 py-2 px-4 flex flex-col items-center justify-center z-30 opacity-0 hover:opacity-100 transition-opacity h-full" style="background: linear-gradient(180.48deg, rgba(255, 255, 255, 0.85) 79.88%, rgba(255, 255, 255, 0) 143.32%)">
                <img sizes="100vw" srcset="../../../static/css5/imgs/bb4.svg" alt="A-morir logo" class="grow-0 self-center max-w-[80%] w-2/3 mt-auto object-fit" />
                <div class="merchant-info bottom-0 inset-x-0 flex flex-col items-center justify-center text-center px-2 self-end mt-auto w-full mb-4">
                  <div class="text-black font-bold text-lg">Kerin Rose Gold</div>
                  <div class="text-black text-xs">Founder</div>
                </div>
              </div>
            </div>
          </div>
          <div role="presentation" class="hero-card">
            <div class="bg-gradient-to-t from-[#4a4b4b] via-[#050505] to-[#1d1e1e] rounded-lg p-6 flex flex-col">
              <div class="text-white/40 text-[10px] mb-1">Total sales</div>
              <div class="text-white text-2xl w-full font-medium mb-6 transition-all duration-500 -opacity-0">
                <div class="flex -w-[134px] flex-row justify-start"><span class="text-left">US $</span><span>38,232.96</span></div>
              </div>
              <div class="mb-6 transition-all duration-[25000ms] h-16 ease-out w-full opacity-100" style="background-image: image-set(url('https://cdn.shopify.com/b/shopify-brochure2-assets/074fcc4445ea4294c9addb6d4a5ded56.webp?width=232&amp;height=77') 1x, url('https://cdn.shopify.com/b/shopify-brochure2-assets/074fcc4445ea4294c9addb6d4a5ded56.webp') 2x); background-size: 200px; background-repeat: no-repeat; background-position: left bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isScroll: true
    }
  },
  methods: {
    onMove() {
      this.isScroll = false
    },
    onLeave() {
      this.isScroll = true
    }
  }
}
</script>

<style scoped>
@import url('../../../static/css5/index/home-90Pn1rZ4.css');
</style>
<style scoped>
.scroll-box:hover .scrolling-box {
  animation-play-state: paused;
}
.scrolling-box {
  animation: bounce 30s linear infinite;
}
.scrolling-box:nth-child(2) {
  animation: bounce 30s linear infinite;
  animation-delay: calc(30 / -2);
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
