<template>
  <!-- <slot name="banner" :recommend="recommend"></slot> -->
  <!-- 第一板块 -->
  <div id="2459882340" mod-name="gmod-h5-rax-deals-and-new-arrivals" data-spm="2459882340" class="rax-view-v2 module-item mui-zebra-module aec-default">
    <div class="rax-view-v2" data-before-current-y="712" data-appeared="true" data-has-appeared="true"></div>
    <div class="rax-view-v2" style="margin: 0px auto">
      <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--out_container--3EW0IOU" style="width: 1632px; font-family: 'TT Norms Pro'; margin: 20px auto">
        <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--slider_out_wrap--2NgrjDb" style="width: 800px">
          <!---->
          <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--title_wrap--n4qlkxW" style="background-color: rgb(255, 235, 248); width: 800px">
            <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--pc_title_text--2t2GoNf rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 36px; height: 43px; line-height: 43px; color: black; max-width: 576px">Deals for you</span>
          </div>
          <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--bottomContainer--3wvnljj" style="width: 800px; background-image: linear-gradient(1deg, rgb(255, 235, 248) 0%, rgb(255, 235, 248) 100%)">
            <div class="rax-view-v2" @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in preferred1" :key="i">
              <a target="_blank" href="javascript:" data-spm="d0" id="3256807448527639" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw; padding: 0vw">
                <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--arrivals_item_container--yh2FUbf" style="height: 383px; width: 240px">
                  <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--top_container--Ihh6dP_" style="height: 330px; width: 240px">
                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 240px; height: 330px; border-radius: 0.5333vw 0.5333vw 0px 0px">
                      <img class="item-card-img undefined" :src="item.goods.img" loading="lazy" style="width: 240px; height: 320px; object-fit: cover" />
                      <div class="AIC-MI-mask" style="background-color: rgba(0, 0, 0, 0.04); height: 320px; width: 240px"></div>
                    </div>
                    <!----><!---->
                  </div>
                  <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--bottom_info--3DcsW0y" style="margin-top: 15px">
                    <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--promo_price_str--1qe3D3E rax-text-v2--overflow-hidden rax-text-v2--singleline">${{ item.goods.price }}</span>
                    <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--arrvials_discount_info--1VQuLEK rax-text-v2--overflow-hidden rax-text-v2--singleline" style="height: 28px; line-height: 27px">{{ $common.cutPriceRate(item.goods.price, item.goods.cost_price) }}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--slider_out_wrap--2NgrjDb" style="width: 800px">
          <!---->
          <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--title_wrap--n4qlkxW" style="background-color: rgb(222, 255, 227); width: 800px">
            <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--pc_title_text--2t2GoNf rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 36px; height: 43px; line-height: 43px; color: black; max-width: 576px">New arrivals</span>
          </div>
          <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--bottomContainer--3wvnljj" style="width: 800px; background-image: linear-gradient(1deg, rgb(222, 255, 227) 0%, rgb(222, 255, 227) 100%)">
            <div class="rax-view-v2" @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in preferred2" :key="i">
              <a target="_blank" href="javascript:" data-spm="d0" id="3256807448527639" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw; padding: 0vw">
                <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--arrivals_item_container--yh2FUbf" style="height: 383px; width: 240px">
                  <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--top_container--Ihh6dP_" style="height: 330px; width: 240px">
                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 240px; height: 330px; border-radius: 0.5333vw 0.5333vw 0px 0px">
                      <img class="item-card-img undefined" :src="item.goods.img" loading="lazy" style="width: 240px; height: 320px; object-fit: cover" />
                      <div class="AIC-MI-mask" style="background-color: rgba(0, 0, 0, 0.04); height: 320px; width: 240px"></div>
                    </div>
                    <!----><!---->
                  </div>
                  <div class="rax-view-v2 h5-rax-deals-and-new-arrivals--bottom_info--3DcsW0y" style="margin-top: 15px">
                    <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--promo_price_str--1qe3D3E rax-text-v2--overflow-hidden rax-text-v2--singleline">${{ item.goods.price }}</span>
                    <span class="rax-text-v2 h5-rax-deals-and-new-arrivals--arrvials_discount_info--1VQuLEK rax-text-v2--overflow-hidden rax-text-v2--singleline" style="height: 28px; line-height: 27px">{{ $common.cutPriceRate(item.goods.price, item.goods.cost_price) }}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="t1">Prior Choice</div>
  <div class="bk01">
    <div class="bk01-box">
      <div @click="$router.push({ name: 'detail', query: { id: item.id } })" class="gi-item" v-for="(item, i) in preferred" :key="i">
        <div class="gi-img">
          <img :src="item.goods.img" alt="" />
        </div>
        <div class="gi-info">
          <div class="gi-name">{{ item.goods.name }}</div>
          <div class="gi-price-box">
            <div class="gi-price">US ${{ item.goods.price }}</div>
            <div class="gi-cost-price">/US ${{ item.goods.cost_price }}</div>
            <div class="gi-dist">-{{ (((item.goods.cost_price - item.goods.price) / item.goods.cost_price) * 100).toFixed(0) }}%</div>
          </div>
          <div class="gi-price-box gi-btn">
            <div class="gibox">Buy Now</div>
            <img src="../../static/temp/1/bt1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- 第二板块 -->
  <div style="">
    <div style="width: 100%; margin: 0px auto">
      <div style="">
        <div style="width: 100%; height: 0.01px"></div>
        <div dir="ltr" style="width: 1220px; margin: 0px auto; font-family: Arial, Helvetica, sans-senif, SimSun">
          <div style="width: 1220px; text-align: center; margin-top: 30px; margin-bottom: 25px">
            <span data-locale="663914722_title" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 38px; margin-bottom: 32px; font-size: 32px; line-height: 40px; font-weight: 700; color: rgb(0, 0, 0)">Boutique</span>
          </div>
          <div class="goods-box">
            <div @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in boutique" :key="i" style="width: 100%; position: relative; box-sizing: border-box; display: inline-block; flex-direction: column; align-content: flex-start; flex-shrink: 0">
              <div style="width: 100%; margin-bottom: 16px; float: left">
                <a style="width: 100%; text-decoration: none; display: flex; flex-direction: row; align-items: center; text-align: center; vertical-align: middle; justify-content: center; height: 250px; background: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(242, 242, 242); padding: 8px">
                  <img :src="item.goods.img" style="width: 100%; height: 100%; display: block; max-width: 270px; max-height: 270px; cursor: pointer" />
                </a>
                <a style="text-decoration: none; display: block; width: 100%; font-family: OpenSans">
                  <span style="width: 100%; padding: 0 5px; position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 8px; margin-bottom: 0px; font-size: 13px; line-height: 18px; height: 36px; color: rgb(51, 51, 51); overflow: hidden; text-overflow: ellipsis; cursor: pointer; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2">{{ item.goods.name }}</span></a
                >
                <div style="position: relative; box-sizing: border-box; display: flex; flex-direction: row; place-content: flex-start space-between; flex-shrink: 0">
                  <div style="position: relative; box-sizing: border-box; display: flex; flex-direction: row; align-content: flex-start; flex-shrink: 0; align-items: center">
                    <span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 4px; margin-bottom: 0px; float: left; font-family: OpenSans-Bold; font-weight: 700; font-size: 18px; color: rgb(0, 0, 0); line-height: 24px; overflow: hidden; text-overflow: ellipsis; width: 144px; font-style: italic; padding-right: 2px; white-space: nowrap">US ${{ item.goods.price }}</span>
                  </div>
                  <span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 4px; margin-bottom: 0px; float: right; font-family: OpenSans; font-size: 12px; color: rgb(153, 153, 153); line-height: 24px; text-align: right; white-space: nowrap; overflow: hidden">{{ item.orders }} orders</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style=""></div>
        <div class="ae-pc-productList-footer" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 20px"></div>
      </div>
    </div>
  </div>
  <!-- 返回顶部 -->
  <!-- <div style="">
    <div style="width: 100%; margin: 0px auto">
      <div style="">
        <div style="width: 100%; height: 0.01px"></div>
        <div style="width: 100%; margin: 0px auto; cursor: pointer; min-width: 1200px; align-items: center">
          <a style="text-decoration: none"><img src="../../static/temp/1/f1.jpg" style="width: 100%; display: block; margin: 0px auto" /></a>
        </div>
        <div style=""></div>
        <div class="ae-pc-singleImageText-footer" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 0px"></div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'template1Vue',
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      store: {},
      preferred1: [],
      preferred2: [],
      boutique: []
    }
  },
  created() {
    this.getStoreTemplate()
  },
  methods: {
    getStoreTemplate() {
      this.$api.storeInfo.getStoreTemplate({ id: this.store_id, template: 1 }).then((res) => {
        this.store = res.data.store
        this.preferred1 = res.data.preferred1
        this.preferred2 = res.data.preferred2
        this.boutique = res.data.boutique
      })
    }
  }
}
</script>
<style scoped src="../../static/css5/store/1.css"></style>
<style>
.t1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: #b78b1f;
  font-weight: 700;
  padding-top: 20px;
  /* background-color: rgb(249, 234, 213); */
  line-height: 100px;
}
.bk01 {
  padding: 30px 0 60px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  /* background-color: #f9ead5; */
  position: relative;
}
.bk01-box {
  width: 1220px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.5%, 1fr));
  gap: 20px;
  box-sizing: border-box;
}

.gi-item {
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

.gi-img {
  overflow: hidden;
  height: 70%;
}

.gi-img img {
  width: auto;
  height: 100%;
  width: 100%;
}

.gi-info {
  height: 30%;
}

.gi-name {
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  padding: 0 15px;
  overflow: hidden;
}

.gi-price-box {
  display: flex;
  padding: 0 0px;
  align-items: center;
  justify-content: center;
}

.gi-price {
  font-size: 15px;
  font-weight: 700;
  color: #4d53a1;
}

.gi-cost-price {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  margin-left: 5px;
}

.gi-dist {
  padding: 0px 5px;
  background-color: #62c5c5;
  color: #fff;
  border-radius: 15px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.gi-btn {
  margin-top: 5px;
  cursor: pointer;
}

.goods-box {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(18.5%, 1fr));
}

.gibox {
  background: linear-gradient(358deg, #e9c02b 5%, #f1d774 95%);
  width: 100px;
  padding: 3px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  text-align: center;
}
</style>
