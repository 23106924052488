<template>
  <head-nav-2></head-nav-2>
  <a-spin tip="Loading..." :spinning="spinning">
    <div class="pdp-page-wrap">
      <div class="pdp-body pdp-wrap">
        <div class="pdp-body-top">
          <div class="pdp-body-top-left">
            <div class="pdp-info">
              <!-- 商品轮播 -->
              <GoodsBanner :imgs="goods.imgs"></GoodsBanner>

              <!-- 右侧价格区域 -->
              <div class="pdp-info-right">
                <!-- 顶部板块 -->
                <div class="banner-big-sale--wrap--F1AAma9">
                  <!-- <div class="banner-big-sale--banner--EsoD1OC" :style="'background-image: url(' + require('../static/css5/imgs/1500.png') + ')'">
                    <div class="banner-big-sale--content--Gnhe7QD">
                      <div class="banner-big-sale--text--Sgy1OOI">
                        <img src="https://ae01.alicdn.com/kf/S7dfeb4c2e68841009f7e72db375f0072W/34x46.png" width="10" height="14" />
                        <strong> <span style="color: #017829"> Save</span> </strong>
                        <strong><span style="color: #017829"> $1,443.12 </span> </strong>
                      </div>
                    </div>
                    <img class="banner-big-sale--slogan--D5kPwT3" src="https://ae-pic-a1.aliexpress-media.com/kf/Sa089ebaaa0674f36aad3adbaa5d23b456/648x144.png_.webp" alt="" />
                  </div> -->
                  <!-- <div class="banner-big-sale--countDownWrap--cGQ0lOy">
                  <div class="countDown banner-big-sale--countDown--gJ4V0xi">
                    <span class="before-text">Ends:</span>
                    <div class="timer">Sep 18, 14:59 (GMT+8)</div>
                  </div>
                </div> -->
                </div>

                <!-- 价格区域 -->
                <div class="price--wrap--Z6Vjgmp product-price price--hasDiscount--FHqL0Zi price--hasBigSale--o3t5cgP" data-pl="product-price">
                  <div class="price--current--I3Zeidd product-price-current">
                    <span class="price--currentPriceText--V8_y_b5 pdp-comp-price-current product-price-value">${{ getAttrVal('price') }}</span>
                  </div>
                  <div class="price--original--wEueRiZ">
                    <span class="price--originalText--gxVO5_d">${{ getAttrVal('cost_price') }}</span>
                    <span class="price--discount--Y9uG2LK">{{ getAttrRate() }} off</span>
                  </div>
                </div>

                <!-- <div class="vat-installment--wrap--MyYzjZo"><span class="vat-installment--item--Fgco36c">Price shown before tax</span></div> -->
                <!-- 商品名称 -->
                <div class="title--wrap--UUHae_g">
                  <h1 data-pl="product-title" data-tticheck="true">{{ goods.name }}</h1>
                </div>

                <!-- 星星 评分 -->
                <div class="reviewer--wrap--vGS7G6P" data-pl="product-reviewer">
                  <div class="slick-slider reviewer--slider--qWpbOtA slick-vertical slick-initialized" dir="ltr">
                    <div class="slick-list" style="height: 22px">
                      <div class="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px); height: 88px">
                        <div data-index="2" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style="width: 643px">
                          <div>
                            <div tabindex="-1" style="width: 100%; display: inline-block">
                              <div class="reviewer--box--wVguYsD">
                                <div class="reviewer--rating--xrWWFzx">
                                  <div class="rating--wrap--jg9uoRp">
                                    <!-- 星星 -->
                                    <div class="rating--item--Nt_U0Ff" v-for="star in store_goods?.star_num" :key="star">
                                      <span class="comet-icon comet-icon-starreviewfilled rating--bottom--FSoGJfY">
                                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                          <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                        </svg>
                                      </span>
                                      <span class="comet-icon comet-icon-starreviewfilled rating--top--hpJ_aL4" style="width: 100%">
                                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                          <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                  <strong>&nbsp;&nbsp;{{ store_goods?.star }}&nbsp;&nbsp;</strong>
                                </div>
                                <!-- <a class="reviewer--reviews--cx7Zs_V" href="#nav-review">2 Reviews</a> -->
                                <span>&nbsp;&nbsp;౹&nbsp;&nbsp;</span>
                                <span class="reviewer--sold--ytPeoEy">{{ store_goods?.orders }} {{ $t('detail.orders') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 16px 0px"></div>
                <!-- 商品规格 -->
                <div class="sku--wrap--xgoW06M">
                  <div class="sku-item--wrap--t9Qszzx" v-for="(item, i) in attr" :key="i">
                    <div class="sku-item--property--HuasaIz">
                      <div class="sku-item--title--Z0HLO87">
                        <span
                          >{{ item.name }}:&nbsp;
                          <span> {{ selected[i] && selected[i].name }}</span>
                        </span>
                      </div>
                      <div class="sku-item--box--Lrl6ZXB">
                        <div class="sku-item--skus--StEhULs" data-sku-row="14" data-sku-scroll="false">
                          <template v-for="(vo, k) in item.children" :key="k">
                            <div :class="['sku-item--text--hYfAukP', selected[i] && selected[i].id == vo.id ? 'sku-item--selected--ITGY_EO' : '', hasAttr(vo.id) ? '' : 'disabled']" v-if="vo.type == 1" @click="onAttr(vo, i)">
                              <span>{{ vo.name }}</span>
                            </div>
                            <div :class="['sku-item--image--jMUnnGA', selected[i] && selected[i].id == vo.id ? 'sku-item--selected--ITGY_EO' : '', hasAttr(vo.id) ? '' : 'disabled']" v-if="vo.type == 2" @click="onAttr(vo, i)">
                              <img :src="vo.thumb" alt="black" />
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 16px 0px"></div>
                <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 16px 0px"></div>
                <!-- 保证说明 -->
                <div>
                  <h3 class="assurance--title--PV3zbCQ">{{ $t('_xbb._ptbz') }}</h3>
                  <a class="assurance--item--mQlmKtz" href=""
                    ><div class="assurance--icon--GmhFjTp">
                      <img src="../static/css5/imgs/1500x144.png_.avif" alt="" />
                    </div>
                    <div class="assurance--box--aNSw9f1">
                      <span class="assurance--main--h3tWWhg">{{ $t('_xbb._aqzf') }}</span>
                      <span class="assurance--content--a8A0af_">{{ $t('_xbb._aqzf1') }}</span>
                    </div>
                  </a>
                  <a class="assurance--item--mQlmKtz" href=""
                    ><div class="assurance--icon--GmhFjTp">
                      <img src="../static/css5/imgs/48x48.png_.avif" alt="" />
                    </div>
                    <div class="assurance--box--aNSw9f1">
                      <span class="assurance--main--h3tWWhg">{{ $t('_xbb._aqys') }}</span>
                      <span class="assurance--content--a8A0af_">{{ $t('_xbb._aqys1') }}</span>
                    </div>
                  </a>
                  <a class="assurance--item--mQlmKtz" href="">
                    <div class="assurance--icon--GmhFjTp">
                      <img src="../static/css5/imgs/48x48.png_ (1).avif" alt="" />
                    </div>
                    <div class="assurance--box--aNSw9f1">
                      <span class="assurance--main--h3tWWhg">{{ $t('_xbb._mjbh') }}</span>
                      <span class="assurance--content--a8A0af_">{{ $t('_xbb._mjbh1') }}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="product-main"></div>
            <div></div>
            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>

            <!-- 警示说明 -->
            <!-- <div>
              <div class="title--wrap--NWOaiSp">
                <a class="title--text--Otu0bLr" target="_blank"><h2 class="title--title--O6xcB1q">{{$t('_xbb._mzsm')}}</h2></a>
              </div>
              <div class="remind--msg--bmjMqyw">⚠️Please mind potential fire risk and strictly follow the instruction manual for charging and storage. While using the product, please wear protective equipment and fully comply with the applicable law and regulations.</div>
            </div> -->

            <!-- <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div> -->

            <!-- 相关产品 -->
            <goods-selling :store_id="store_id"></goods-selling>

            <!-- 锚点栏目 -->
            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>
            <div class="comet-v2-anchor navigation--wrap--dDqVSSY notranslate" style="border-bottom: 1px solid #f5f5f5">
              <div class="comet-v2-anchor-scroll-wrapper">
                <div class="comet-v2-anchor-scroller">
                  <a href="#nav-store" class="comet-v2-anchor-link comet-v2-anchor-link-active" title="Store">
                    <!-- <span class="comet-icon comet-icon-locationfilled" style="color: var(--color-grey-10, rgba(25, 25, 25, 1)); width: 14px; margin: 0px 4px 0px 0px">
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                      <path d="M712.533333 138.666667C646.4 85.333333 567.466667 64 512 64c-55.466667 0-134.4 21.333333-200.533333 74.666667C245.333333 194.133333 192 279.466667 192 403.2c0 100.266667 51.2 209.066667 106.666667 296.533333 57.6 89.6 123.733333 166.4 160 206.933334 27.733333 32 76.8 32 104.533333 0 36.266667-40.533333 102.4-117.333333 160-206.933334 55.466667-89.6 106.666667-196.266667 106.666667-296.533333 2.133333-123.733333-51.2-209.066667-117.333334-264.533333z m-200.533333 341.333333c-53.333333 0-96-42.666667-96-96s42.666667-96 96-96 96 42.666667 96 96-42.666667 96-96 96z"></path>
                    </svg>
                  </span> -->
                    {{ $t('_xbb._sjxx') }}
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                  </a>
                  <a href="#nav-specification" class="comet-v2-anchor-link comet-v2-anchor-link-active">
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                    {{ $t('_xbb._ggms') }}
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                  </a>
                  <a href="#nav-description" class="comet-v2-anchor-link comet-v2-anchor-link-active">
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                    {{ $t('_xbb._mswa') }}
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                  </a>
                  <a href="#nav-review" class="comet-v2-anchor-link comet-v2-anchor-link-active">
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                    {{ $t('_xbb._khpl') }} ({{ review_num }})
                    <span style="display: inline-block; width: 14px; height: 8px"></span>
                  </a>
                </div>
              </div>
            </div>

            <!-- 商家信息 -->
            <store-vue :store_id="store_id" :goods_id="goods_id"></store-vue>

            <!-- 商品规格 -->
            <goods-specifications :specs="specs"></goods-specifications>

            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>

            <!-- 商品描述 -->
            <goods-content :content="content_html"></goods-content>

            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>

            <!-- 顾客评论 -->
            <customer-reviews :goods_id="goods_id" @setReviewsNum="setReviewsNum"></customer-reviews>

            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>
            <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 32px 0px"></div>
          </div>

          <!-- 右边价格栏 -->
          <div class="pdp-body-top-right">
            <div class="action--container--Bv1OwjX">
              <div class="action--wrap--BWrENF4" data-spm-anchor-id="a2g0o.detail.0.i18.3f20HV5NHV5NvG">
                <!-- 运输地点 -->
                <!-- <div class="delivery-v2--wrap--Z0JqOXJ" data-pl="product-delivery">
                  <div class="delivery-v2--title--uyNSBOu"><span>Ship to</span></div>
                  <div class="delivery-v2--address--YNCKQZE">
                    <span class="delivery-v2--icon--lR1ryQ1">
                      <span class="comet-icon comet-icon-location">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path
                            d="M311.893333 139.52C377.664 86.4 456.789333 64 512 64c55.210667 0 134.336 22.4 200.106667 75.52C779.157333 193.642667 832 279.530667 832 403.776c0 100.970667-50.624 208.298667-107.008 297.472-57.045333 90.197333-123.178667 166.741333-160.384 207.253333a71.04 71.04 0 0 1-105.216 0c-37.205333-40.512-103.338667-117.056-160.384-207.253333C242.624 612.096 192 504.789333 192 403.797333c0-124.245333 52.842667-210.133333 119.893333-264.298666z m40.213334 49.770667C299.157333 232.042667 256 300.053333 256 403.797333c0 82.154667 42.282667 176.576 97.109333 263.253334 54.186667 85.674667 117.504 159.082667 153.408 198.186666 1.770667 1.92 3.733333 2.56 5.482667 2.56a7.317333 7.317333 0 0 0 5.482667-2.56c35.904-39.104 99.242667-112.512 153.408-198.186666C725.717333 580.394667 768 485.973333 768 403.797333c0-103.744-43.157333-171.733333-96.106667-214.506666C617.664 145.514667 552.789333 128 512 128s-105.664 17.514667-159.893333 61.290667z"
                          ></path>
                          <path d="M512 320a64 64 0 1 0 0 128 64 64 0 0 0 0-128z m-128 64a128 128 0 1 1 256 0 128 128 0 0 1-256 0z"></path>
                        </svg>
                      </span>
                    </span>
                    <span class="delivery-v2--to--Mtweg7y">United States</span>
                  </div>
                </div> -->
                <!-- 栏1 -->
                <!-- <div class="choice-mind--mask--LpLLC79" style="background-image: url('https://ae-pic-a1.aliexpress-media.com/kf/Sf9d607003da2464ab3e02681f5651609e/1500x180.png_.webp')"></div> -->
                <div class="choice-mind--wrap--ai_fJ6V">
                  <div class="choice-mind--mask--LpLLC79" :style="'background-image: url(' + require('../static/css5/imgs/bbbagg1.png') + ')'"></div>
                  <div class="choice-mind--box--fJKH05M">
                    <img src="https://ae01.alicdn.com/kf/S2eef439ead604da69a385490d86118c97/98x42.png" alt="" />&nbsp;&nbsp;
                    <span>ShopifyOutlets {{ $t('_xbb._pdcl') }}</span>
                  </div>
                </div>
                <!-- 说明栏 -->
                <div>
                  <!-- 免运费 -->
                  <div class="shipping--item--F04J6q9">
                    <img class="shipping--icon--vAVr7jZ" src="../static/css5/imgs/2.png" />
                    <div class="shipping--content--ulA3urO">
                      <div class="dynamic-shipping">
                        <div class="dynamic-shipping-line dynamic-shipping-titleLayout" data-spm-anchor-id="a2g0o.detail.0.i10.3f20HV5NHV5NvG">
                          <span
                            ><span style="color: #191919"
                              ><strong>{{ $t('_xbb._myf') }}</strong></span
                            ></span
                          >&nbsp;
                        </div>
                        <div class="dynamic-shipping-line dynamic-shipping-contentLayout" data-spm-anchor-id="a2g0o.detail.0.i20.3f20HV5NHV5NvG">
                          <span
                            ><span style="color: #666666">{{ $t('detail.delivery') }}:</span> </span
                          >&nbsp;
                          <span>
                            <span style="color: #191919">
                              <strong>{{ $t('_xbb._sdsj') }}</strong>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <span class="comet-icon comet-icon-arrowleftrtl32 shipping--arrow--Z3MNNT8">
                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M373.856 288l224 224-224 224a74.656 74.656 0 0 0 105.6 0l197.6-197.6a37.344 37.344 0 0 0 0-52.8L479.456 288a74.656 74.656 0 0 0-105.6 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <!-- 交货快 -->
                  <div class="shipping--item--F04J6q9">
                    <img class="shipping--icon--vAVr7jZ" src="../static/css5/imgs/3.png" />
                    <div class="shipping--content--ulA3urO">
                      <div class="shipping--title--sZAnuQw">
                        <span style="font-weight: bold; color: rgb(0, 0, 0)">{{ $t('_xbb._ksjh') }}</span>
                      </div>
                      <div class="shipping--descList--godpH8F">
                        <div class="shipping--descItem--pqpWeD5">
                          <img class="shipping--descIcon--dGr7zk4" src="../static/css5/imgs/4.png" />&nbsp;
                          <div class="shipping--descText--UVpscND">{{ $t('_xbb._bgdstk') }}</div>
                        </div>
                        <div class="shipping--descItem--pqpWeD5">
                          <img class="shipping--descIcon--dGr7zk4" src="../static/css5/imgs/4.png" />&nbsp;
                          <div class="shipping--descText--UVpscND">{{ $t('_xbb._shbgtk') }}</div>
                        </div>
                        <div class="shipping--descItem--pqpWeD5">
                          <img class="shipping--descIcon--dGr7zk4" src="../static/css5/imgs/4.png" />&nbsp;
                          <div class="shipping--descText--UVpscND">{{ $t('_xbb._s30gtk') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 免费退货 · 价格调整 -->
                  <!-- <div class="shipping--item--F04J6q9">
                    <img class="shipping--icon--vAVr7jZ" src="../static/css5/imgs/5.png" />
                    <div class="shipping--content--ulA3urO">
                      <div class="shipping--title--sZAnuQw">
                        <span style="font-weight: bold; color: rgb(0, 0, 0)">Free returns · Price adjustment</span>
                      </div>
                    </div>
                  </div> -->
                  <!-- 安全与隐私 -->
                  <div class="shipping--item--F04J6q9">
                    <img class="shipping--icon--vAVr7jZ" src="../static/css5/imgs/6.png" />
                    <div class="shipping--content--ulA3urO">
                      <div class="shipping--title--sZAnuQw">
                        <span style="font-weight: bold; color: rgb(0, 0, 0)">{{ $t('_xbb._aqyys') }}</span>
                      </div>
                      <div class="shipping--descList--godpH8F">
                        <div class="shipping--descItem--pqpWeD5">
                          <div class="shipping--descText--UVpscND">{{ $t('_xbb._wjtrxk') }}</div>
                        </div>
                        <div class="shipping--descItem--pqpWeD5">
                          <div class="shipping--descText--UVpscND">{{ $t('_xbb._sfgrxx') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 全球的可持续发展 -->
                  <!-- <div class="shipping--item--F04J6q9">
                    <img class="shipping--icon--vAVr7jZ" src="../static/css5/imgs/7.png" />
                    <div class="shipping--content--ulA3urO">
                      <div class="shipping--title--sZAnuQw" data-spm-anchor-id="a2g0o.detail.0.i12.3f20HV5NHV5NvG">
                        <span style="font-weight: bold; color: rgb(0, 0, 0)">Sustainability at ShopifyOutlets</span>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="comet-v2-divider comet-v2-divider-small split-line--wrap--dVhU29K" style="margin: 16px 0px"></div>

                <!-- 数量 -->
                <div>
                  <div class="quantity--title--wI85xE8">{{ $t('_xbb._sl') }}</div>
                  <div class="comet-v2-input-number quantity--picker--OaDgLYT">
                    <div @click="onSubNum()" :class="['comet-v2-input-number-btn', buyNum == 1 ? 'comet-v2-input-number-btn-disabled' : '', 'comet-v2-input-number-btn-decrease']">
                      <span class="comet-icon comet-icon-subtract">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M170.666667 506.666667c0-20.608 16.725333-37.333333 37.333333-37.333334h608a37.333333 37.333333 0 1 1 0 74.666667H208A37.333333 37.333333 0 0 1 170.666667 506.666667z"></path>
                        </svg>
                      </span>
                    </div>
                    <input type="text" class="comet-v2-input-number-input" value="1" v-model="buyNum" @input="onNumber" />
                    <div @click="onAddNum()" :class="['comet-v2-input-number-btn', buyNum == getAttrVal('total') ? 'comet-v2-input-number-btn-disabled' : '', 'comet-v2-input-number-btn-increase']">
                      <span class="comet-icon comet-icon-add">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false"><path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path></svg>
                      </span>
                    </div>
                  </div>
                  <div class="quantity--info--jnoo_pD">
                    <div>
                      <div style="font-size: 13px">{{ getAttrVal('total') }} {{ $t('_xbb._kcld') }}</div>
                    </div>
                  </div>
                </div>
                <div class="action--stickyWrap--RtlPq66">
                  <button @click="onBuyNow()" type="button" class="comet-v2-btn comet-v2-btn-primary comet-v2-btn-large buy-now--buynow--OH44OI8 comet-v2-btn-important">
                    <span>{{ $t('detail.buy.now') }}</span>
                  </button>
                  <button @click="onAddToCart()" type="button" class="comet-v2-btn comet-v2-btn-large add-to-cart--addtocart--Qhoji3M comet-v2-btn-important">
                    <span>{{ $t('detail.add.to.cart') }}</span>
                  </button>
                  <div class="share-and-wish--wrap--mBF1ugA">
                    <router-link class="share-and-wish--store--Zsc9uDn" :to="{ name: 'storeHome', query: { id: store_id } }">
                      <span class="comet-icon comet-icon-store"
                        ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M764.865306 879.804082H238.236735c-39.706122 0-73.142857-33.436735-73.142857-73.142858V461.844898h62.693877v344.816326c0 6.269388 4.179592 10.44898 10.44898 10.44898h526.628571c6.269388 0 10.44898-4.179592 10.44898-10.44898V461.844898h62.693877v344.816326c0 39.706122-33.436735 73.142857-73.142857 73.142858z"></path>
                          <path
                            d="M501.55102 524.538776c-56.42449 0-102.4-25.077551-129.567347-64.783674-52.244898 48.065306-131.657143 58.514286-190.171428 37.616327-54.334694-20.897959-106.579592-75.232653-83.591837-169.27347 0-2.089796 0-2.089796 2.089796-4.179592L167.183673 148.37551c4.179592-12.538776 16.718367-20.897959 29.257143-20.897959h612.310204c12.538776 0 25.077551 8.359184 29.257143 20.897959l64.783674 175.542857c0 2.089796 0 2.089796 2.089796 4.179592 22.987755 94.040816-27.167347 148.37551-83.591837 169.27347-58.514286 20.897959-135.836735 10.44898-190.171429-37.616327-29.257143 43.885714-81.502041 64.783673-129.567347 64.783674z m-119.118367-160.914286h6.269388c14.628571 2.089796 25.077551 12.538776 25.077551 27.167347 6.269388 52.244898 50.155102 71.053061 87.771428 71.053061 39.706122 0 83.591837-20.897959 87.771429-71.053061 2.089796-14.628571 12.538776-25.077551 25.077551-27.167347 14.628571-2.089796 27.167347 4.179592 33.436735 16.718367 29.257143 56.42449 100.310204 73.142857 150.465306 56.42449 22.987755-8.359184 58.514286-31.346939 43.885714-94.040816L785.763265 188.081633H217.338776l-56.42449 154.644898c-14.628571 60.604082 20.897959 85.681633 43.885714 94.040816 50.155102 18.808163 121.208163 0 150.465306-56.42449 4.179592-10.44898 14.628571-16.718367 27.167347-16.718367z"
                          ></path>
                          <path d="M647.836735 555.885714v188.081633h62.693877v-123.297959c0-35.526531-27.167347-64.783673-62.693877-64.783674z"></path>
                        </svg>
                      </span>
                    </router-link>
                    <!-- <div class="share-and-wish--share--FhIVM_X">
                      <span class="comet-icon comet-icon-share"
                        ><svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path
                            d="M725.333333 608c-51.2 0-96 23.466667-125.866666 61.866667L405.333333 571.733333c6.4-19.2 10.666667-38.4 10.666667-59.733333 0-21.333333-4.266667-40.533333-10.666667-57.6l200.533334-91.733333c29.866667 34.133333 72.533333 55.466667 119.466666 55.466666 87.466667 0 160-72.533333 160-160S812.8 96 725.333333 96s-160 72.533333-160 160c0 17.066667 2.133333 34.133333 8.533334 49.066667l-202.666667 93.866666c-29.866667-29.866667-68.266667-46.933333-113.066667-46.933333-87.466667 0-160 72.533333-160 160s72.533333 160 160 160c44.8 0 83.2-17.066667 113.066667-46.933333l202.666667 100.266666c-4.266667 12.8-6.4 27.733333-6.4 40.533334 0 87.466667 72.533333 160 160 160s160-72.533333 160-160-74.666667-157.866667-162.133334-157.866667z m0-448c53.333333 0 96 42.666667 96 96s-42.666667 96-96 96-96-42.666667-96-96 42.666667-96 96-96z m-469.333333 448c-53.333333 0-96-42.666667-96-96s42.666667-96 96-96 96 42.666667 96 96-42.666667 96-96 96z m469.333333 256c-53.333333 0-96-42.666667-96-96 0-12.8 2.133333-23.466667 6.4-34.133333l6.4-12.8c17.066667-27.733333 46.933333-49.066667 83.2-49.066667 53.333333 0 96 42.666667 96 96s-42.666667 96-96 96z"
                          ></path>
                        </svg>
                      </span>
                    </div> -->
                    <div class="share-and-wish--wish--FAJGuXH" @click="onWishlist()">
                      <span class="comet-icon comet-icon-wishlist">
                        <template v-if="store_goods.is_wish === 2">
                          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                            <path d="M165.589333 578.005333c-83.584-83.584-83.584-219.093333 0-302.677333 83.584-83.584 219.093333-83.584 302.677334 0l43.797333 43.797333 43.797333-43.797333c83.562667-83.584 219.093333-83.584 302.656 0 83.584 83.562667 83.584 219.093333 0 302.656l-323.84 323.84a32 32 0 0 1-45.226666 0l-323.84-323.84z"></path>
                          </svg>
                        </template>
                        <template v-else>
                          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                            <path d="M165.589333 578.005333c-83.584-83.584-83.584-219.093333 0-302.677333 83.584-83.584 219.093333-83.584 302.677334 0l43.797333 43.797333 43.797333-43.797333c83.562667-83.584 219.093333-83.584 302.656 0 83.584 83.562667 83.584 219.093333 0 302.656l-323.84 323.84a32 32 0 0 1-45.226666 0l-323.84-323.84z m45.269334-257.429333a150.016 150.016 0 0 0 0 212.181333l301.205333 301.205334 301.205333-301.226667a150.016 150.016 0 1 0-212.16-212.16l-66.432 66.432a32 32 0 0 1-45.248 0l-66.410666-66.432a150.016 150.016 0 0 0-212.16 0z"></path>
                          </svg>
                        </template>
                      </span>
                      <span class="share-and-wish--wishText--RSGNBfP">&nbsp;{{ store_goods?.wish_num_str }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import GoodsBanner from '@/components/v5/detail/GoodsBanner.vue'
import HeadNav2 from '@/components/v5/head/HeadNav2.vue'

import CustomerReviews from '@/components/v5/detail/CustomerReviews.vue'
import GoodsSpecifications from '@/components/v5/detail/GoodsSpecifications.vue'
import GoodsContent from '@/components/v5/detail/GoodsContent.vue'
import StoreVue from '@/components/v5/detail/StoreVue.vue'
import GoodsSelling from '@/components/v5/detail/GoodsSelling.vue'

export default {
  name: 'DetailVue',
  components: {
    HeadNav2,
    GoodsBanner,
    CustomerReviews,
    GoodsSpecifications,
    GoodsContent,
    StoreVue,
    GoodsSelling
  },
  data() {
    return {
      spinning: true,
      //商品信息
      goods: {},
      //商家商品
      store_goods: {},
      //商品规格
      goods_sku: {
        min: { cost_price: '0.00', price: '0.00' }
      },

      //商品规格数组
      attr: [],
      //规格中可用组合
      tags: {},
      //sku选中商品组合
      sku: {},

      //选中
      selected: {},
      haveattr: [],
      attrSku: {},

      //选中带图的规格
      attrSkuImg: {},

      //商家ID
      store_id: 0,
      goods_id: 0,
      specs: {},
      content_html: '',

      //子组件设置
      review_num: 0,

      //购买
      buyNum: 1
    }
  },
  watch: {
    $route(vNew) {
      console.log(vNew.query, '参数')
      location.reload()
    }
  },
  created() {
    this.goods_id = parseInt(this.$route.query.id)
    this.getGoodsInfo(this.goods_id)
  },
  mounted() {},
  methods: {
    setReviewsNum(num) {
      this.review_num = num
    },
    getGoodsInfo(id) {
      let that = this
      this.$api.goods
        .getGoodsInfo({ id: id })
        .then((res) => {
          that.spinning = false

          let goods = res.data.goods
          let content = res.data.content
          let goods_sku = res.data.goods_sku
          let store_goods = res.data.store_goods

          that.goods = goods
          that.store_goods = store_goods
          that.goods_sku = goods_sku

          that.tags = goods_sku.tags
          that.sku = goods_sku.sku

          that.attr = content.attr

          //获取商家
          that.store_id = that.store_goods.store_id
          that.specs = content.specs
          that.content_html = content.content

          //默认选中
          this.onDefault()
        })
        .catch(() => {
          that.spinning = false
          //   setTimeout(() => {
          //     that.$router.go(-1)
          //   }, 1500)
        })
    },
    onDefault() {
      let that = this
      this.attr.forEach((item, i) => {
        that.onAttr(item.children[0], i)
      })
    },
    /**
     * 判断该规格是否可用
     * @param id
     */
    hasAttr(id) {
      if (this.haveattr.length > 0) {
        return this.haveattr.includes(id)
      }
      return true
    },
    /**
     * 选择规格
     */
    onAttr(vo, i) {
      if (this.haveattr.length > 0 && !this.haveattr.includes(vo.id) && this.attr.length > 1) {
        // return;
      }
      if (this.selected[i] && this.selected[i].id === vo.id) {
        // delete this.selected[i]
        //带图的规格
        // if (vo.type == 2) {
        // this.attrSkuImg = {}
        // }
      } else {
        this.selected[i] = vo

        //带图的规格
        if (vo.type == 2) {
          if (vo.img) {
            this.attrSkuImg = vo
          }
        }
      }

      //计算有的规格
      this.haveattr = this.getAttr()

      //触发规格属性
      this.attrSku = this.getSku()

      //console.log('selected', this.selected)
      //console.log('haveattr', this.haveattr)
      console.log('attrSku', this.attrSku)
    },
    /**
     * 判断交集规格
     */
    getAttr() {
      //console.log(this.selected, 'selected')
      let temp = []
      const arr = this.tags
      for (let i in this.selected) {
        const id = this.selected[i].id
        if (temp.length == 0) {
          temp = [].concat(arr[id])
        } else {
          temp = temp.filter((item) => arr[id].indexOf(item) > -1)
        }
      }
      return temp
    },
    getSku() {
      let temp = []
      if (this.hasCheck()) {
        let key = 'k'
        for (let i in this.selected) {
          if (key != 'k') {
            key += '_'
          }
          key += this.selected[i].id
        }
        //console.log(key, this.sku, 'sku')
        temp = this.sku[key]

        this.buyNum = 1
      }
      return temp
    },
    /**
     * 是否全部选中
     */
    hasCheck() {
      let isCheck = true
      for (let n = 0; n < this.attr.length; n++) {
        if (this.selected[n] === undefined) {
          isCheck = false
        }
      }
      //console.log("是否选择", isCheck);
      return isCheck
    },
    /**
     * 获取显示价格
     */
    getAttrVal(key) {
      // console.log(this.goods_sku, key, Object.keys(this.goods_sku.min).length, 'aaaaaaaaaaa')
      if (Object.keys(this.attrSku).length > 0) {
        return this.attrSku[key]
      } else if (this.goods_sku.min) {
        return this.goods_sku.min[key]
      }
      return ''
    },
    getAttrRate() {
      let price = this.getAttrVal('price')
      let cost_price = this.getAttrVal('cost_price')
      return this.$common.cutPriceRate(price, cost_price)
    },
    onSubNum() {
      this.buyNum--
      if (this.buyNum <= 1) {
        this.buyNum = 1
      }
    },
    onAddNum() {
      this.buyNum++
      let num = this.getAttrVal('total')
      if (this.buyNum > num) {
        this.buyNum = num
      }
    },
    onNumber() {
      let num = this.getAttrVal('total')
      if (this.buyNum > num) {
        this.buyNum = num
      }
    },
    /**
     * 购买
     */
    onBuyNow() {
      // if (!this.attrSku.id) {
      //   this.$message.warning('Please select the specific specifications of the product')
      //   return
      // }

      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      if (Reflect.ownKeys(this.attrSku).length == 0 || this.attrSku.length == 0) {
        this.$message.warning('Please select product specifications')
        return
      }

      this.$router.push({
        name: 'confirm',
        query: {
          type: 'goods',
          goods_id: this.goods_id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        }
      })
    },
    /**
     * 增加到购物车
     */
    onAddToCartAll() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCartAll({
          goods_id: this.goods_id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    },
    /**
     * 增加到购物车
     */
    onAddToCart() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCart({
          goods_id: this.goods_id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    },
    /**
     * 添加到喜欢列表
     */
    onWishlist() {
      let that = this
      this.$message.loading({ content: 'add wishlist...', key: 'msg' })
      this.$api.user
        .saveWishlist({
          goods_id: this.goods_id
        })
        .then((res) => {
          //设置当前喜欢状态
          that.store_goods.is_wish = res.data.code
          that.store_goods.wish_num_str = res.data.num

          //提示成功
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
        })
    }
  }
}
</script>

<style scoped src="../static/css5/index/2.css"></style>
<style scoped src="../static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="../static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="../static/css5/detail/214.css"></style>
<style scoped src="../static/css5/detail/navigation.css"></style>
<style scoped>
.comet-v2-anchor-link-active {
  font-weight: 700;
}
</style>
