<template>
  <div class="service-hall-pc" style="">
    <div class="Page--page--1dBhCAQ node-ocl6ktyru81" style="background-color: var(--color-fill1-2, #f5f5f5); color: rgb(51, 51, 51)">
      <div class="Layout--layout--3GZSgo0" style="background-color: rgb(255, 255, 255)">
        <div class="Layout--header--8mfrVMO Layout--hFixed--x3fVsHj">
          <div class="NavBar--navbar--2Dw6ffN">
            <div class="NavBar--wp--Tpab1Qm">
              <!-- logo -->
              <div class="NavBar--leftItem--28QCCLj">
                <a href="/" class="NavBar--leftItem--28QCCLj" style="padding: 10px 0">
                  <img class="NavBar--logo--2lZWy_w" src="../../static/css5/logo2.png" alt="Help Center" />
                </a>
                <div class="NavBar--splitLine--1zA1Juu"></div>
                <span class="NavBar--title--126Xe1i">Help Center</span>
              </div>
              <!-- 搜索框 -->
              <div>
                <!-- <div>
                  <div class="Search--searchWrapper--1cx4hrb">
                    <span class="next-input-group Search--search--2F5qXFf"
                      ><span class="next-input next-medium next-input-group-auto-width"><input aria-label="search" placeholder="Search for questions ..." height="100%" autocomplete="off" value="" /></span
                      ><span class="next-input-group-addon next-after"
                        ><button type="button" class="next-btn next-medium next-btn-primary"><i class="next-icon next-icon-search next-medium next-icon-alone"></i></button></span
                    ></span>
                  </div>
                </div> -->
              </div>
              <!-- 其他 -->
              <div class="NavBar--rightItem--3HZZaTa">
                <!-- <div class="NavBar--navItem--2W38lR1">Home</div> -->
                <!-- <div class="NavBar--navItem--2W38lR1">Privacy Policy</div> -->
              </div>
            </div>
          </div>
        </div>
        <router-view></router-view>
        <!-- <div class="Layout--footer--3-4eWI3">
          <div class="TouchServicer--fixMode--12_ambG">
            <div class="TouchServicer--content--2_6pOj5">
              <div class="TouchServicer--textWrap--12GRDCz">
                <div class="TouchServicer--firstText--tv-Zclp" style="color: rgb(34, 34, 34)">Online Service</div>
                <div class="TouchServicer--secondText--1pZ_sfg" style="color: rgb(34, 34, 34)">7 * 24 hours</div>
              </div>
              <img src="https://img.alicdn.com/imgextra/i1/O1CN01e5dnzH1hYPT45jx9O_!!6000000004289-2-tps-160-160.png" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <Footer ref="footer"></Footer>
</template>

<script>
import Footer from '@/components/v5/head/FooterVue.vue'
export default {
  name: 'HelpLayout',
  components: {
    Footer
  }
}
</script>

<style>
@import url('../../static/css5/help/theme-57557.css');
</style>

<style src="../../static/css5/help/preview.css"></style>
