<template>
  <div class="es--wrap--RYjm1RT down-box">
    <div>
      <div class="ship-to--menuItem--WdBDsYl">
        <span :class="['ship-to--cssFlag--3qFf5C9', '-country-flag-y2023', '-CN', 'langs-img langs-' + language.lang]"></span>
        <div class="ship-to--text--3H_PaoC" style="display: flex">
          <span class="ship-to--small--1wG1oGl">{{ language.text }}</span>
          <b>
            <span class="comet-icon comet-icon-chevrondown32 ship-to--centerIcon--1viVSdj base--chevronIcon--25sHdop">
              <svg style="fill: var(--nav-color1);" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
              </svg>
            </span>
          </b>
        </div>
        <!-- <span class="comet-icon comet-icon-chevrondown32 base--responseIcon--3et2x1Z">
          <svg style="fill: #000;" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
            <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
          </svg>
        </span> -->
      </div>
    </div>

    <!-- 下拉框 -->
    <div class="comet-v2-popover comet-v2-popover-show-arrow show-box" data-popper-placement="bottom-start" style="position: absolute; inset: 45px 0 auto auto; margin: 0px">
      <div class="comet-v2-popover-arrow" data-popper-arrow="true" style="position: absolute; right: 81px"></div>
      <div class="comet-v2-popover-body" style="border-radius: 24px">
        <div class="comet-v2-popover-content" style="padding: 20px 0">
          <div class="over-y">
            <div class="langs-item" v-for="(vo, i) in list" :key="i" @click="onLang(vo)">
              <div class="langs-box" style="width: 160px">
                <div :class="'langs-img langs-' + vo.lang"></div>
                <span class="lang-item" style="padding: 0">
                  {{ vo.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
export default {
  components: {},
  name: 'HeadVue',
  setup() {
    const { proxy } = getCurrentInstance()
    function change(language) {
      proxy.$i18n.locale = language.lang
      setLangauge(language.lang)
      console.log('切换语言', language)
    }
    return { change }
  },
  data() {
    return {
      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },
      list: this.$Const.LANGUAGE
    }
  },
  created() {
    let config = localStorage.getItem('config')
    try {
      if (config) {
        this.language = JSON.parse(config)
      }
    } catch (error) {
      //console.log(error);
    }
  },
  methods: {
    onLang(vo) {
      console.log(vo)
      this.language = vo
      this.change(this.language)
      localStorage.setItem(this.$Const.KEY_LANG, JSON.stringify(this.language))
      this.$router.go(0)
    }
  }
}
</script>

<style scoped src="@/static/css5/index.css"></style>
<style scoped>
.down-box {
  position: relative;
}

.down-box:hover .show-box {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.show-box {
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.13s ease-in-out;
  z-index: 20;
}

.langs-item {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  /* width: 100%; */
  padding: 0px 20px;
  margin: 0 10px;
  border-radius: 10px;
}

.langs-item:hover {
  background: #f6f6f6;
}

.over-y {
  height: 300px;
  overflow-y: scroll;
}
</style>

<style></style>
