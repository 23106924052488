<template>
  <a-spin tip="Loading..." :spinning="spinning">
    <div class="Layout--wrapper--VN8fXDo">
      <div>
        <div data-spm-anchor-id="0.0.0.i0.59134300v6VAFl">
          <div id="c_l6ktyxu8" class="ViewCard--viewCard--1m6uQ8o MenuList--menuListContainer--31BFbsH node-ocl6ktyru8a MenuList--menuFix--1kh-RA6 viewCard" style="background-color: unset">
            <div class="MenuList--menu--S2VVRqD" id="c_l6ktyxu8-menu-scroll" style="height: calc(100vh - 125px)">
              <ul role="listbox" class="next-menu next-ver next-menu-selectable-single MenuList--menuList--jZf8Ug5" aria-multiselectable="false">
                <li role="option" class="next-menu-sub-menu-wrapper" v-for="(vo, i) in docs" :key="i" @click="onDocs(vo)">
                  <div role="listitem" aria-expanded="false" tabindex="-1" :class="['next-menu-item', vo.key == key ? 'next-selected' : '']">
                    <div class="next-menu-item-inner">
                      <span class="next-menu-item-text" aria-selected="false">
                        <span class="next-menu-item-text">{{ vo.title }}</span>
                        <i class="n-icon next-icon next-icon-arrow-right next-medium next-menu-icon-arrow next-menu-icon-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 内容区域 -->

            <div class="MenuList--content--1wm6ddI customMenuListContent MenuList--contentMode2--3K6-fpT" id="c_l6ktyxu8-content" style="overflow: auto; min-height: calc(100vh - 205px)">
              <template v-if="type == 1">
                <div v-if="key == 'qualification'">
                  <div style="text-align: center; margin-bottom: 65px">
                    <h3 style="font-weight: 700; font-size: 17px">{{ $t('_store._ptzz') }}</h3>
                  </div>
                  <div style="display: flex; flex-direction: row; gap: 20px">
                    <!-- <div class="bbox">
                    <a href="https://find-and-update.company-information.service.gov.uk/company/13322982">
                  <div class="ltd-zs tu" :style="'background-image: url(' + require('@/static/next_store_ltd.png') + ');'"></div>
                  </a>
                  </div> -->
                    <div class="bbox">
                      <div class="trq-zs tu" :style="'background-image: url(' + require('@/static/gxx.png') + ');'"></div>
                    </div>
                    <div class="bbox">
                      <div class="ymx-zs tu" :style="'background-image: url(' + require('@/static/sqs.png') + ');'"></div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="MenuList--headerWrapper--24ETjVf" v-html="content"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
export default {
  name: 'DocsVue',
  data() {
    return {
      look_key: '',

      docs: [],
      content: '',
      key: '',
      type: 1,
      spinning: true
    }
  },
  created() {
    this.look_key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    getDocs() {
      //获取文章
      let that = this
      this.$api.docs.getDocsAll().then((res) => {
        that.spinning = false

        that.docs.push({
          title: this.$t('_store._ptzz'),
          key: 'qualification',
          content: '',
          type: 1
        })

        res.data.forEach((item) => {
          // that.docs.forEach((vo, i) => {
          // if (vo.key == item.key) {
          that.docs.push({ type: 2, ...item })
          // }
          // })
        })

        // that.docs = res.data
        if (that.look_key) {
          res.data.forEach((item) => {
            if (item.key == that.look_key) {
              that.onDocs(item)
            }
          })
        } else {
          that.onDocs(res.data[0])
        }
      })
    },
    onDocs(docs) {
      this.type = docs.type
      this.content = docs.content
      this.key = docs.key
    }
  }
}
</script>

<style>
strong {
  font-weight: 700;
}
</style>
