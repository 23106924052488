<template>
  <div class="Categoey--categoryIconContainer--rMrbITe category-show">
    <div class="Categoey--categoryLeft--34enJNG" style="height: 563px">
      <div class="Categoey--categoryIconTitleContent--1W4D1RT">
        <div class="Categoey--categoryTitle--_3bKGRN">{{$t('_xbb._syfl')}}</div>
      </div>
      <ul class="Categoey--categoryList--2QES_k6">
        <a v-for="(item, i) in categoryList" :key="i" @mouseover="onShowMenu(item)">
          <li data="us_jewelry,_watches_&amp;_accessories" class="Categoey--categoryItem--3hPv6R5" style="">
            <div :class="[item.css, 'i-icon']"></div>
            <div class="Categoey--categoryItemTitle--2uJUqT2" style="">{{ item.name }}</div>
          </li>
        </a>
        <!-- 右侧三级 -->
        <CategoryList5_3 ref="childCate" v-show="isShowChildMenu"></CategoryList5_3>
      </ul>
    </div>
  </div>
</template>

<script>
import CategoryList5_3 from '@/components/v5/category/CategoryList5_3.vue'
export default {
  components: {
    CategoryList5_3
  },
  data() {
    return {
      isShowChildMenu: false,
      currentCategory: {},
      categoryList: []
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    /**
     * 获取分类列表
     */
    getCategory() {
      const that = this
      this.$api.category.getCategoryList().then((res) => {
        that.categoryList = res.data.list
        that.categoryTop = res.data.top
      })
    },
    onShowMenu(item) {
      this.$refs.childCate.setCurrent(item)
      this.isShowChildMenu = true
    }
  }
}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style scoped src="@/static/cate/cate.css"></style>
<style></style>
