<template>
  <div class="es--wrap--RYjm1RT down-box" v-if="isLogin">
    <div>
      <router-link :to="{ name: 'loan' }">
        <div class="ship-to--menuItem--WdBDsYl">
          <span :class="['ship-to--cssFlag--3qFf5C9', '-country-flag-y2023']"></span>
          <DollarOutlined style="color: var(--nav-color1); font-size: 20px" />
          <div class="ship-to--text--3H_PaoC" style="display: flex">
            <span class="ship-to--small--1wG1oGl">{{ $t('_dk._dksq') }}</span>
          </div>
          <!-- <span class="comet-icon comet-icon-chevrondown32 base--responseIcon--3et2x1Z">
          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
            <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
          </svg>
        </span> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { DollarOutlined } from '@ant-design/icons-vue'
export default {
  components: { DollarOutlined },
  name: 'HeadVue',

  data() {
    return {
      sp_info: {},
      isLogin: false
    }
  },
  created() {
    this.tokenStatus()
  },
  methods: {
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
    }
  }
}
</script>

<style scoped src="@/static/css5/index.css"></style>
<style scoped>
.down-box {
  position: relative;
}

.down-box:hover .show-box {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.show-box {
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.13s ease-in-out;
  z-index: 10;
}

.nav-show {
  width: 160px;
}

.langs-item {
  list-style: none;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  width: 100%;
  padding: 0px 20px;
}

.langs-item a {
  color: #000;
}
.langs-item:hover {
  background: #f6f6f6;
}

.line-qrcode:hover .line-box {
  opacity: 1;
  visibility: visible;
}

.line-box {
  opacity: 1;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
</style>

<style></style>
