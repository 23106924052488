import { createRouter, createWebHashHistory } from 'vue-router'
import common from '@/utils/common'

/**-----------------------------------------------*/
/* 基础页面
/* -----------------------------------------------*/
// 首页
import IndexLayout from '../view/index/layout.vue'
import Index from '../view/index/index.vue'
import Docs from '../view/index/docs.vue'
// import Video from '../view/index/video.vue'
import Task from '../view/index/task.vue'
import TaskContent from '../view/index/task_content.vue'
// import Halloween from '../view/index/halloween.vue'
import Christmas from '../view/index/christmas.vue'
import Halloween2 from '../view/index/halloween2.vue'

//商家申请登录
import Beseller from '../view/index/beseller.vue'
import StoreSignin from '../view/signin.vue'
import Forgot from '../view/forgot.vue'

import Loan from '../view/index/loan.vue'
import ApplyLoan from '../view/index/applyloan.vue'
import MyLoan from '../view/index/myloan.vue'
import Activity from '../view/index/activity.vue'

//分类
import Category from '../view/category.vue'

//详情
import Detail from '../view/detail.vue'

//确认订单
import Confirm from '../view/confirm.vue'

//店铺页面
import StoreInfoHome from '../view/storeinfo/home.vue'
import StoreInfoSales from '../view/storeinfo/sales.vue'
import StoreInfoComment from '../view/storeinfo/comment.vue'
import StoreInfoActivity from '../view/storeinfo/activity.vue'

/**-----------------------------------------------*/
/* 会员
/* -----------------------------------------------*/
import Member from '../view/member/member.vue'
import Overview from '../view/member/overview.vue'
import Order from '../view/member/order.vue'
import Payment from '../view/member/payment.vue'
import Address from '../view/member/address.vue'
import Setting from '../view/member/setting.vue'
import Wishlist from '../view/member/wishlist.vue'
import Follow from '../view/member/follow.vue'
import Message from '../view/member/message.vue'
import OrderDetail from '../view/member/order-detail.vue'
import OrderAll from '../view/member/order/order-all.vue'
import OrderUnpaid from '../view/member/order/order-unpaid.vue'
import OrderProcurement from '../view/member/order/order-procurement.vue'
import OrderProcessing from '../view/member/order/order-processing.vue'
import OrderShipped from '../view/member/order/order-shipped.vue'
import OrderCompleted from '../view/member/order/order-completed.vue'
//购物车
import ShopCart from '../view/member/shopcart.vue'

/**-----------------------------------------------*/
/* 商家
/* -----------------------------------------------*/
import MyStore from '../view/store/mystore.vue'
import StoreLayout from '../view/store/layout.vue'

//首页
import storeDashboard from '../view/store/dashboard.vue'

//产品
import StoreProductsList from '../view/store/products/products.vue'
import StoreAddFromHouse from '../view/store/products/addFromHouse.vue'
import storeProductsReviews from '../view/store/products/reviews.vue'

//订单
import StoreOrderList from '../view/store/order/list.vue'
import StoreOrderDetail from '../view/store/order/detail.vue'

import StoreWallet from '../view/store/wallet.vue'

//产品
import StoreAdvertiseFlow from '../view/store/advertise/flow.vue'
import StoreAdvertiseList from '../view/store/advertise/index.vue'
import StoreAdvertiseAdd from '../view/store/advertise/add.vue'

// import StoreSetting from '../view/store/setting'
import StoreStore from '../view/store/setting/store'
import StoreSafety from '../view/store/setting/safety'
import StoreAuth from '../view/store/setting/auth'
import StoreNotify from '../view/store/setting/notify'
import storeMyHome from '../view/store/setting/home'
import storeBenefit from '../view/store/setting/benefit'
import storeRule from '../view/store/setting/rule'
import StoreMessage from '../view/store/message.vue'
import StoreShare from '../view/store/share/share.vue'
import StoreShareList from '../view/store/share/list.vue'
import storeDocs from '../view/store/setting/docs'

import AboutLayout from '../view/about/layout.vue'
import AboutModel from '../view/about/model.vue'
import AboutLiability from '../view/about/liability.vue'
import AboutLiabilitys from '../view/about/liabilitys.vue'
import AboutContact from '../view/about/contact.vue'
import AboutDocs from '../view/about/docs.vue'
import AboutQualification from '../view/about/qualification.vue'

// 帮助

import HelpLayout from '../view/help/layout.vue'
import HelpDocs from '../view/help/docs.vue'
import HelpCompany from '../view/help/company.vue'

const routes = [
  {
    path: '/layout',
    name: 'indexLayout',
    component: IndexLayout,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'index',
        meta: {},
        component: Index
      },
      {
        path: '/beseller',
        name: 'beseller',
        component: Beseller
      },
      {
        path: '/loan',
        name: 'loan',
        component: Loan
      },
      {
        path: '/apply',
        name: 'apply',
        meta: { isLogin: true },
        component: ApplyLoan
      },
      {
        path: '/myloan',
        name: 'myloan',
        meta: { isLogin: true },
        component: MyLoan
      },
      {
        path: '/docs',
        name: 'docs',
        component: Docs
      },
      // {
      //   path: '/video',
      //   name: 'video',
      //   component: Video
      // },

      {
        path: '/task',
        name: 'task',
        component: Task
      },
      {
        path: '/task_content',
        name: 'task_content',
        component: TaskContent
      },
      {
        path: '/halloween',
        name: 'halloween',
        component: Halloween2
      },
      {
        path: '/christmas',
        name: 'christmas',
        component: Christmas
      }
    ]
  },
  {
    path: '/',
    name: 'aboutLayout',
    component: AboutLayout,
    redirect: '/about/model',
    children: [
      {
        path: '/about/model',
        name: 'about_model',
        meta: {},
        component: AboutModel
      },
      {
        path: '/about/liability',
        name: 'about_liability',
        meta: {},
        component: AboutLiability
      },
      {
        path: '/about/liabilitys',
        name: 'about_liabilitys',
        meta: {},
        component: AboutLiabilitys
      },
      {
        path: '/about/contact',
        name: 'about_contact',
        meta: {},
        component: AboutContact
      },
      {
        path: '/about/qualification',
        name: 'about_qualification',
        component: AboutQualification
      },
      {
        path: '/about/docs',
        name: 'about_docs',
        meta: {},
        component: AboutDocs
      }
    ]
  },
  {
    path: '/',
    name: 'helpLayout',
    component: HelpLayout,
    redirect: '/help/docs',
    children: [
      {
        path: '/help/docs',
        name: 'help_docs',
        meta: {},
        component: HelpDocs
      },
      {
        path: '/help/company',
        name: 'help_company',
        meta: {},
        component: HelpCompany
      }
    ]
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity
  },
  {
    path: '/category',
    name: 'category',
    component: Category
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/confirm',
    name: 'confirm',
    meta: { isLogin: true },
    component: Confirm
  },
  // 店铺信息
  {
    path: '/store/home',
    name: 'storeHome',
    component: StoreInfoHome,
    meta: { name: 'storeHome' }
  },
  {
    path: '/store/sales',
    name: 'storeSales',
    component: StoreInfoSales,
    meta: { name: 'StoreInfoSales' }
  },
  {
    path: '/store/comment',
    name: 'storeComment',
    component: StoreInfoComment,
    meta: { name: 'StoreInfoComment' }
  },
  {
    path: '/store/activity',
    name: 'storeActivity',
    component: StoreInfoActivity,
    meta: { name: 'StoreInfoActivity' }
  },
  //会员
  {
    path: '/member/shopcart',
    name: 'shopcart',
    meta: { isLogin: true, type: 1 },
    component: ShopCart
  },
  {
    path: '/member',
    name: 'member',
    meta: { isLogin: true, type: 1 },
    component: Member,
    children: [
      {
        path: '/overview',
        name: 'overview',
        component: Overview,
        meta: { name: 'overview' }
      },
      {
        path: '/payment',
        name: 'payment',
        meta: { name: 'payment' },
        component: Payment
      },
      {
        path: '/address',
        name: 'address',
        meta: { name: 'address' },
        component: Address
      },
      {
        path: '/wishlist',
        name: 'wishlist',
        meta: { name: 'wishlist' },
        component: Wishlist
      },
      {
        path: '/follow',
        name: 'follow',
        meta: { name: 'follow' },
        component: Follow
      },
      {
        path: '/message',
        name: 'message',
        meta: { name: 'message' },
        component: Message
      },
      {
        path: '/setting',
        name: 'setting',
        meta: { name: 'setting' },
        component: Setting
      },
      {
        path: '/order',
        name: 'order',
        component: Order,
        meta: { name: 'order' },
        children: [
          {
            path: '/order/all',
            name: 'orderAll',
            component: OrderAll
          },
          {
            path: '/order/unpaid',
            name: 'orderUnpaid',
            component: OrderUnpaid
          },
          {
            path: '/order/procurement',
            name: 'orderProcurement',
            component: OrderProcurement
          },
          {
            path: '/order/processing',
            name: 'orderProcessing',
            component: OrderProcessing
          },
          {
            path: '/order/shipped',
            name: 'orderShipped',
            component: OrderShipped
          },
          {
            path: '/order/completed',
            name: 'orderCompleted',
            component: OrderCompleted
          }
        ]
      },
      {
        path: '/order-detail',
        name: 'order-detail',
        meta: { name: 'order' },
        component: OrderDetail
      }
    ]
  },
  //店铺
  {
    path: '/mystore',
    meta: { isLogin: true, type: 2 },
    component: MyStore,
    children: [
      {
        path: '/store/dashboard',
        name: 'storeDashboard',
        meta: { name: 'storeDashboard' },
        component: storeDashboard
      },
      {
        path: '/store/products',
        meta: { name: 'storeProducts' },
        component: StoreLayout,
        children: [
          {
            path: '/store/products/list',
            name: 'storeProductsList',
            component: StoreProductsList
          },
          {
            path: '/store/products/addfromhouse',
            name: 'StoreAddFromHouse',
            meta: { key: 'storeProductsList' },
            component: StoreAddFromHouse
          },
          {
            path: '/store/products/reviews',
            name: 'storeProductsReviews',
            component: storeProductsReviews
          }
        ]
      },
      {
        path: '/store/wallet',
        name: 'storeWallet',
        meta: { name: 'storeWallet' },
        component: StoreWallet
      },
      {
        path: '/store/order',
        meta: { name: 'storeOrder' },
        component: StoreLayout,
        children: [
          {
            path: '/store/order/list',
            name: 'storeOrderList',
            component: StoreOrderList
          },
          {
            path: '/store/order/detail',
            name: 'storeOrderDetail',
            meta: { key: 'storeOrderList' },
            component: StoreOrderDetail
          }
        ]
      },
      // {
      //   path: "/store/advertise",
      //   name: "storeAdvertise",
      //   meta: { name: "storeAdvertise" },
      //   component: StoreAdvertise,
      // },
      {
        path: '/store/advertise',
        meta: { name: 'storeAdvertise' },
        component: StoreLayout,
        children: [
          {
            path: '/store/advertise/list',
            name: 'storeAdvertiseList',
            component: StoreAdvertiseList
          },
          {
            path: '/store/advertise/add',
            name: 'storeAdvertiseAdd',
            meta: { key: 'storeAdvertiseList' },
            component: StoreAdvertiseAdd
          },
          {
            path: '/store/advertise/flow',
            name: 'storeAdvertiseFlow',
            component: StoreAdvertiseFlow
          }
        ]
      },
      {
        path: '/store/message',
        name: 'storeMessage',
        meta: { name: 'storeMessage' },
        component: StoreMessage
      },
      {
        path: '/store/share',
        name: 'share',
        meta: { name: 'share' },
        component: StoreLayout,
        children: [
          {
            path: '/store/share',
            name: 'storeShare',
            component: StoreShare
          },
          {
            path: '/store/share/list',
            name: 'storeShareList',
            component: StoreShareList
          }
        ]
      },
      {
        path: '/store/setting',
        name: 'storeSetting',
        meta: { name: 'storeSetting' },
        component: StoreLayout,
        children: [
          {
            path: '/setting/store',
            name: 'storeStore',
            component: StoreStore
          },
          {
            path: '/setting/auth',
            name: 'storeAuth',
            component: StoreAuth
          },
          {
            path: '/setting/safety',
            name: 'storeSafety',
            component: StoreSafety
          },
          {
            path: '/setting/notify',
            name: 'storeNotify',
            component: StoreNotify
          },
          {
            path: '/setting/home',
            name: 'storeMyHome',
            component: storeMyHome
          },
          {
            path: '/setting/benefit',
            name: 'storeBenefit',
            component: storeBenefit
          },
          {
            path: '/setting/rule',
            name: 'storeRule',
            component: storeRule
          },
          {
            path: '/setting/docs',
            name: 'storeDocs',
            component: storeDocs
          }
        ]
      }
    ]
  },
  {
    path: '/store/signin',
    name: 'storeSignin',
    component: StoreSignin
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot
  }
]

// const InitRouter = () => {
//   let router = createRouter({
//     history: createWebHashHistory(),
//     routes
//   })

//   return router
// }

// const router = InitRouter()
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    // console.log(to, from, savedPosition)
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

// 导航守卫
let isload = false
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title
  } else {
    window.document.title = 'Shopify Outlets, Creating a global one-stop shopping platform'
  }

  //目的地是否需要登录
  if (to.meta.isLogin) {
    const token = common.isLogin()
    if (to.name !== 'index' && !token) {
      return next({ name: 'index' })
    }
  }

  if (to.meta.type) {
    const info = common.getInfo()
    //console.log(to.meta, info);
    if (to.meta.type !== info.type) {
      return next({ name: 'index' })
    }
  }

  if (isload) {
    // console.log('加载路由1', to)
    return next()
  }
  // console.log('加载路由2', to)

  isload = true

  next({ ...to })
})

router.afterEach((e) => {
  // console.log('回到顶部', e)
  // window.scrollTo(0, 0)
  if (e.hash == '') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)
  }
})

export default router
