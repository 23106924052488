<template>
  <div class="common-floor" style="padding-top: 32px; padding-bottom: 0" data-spm="category" data-component-id="7588266" data-floor-name="us-category">
    <div class="row-container--root--33oSY1n" style="background-color: #f5f5f5">
      <div data-spm="category" class="us-category--category-new-main--MXEPhC3" id="pc2023Category">
        <div class="us-category--title--3-sGF_U"><span>{{$t('_xbb._asjgw')}}</span></div>
        <div class="us-category--category-part--1dz39UD">
          <div class="us-category--lucency-main--m04W6t-"></div>
          <div class="us-category--loopBtn--32jrIAP ts-prev" style="left: 84px; transform: rotate(180deg); background-position: center; transform-origin: center"></div>
          <div class="es--root--3PUGUTI" style="display: flex; flex-direction: row">
            <swiper
              :modules="modules1"
              :slidesPerView="12"
              :spaceBetween="10"
              :navigation="{ nextEl: '.ts-next', prevEl: '.ts-prev' }"
              :loop="true"
              :autoplay="{
                delay: 2000,
                disableOnInteraction: true
              }"
              style="width: 100%; height: 100%"
            >
              <swiper-slide v-for="(item, i) in topStore" :key="i">
                <div>
                  <router-link :to="{ name: 'storeHome', query: { id: item.id } }" class="us-category--item--28cEhoE us-category--right-item--14iXkWk" aria-hidden="true" target="_blank">
                    <!-- <img src="https://ae-pic-a1.aliexpress-media.com/kf/S7d7842a1811c40d381b291093e7e6b44i/440x440.png_480x480.png_.webp" alt="Jewelry, Watches &amp; Accessories" /> -->
                    <img :src="item.avatar" :alt="item.name" />
                    <span class="us-category--item-title--37T6QdP">{{ item.name }}</span>
                  </router-link>
                  <!-- <a class="us-category--item--28cEhoE us-category--left-item--3FfYOPl" aria-hidden="true" target="_blank" href="https://www.aliexpress.com/p/calp-plus/index.html?osf=category_navigate_newTab2&amp;queryFrom=kingKong&amp;categoryTab=us_sports_%26_entertainment"><img src="https://ae-pic-a1.aliexpress-media.com/kf/Scf0db78b715440389d2793da087c89d24/440x440.png_480x480.png_.webp" alt="Jewelry, Watches &amp; Accessories" /><span class="us-category--item-title--37T6QdP">Sports &amp; Entertainment</span></a> -->
                </div>
              </swiper-slide></swiper
            >
          </div>
          <div class="us-category--loopBtn--32jrIAP ts-next" style="right: 84px; background-position: center"></div>
          <div class="us-category--lucency-right-main--2agXlEv"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FreeMode, Navigation, Pagination, Autoplay } from 'swiper'
export default {
  data() {
    return {
      modules1: [FreeMode, Navigation, Pagination, Autoplay],
      topStore: []
    }
  },
  created() {
    //获取置顶店铺
    let that = this
    this.$api.home.getTopStore().then((res) => {
      that.topStore = res.data
    })
  },
  methods: {}
}
</script>

<style></style>
