<template>
  <div class="pdp-info-left">
    <div class="main-image--wrap--nFuR5UU">
      <div class="image-view--wrap--UhXabwz">
        <!-- 左侧轮播图 -->
        <div class="slider--wrap--krlZ7X9">
          <div class="slider--slider--uRpGJpg">
            <div style="height: 100%">
              <!-- :loop="true" :navigation="{ nextEl: '.sl-next', prevEl: '.sl-prev' }" -->
              <!-- :spaceBetween="8" slidesPerView="auto" :slidesPerGroup="1" :watchSlidesProgress="true" :slideToClickedSlide="true" :centeredSlides="true" @swiper="setThumbsSwiper" @click="onClick" -->
              <!-- <swiper direction="vertical" :modules="modules" :loop="true" :spaceBetween="8"> -->
              <swiper direction="vertical" :modules="modules" :slidesPerView="6" :slidesPerGroup="1" :spaceBetween="10" :slideToClickedSlide="true" :loop="true" :centeredSlides="true" :centeredSlidesBounds="true" :thumbs="{ swiper: thumbsSwiper }" slideThumbActiveClass="slide-thumb-active" style="height: 100%">
                <swiper-slide v-for="(vo, i) in imgs" :key="i">
                  <div class="slider--item--FefNjlj sdfeq1">
                    <div class="slider--img--K0YbWW2 sdfetq">
                      <img :src="vo.img" alt="" />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- 右侧显示图 -->
        <div class="image-view--previewWrap--ZnAzr0D">
          <div class="image-view--previewBox--_oNCSe7">
            <!-- 左边 -->
            <div class="image-view--prev--hsyxuX1 sp-prev">
              <span class="comet-icon comet-icon-chevronleft32" style="font-size: 32px">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                  <path d="M645.344 288l-224 224 224 224a74.656 74.656 0 0 1-105.6 0l-197.6-197.6a37.344 37.344 0 0 1 0-52.8L539.744 288a74.656 74.656 0 0 1 105.6 0z"></path>
                </svg>
              </span>
            </div>
            <!-- 右边 -->
            <div class="image-view--next--uMbeqsc sp-next">
              <span class="comet-icon comet-icon-chevronright32" style="font-size: 32px">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                  <path d="M373.856 288l224 224-224 224a74.656 74.656 0 0 0 105.6 0l197.6-197.6a37.344 37.344 0 0 0 0-52.8L479.456 288a74.656 74.656 0 0 0-105.6 0z"></path>
                </svg>
              </span>
            </div>
            <!-- effect="fade" -->
            <swiper :modules="modules_img" effect="fade" :navigation="{ nextEl: '.sp-next', prevEl: '.sp-prev' }" :spaceBetween="0" :watchSlidesProgress="true" @swiper="setThumbsSwiper" style="width: 100%; height: 100%">
              <swiper-slide v-for="(vo, i) in imgs" :key="i">
                <!-- @mousemove="onViewStart($event)" @mouseleave="onViewStop" -->
                <div class="magnifier--wrap--cF4cafd sdfeqq2r1"  >
                  <img ref="view" class="magnifier--image--EYYoSlr magnifier--zoom--RzRJGZg" :src="vo.img" style="transform: translate(0px, 0px)" />
                  <div class="magnifier--behiver--Wxq3D7r"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="image-view--previewCover--REcZa3D" style="padding-top: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Thumbs, Pagination, EffectFade } from 'swiper'
export default {
  props: {
    imgs: {
      typeof: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      thumbsSwiper: null,
      thumbsSwiper2: null,
      modules: [Pagination, Navigation, Thumbs],
      modules_img: [Navigation, EffectFade],


    }
  },
  created() {},
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper
    },
    onViewStart(e) {
      console.log(e)
      let offsetX = e.offsetX - 450 / 2
      let offsetY = e.offsetY - 450 / 2
      console.log(offsetX, offsetY)
      this.$refs.view.style.width = 'auto'
      this.$refs.view.style.transform = `translate(${offsetX * -1 * 0.8}px, ${offsetY * -1 * 0.8}px) scale(1.6)`
    },
    onViewStop() {
      this.$refs.view.style.width = '100%'
      this.$refs.view.style.transform = `translate(0px, 0px) scale(1)`
    },
  }
}
</script>

<style scoped src="@/static/css5/index/2.css"></style>
<style scoped>
.sdfeq1 {
  padding-bottom: 100%;
  position: relative;
}

.sdfetq {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sdfetq img {
  width: 100%;
  height: auto;
  max-width: initial;
  max-height: inherit;
}

.sdfeqq2r1 img {
  width: 100%;
  height: auto;
  max-width: initial;
  max-height: inherit;
}
.slider--slider--uRpGJpg >>> .swiper-slide-active {
  border: 1px solid #000;
  border-radius: 5px;
}
</style>
