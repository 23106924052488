<template>
  <div class="pc2023-header-tab--all-categories-part--FwIOdmw">
    <div data-spm="allcategoriespc">
      <!-- Categoey--allCategoryContainer--1HhZA0c Categoey--allCategoryContainerZIndex--19uLZPT -->
      <div
        :class="['Categoey--allCategoryContainer--1HhZA0c', isShowMenu ? 'Categoey--allCategoryContainerZIndex--19uLZPT' : '']"
        @mouseover="
          () => {
            isShowMenu = true
          }
        "
        @mouseleave="
          () => {
            isShowMenu = false
            isShowChildMenu = false
          }
        "
      >
        <div>
          <div class="Categoey--categoryLeft--34enJNG" :style="{ height: isShowMenu ? '563px' : '40px' }">
            <!-- Categoey--controlCategory--3xX8k7k -->
            <!-- Categoey--categoryTitleContent--2hUH7a_ -->
            <div :class="[isShowMenu ? 'Categoey--categoryTitleContent--2hUH7a_' : 'Categoey--controlCategory--3xX8k7k']">
              <div class="Categoey--categoryTitle--_3bKGRN">{{$t('_xbb._syfl')}}</div>
            </div>
            <ul class="Categoey--categoryList--2QES_k6">
              <a href="javascript:" v-for="(item, i) in categoryList" :key="i" @mouseover="onShowMenu(item)">
                <li data="us_jewelry,_watches_&amp;_accessories" class="Categoey--categoryItem--3hPv6R5" style="">
                  <div :class="[item.css, 'i-icon']"></div>
                  <!-- <img class="Categoey--cateItemIcon--2q88U8R" src="https://ae01.alicdn.com/kf/Sc4a199d4eee2460592c53f47a9387e94g/27x27.png" /> -->
                  <div class="Categoey--categoryItemTitle--2uJUqT2" style="">{{ item.name }}</div>
                </li>
              </a>
              <!-- 右侧三级 -->
              <CategoryList5_3 ref="childCate" v-show="isShowChildMenu"></CategoryList5_3>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 快捷标签 -->
  <div style="width: 100%; height: 100%; display: flex">
    <div class="pc2023-header-tab--all-tabs-part--1JMWNGu" data-tabs="true">
      <router-link style="font-weight: normal" class="pc2023-header-tab--tab-item--2Hs_3sA" v-for="(item, i) in categoryTop" :key="i" :to="{ name: 'category', query: { id: item.id, level: item.level } }">
        {{ item.name }}
      </router-link>
      <!-- <a target="_blank" data-spm="Fast delivery" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">SuperDeals</a>
    <a target="_blank" data-spm="Top shopper" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">AliExpress Business</a>
    <a target="_blank" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">Jewelry, Watches &amp; Accessories</a>
    <a target="_blank" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">Beauty &amp; Health</a>
    <a target="_blank" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">Luggage, Bags &amp; Shoes</a>
    <a target="_blank" class="pc2023-header-tab--tab-item--2Hs_3sA" href="" style="font-weight: normal">Automotive &amp; Motorcycle</a> -->
    </div>
    <div class="pc2023-header-tab--more-tab--3Xov0hz more-box">
      <span class="pc2023-header-tab--moreText--o8dQidV">{{$t('_xbb._gdfl')}}</span>
      <span class="comet-icon comet-icon-chevrondown32">
        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
          <path d="M253.664 481.536a48 48 0 0 1 65.056-70.4l3.616 3.328L512 608.64l189.664-194.176a48 48 0 0 1 64.192-4.064l3.68 3.264a48 48 0 0 1 4.064 64.192l-3.264 3.68-202.432 207.232a80 80 0 0 1-108.992 2.624l-4.16-3.968-201.088-205.888z"></path>
        </svg>
      </span>
      <div tabindex="-1" class="comet-v2-popover-wrap more-list">
        <div class="comet-v2-popover comet-v2-popover-show-arrow comet-v2-zoom-big-exit-done" data-popper-placement="bottom-end" style="position: absolute; inset: 120px 144px auto auto; margin: 0px; top: 100%; right: -30px">
          <div class="comet-v2-popover-arrow" data-popper-arrow="true" style="position: absolute; left: 198px"></div>
          <div class="comet-v2-popover-body pc2023-header-tab--more-list--3jm9Xqk">
            <div class="comet-v2-popover-content" style="width: 250px">
              <div class="pc2023-header-tab--more-main--2phf-LQ" data-spm="tab" data-spm-max-idx="6">
                <router-link v-for="(item, i) in categoryMore" :key="i" :to="{ name: 'category', query: { id: item.id, level: item.level } }" class="pc2023-header-tab--more-item--A1mqyV4">
                  {{ item.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryList5_3 from '@/components/v5/category/CategoryList5_3.vue'
export default {
  components: {
    CategoryList5_3
  },
  data() {
    return {
      isShowMenu: false,
      isShowChildMenu: false,
      currentCategory: {},
      categoryList: [],
      categoryTop: [],
      categoryMore: []
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    /**
     * 获取分类列表
     */
    getCategory() {
      const that = this
      this.$api.category.getCategoryList().then((res) => {
        that.categoryList = res.data.list
        that.categoryTop = res.data.top
        that.categoryMore = res.data.more
      })
    },
    onShowMenu(item) {
      this.$refs.childCate.setCurrent(item)
      this.isShowChildMenu = true
    }
  }
}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style scoped src="@/static/cate/cate.css"></style>

<style scoped>
.more-list {
  display: none;
}

.more-box {
  position: relative;
}
.more-box:hover .more-list {
  display: block;
}
</style>
