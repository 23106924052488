<template>
  <div class="down-load-app--menuItem--3FNru0r down_en_US down-box">
    <span class="comet-icon comet-icon-qrcode down-load-app--qrCodeNormal--58wUD12">
      <svg style="fill: var(--nav-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
        <path
          d="M128 202.666667A74.666667 74.666667 0 0 1 202.666667 128h149.333333A74.666667 74.666667 0 0 1 426.666667 202.666667v149.333333a74.666667 74.666667 0 0 1-74.666667 74.666667h-149.333333A74.666667 74.666667 0 0 1 128 352v-149.333333zM202.666667 192a10.666667 10.666667 0 0 0-10.666667 10.666667v149.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-149.333333a10.666667 10.666667 0 0 0-10.666667-10.666667h-149.333333zM597.333333 202.666667A74.666667 74.666667 0 0 1 672 128h149.333333A74.666667 74.666667 0 0 1 896 202.666667v149.333333a74.666667 74.666667 0 0 1-74.666667 74.666667h-149.333333a74.666667 74.666667 0 0 1-74.666667-74.666667v-149.333333z m74.666667-10.666667a10.666667 10.666667 0 0 0-10.666667 10.666667v149.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-149.333333a10.666667 10.666667 0 0 0-10.666667-10.666667h-149.333333zM597.333333 672a74.666667 74.666667 0 0 1 74.666667-74.666667h149.333333a74.666667 74.666667 0 0 1 74.666667 74.666667v149.333333a74.666667 74.666667 0 0 1-74.666667 74.666667h-149.333333a74.666667 74.666667 0 0 1-74.666667-74.666667v-149.333333z m74.666667-10.666667a10.666667 10.666667 0 0 0-10.666667 10.666667v149.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-149.333333a10.666667 10.666667 0 0 0-10.666667-10.666667h-149.333333zM128 672A74.666667 74.666667 0 0 1 202.666667 597.333333h149.333333a74.666667 74.666667 0 0 1 74.666667 74.666667v149.333333a74.666667 74.666667 0 0 1-74.666667 74.666667h-149.333333A74.666667 74.666667 0 0 1 128 821.333333v-149.333333z m74.666667-10.666667a10.666667 10.666667 0 0 0-10.666667 10.666667v149.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-149.333333a10.666667 10.666667 0 0 0-10.666667-10.666667h-149.333333z"
        ></path>
        <path d="M512 128a32 32 0 0 1 32 32V512a32 32 0 0 1-32 32H160a32 32 0 0 1 0-64h320v-320A32 32 0 0 1 512 128zM512 597.333333a32 32 0 0 1 32 32v234.666667a32 32 0 0 1-64 0v-234.666667A32 32 0 0 1 512 597.333333zM595.2 512a34.133333 34.133333 0 0 1 34.133333-34.133333h268.8a68.266667 68.266667 0 0 1-68.266666 68.266666h-200.533334a34.133333 34.133333 0 0 1-34.133333-34.133333z"></path>
      </svg>
    </span>
    <div class="down-load-app--text--3b5oaiF">
      <b class="down-load-app--longStr--1OJgHfl">{{$t('_xbb._xz')}}</b>
    </div>
    <span class="comet-icon comet-icon-chevrondown32 base--responseIcon--3et2x1Z">
      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
        <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
      </svg>
    </span>
    <!-- 下拉框 -->
    <div class="comet-v2-popover comet-v2-popover-show-arrow show-box" data-popper-placement="bottom-start" style="position: absolute; inset: 45px 0 auto auto; margin: 0px">
      <div class="comet-v2-popover-arrow" data-popper-arrow="true" style="position: absolute; right: 81px"></div>
      <div class="comet-v2-popover-body" style="border-radius: 24px">
        <div class="comet-v2-popover-content">
          <div class="down-load-app--downloadWrap--mGdiEjR">
            <img src="@/assets/app_android.png" alt="" />
            <img src="@/assets/app_ios.png" alt="" />
          </div>
          <div class="down-load-app--guide--PbUR31v">
            <h3 style="margin: 0; padding: 0">{{$t('_xbb._xzapp')}}</h3>
            <p style="padding: 0">{{$t('_xbb._smewm')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="show-box">
      <div tabindex="-1" class="comet-v2-popover-wrap" style=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style></style>
<style scoped>
.down-box {
  position: relative;
}

.down-box:hover .show-box {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.show-box {
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.13s ease-in-out;
  z-index: 10;
}
</style>
