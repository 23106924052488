export default {
  'nav.account': 'المركز الشخصي ',
  'nav.language': 'الصينية المبسطة',
  'signup.success': 'الاشتراك بنجاح',

  'contact.empty': 'لا يمكن أن تكون معلومات الاتصال فارغة ',
  'phone.country.empty': 'لا يمكن أن تكون بادئة الهاتف فارغة ',
  'phone.empty': 'لا يمكن أن يكون الهاتف فارغًا ',
  'email.valid': 'يرجى إدخال البريد الإلكتروني الصحيح',

  'base.success': 'نجاح',
  'base.save': 'يحفظ',
  'base.slide': 'الرجاء سحب شريط التمرير للتحقق ',
  'base.send.code': 'أرسل الرمز ',
  'base.send.time': '({time}) إعادة إرسال الرمز ',
  'base.send.success': 'أرسل بنجاح ',
  'base.view.more': 'عرض المزيد',
  'base.Language': 'لغة',
  'base.previous': 'الصفحة السابقة',
  'base.next': 'الصفحة التالية',
  'base.total.page': 'إجمالي عدد صفحات {page} ',
  'base.go.to.page': 'انتقل إلى صفحة',
  'base.go': 'اذهب إلى',
  'base.followers': 'متابعون',
  'base.follow': 'يتبع',
  'base.following': 'التالي',
  'base.visit.store': 'تصفح المتجر ',
  'base.contact': 'اتصل بنا',
  'base.delivery': 'وقت التسليم المقدر: 1-7 أيام ',
  'base.search': 'يبحث',
  'base.search.text': 'أدخل كلمات رئيسية للبحث ',
  'base.server': 'خدمة العملاء عبر الإنترنت',

  'text.categories.title': 'فئة المنتج',
  'text.welcome': 'مرحبًا بكم في التسوق! ',
  'text.wishlist': 'قائمة الرغبات',
  'text.account': 'المركز الشخصي ',
  'text.orders': 'طلبات',
  'text.messages': 'رسالة',
  'text.suggest.title': 'عرض حصري',
  'text.suggest.content': 'فقط للأعضاء المميزين العالميين الجدد! ',
  'text.super.deals': 'أفضل المنتجات بأسعار لا تصدق. ',

  'signup.register': 'اشتراك',
  'signup.signin': 'تسجيل الدخول',
  'signup.store.signin': 'تسجيل دخول الأعمال ',
  'signup.sign.out': 'تسجيل خروج',
  'signup.email': 'عنوان البريد الإلكتروني',
  'signup.password': 'كلمة المرور',
  'signup.qr.password': 'تأكيد كلمة المرور',
  'signup.confirm.password': 'تأكيد كلمة المرور',
  'signup.forgot.password': 'هل نسيت كلمة السر',
  'signup.invitation': 'شفرة الدعوة',
  'signup.char': 'شخصية',
  'signup.contains': 'تحتوي على أرقام أو أحرف أو رموز ',
  'signup.qr.contains': 'كلمتا المرور غير متسقتين ',
  'signup.create.account': 'إنشاء حساب',
  'signup.agree': 'إنشاء حساب , أنت توافق ',
  'signup.member.agreement': 'اتفاقية العضو ',
  'signup.and': 'و',
  'signup.privacy.policy': 'سياسة الخصوصية',
  'signup.email.code': 'تأكيد بواسطة البريد الالكتروني',
  'signup.last.step': 'اخر خطوة',
  'signup.send.email': 'الرجاء إدخال الرمز المكون من 4 أرقام المرسل إلى {email} ',
  'signup.modify.email': 'تعديل البريد الإلكتروني ',
  'signup.verify.email': 'البريد الإلكتروني للتحقق ',
  'signup.have.store': 'لديك حساب تاجر ',
  'signup.goto.signin': 'تسجيل دخول الأعمال ',
  'signup.no.store': 'لا يوجد حساب تاجر',
  'signup.goto.store': 'تسجيل التاجر',
  'signup.next': 'التالي',
  'signup.your.email': 'بريدك الالكتروني',
  'signup.code.text': 'رمز التحقق',
  'signup.submit.signup': 'أفتح حساب الأن',
  'signup.smrz': 'مصادقة الاسم الحقيقي',
  'signup.derb': 'الخطوة الثانية',
  'signup.qsrxm': 'الرجاء إدخال اسم المستند',
  'signup.qsrhm': 'الرجاء إدخال رقم الهوية',

  'forgot.title': 'إعادة تعيين كلمة المرور ',
  'forgot.btn.check': 'التحقق من البريد الإلكتروني',
  'forgot.reset.now': 'إعادة التعيين الآن ',

  'store.info.open': 'افتح {y} أسبوع',

  'footer.great.value': 'قيمة عظيمة',
  'footer.great.value.desc': 'نحن نقدم أسعارًا تنافسية لأكثر من 100 مليون عنصر. ',
  'footer.shopping': 'التسوق العالمي ',
  'footer.shopping.desc': 'نشحن إلى أكثر من 200 دولة ومنطقة ، وموقعنا الإلكتروني متاح بسبع لغات',
  'footer.safe.payment': 'الدفع الآمن ',
  'footer.safe.payment.desc': 'ادفع بأكثر طرق الدفع شيوعًا وأمانًا في العالم. ',
  'footer.shop.with.confidence': 'تسوق مع الثقة',
  'footer.shop.with.confidence.desc': 'تغطي سياسة حماية المشتري لدينا عملية الشراء بالكامل. ',
  'footer.help.center': 'مركز المساعدة',
  'footer.help.center.desc': 'مساعدة على مدار الساعة طوال أيام الأسبوع لخلق تجربة تسوق سلسة. ',
  'footer.terms.conditions': 'الأحكام والشروط',
  'footer.return.policy': 'سياسة العائدات',
  'footer.support.policy': 'سياسة الدعم ',
  'footer.privacy.policy': 'سياسة الخصوصية',
  'footer.be.seller': 'كن بائعًا ',
  'footer.apply.now': 'قدم الآن',
  'footer.stay.connected': 'ابق على اتصال',

  'footer.about.us': 'نبذة عنا',
  'footer.about.company': 'ملف تعريف الشركة',
  'footer.about.video': 'مقدمة الفيديو',
  'footer.contact': 'جهة اتصال',

  'footer.my.account': 'حسابي',
  'footer.my.logout': 'تسجيل الخروج',
  'footer.my.order': 'سجل الطلبات',
  'footer.my.wish': 'My Wish List',
  'footer.my.join': 'كن عضوًا شريكًا',
  'footer.email': 'بريد إلكتروني',
  'footer.gfemail': 'بريد إلكتروني رسمي',
  'footer.fwemail': 'البريد الإلكتروني للخدمة',
  'footer.address': 'العنوان',

  'application.success': 'التطبيق ناجح',
  'application.success.desc': 'تم التطبيق بنجاح ، انتقل إلى حساب تسجيل الدخول',

  'category.title': 'فئة ذات صلة ',
  'category.all': 'جميع الفئات',

  'detail.store.home': 'مخزن البيت',
  'detail.sale.items': 'قائمة البند',
  'detail.recommend': 'مُستَحسَن',
  'detail.orders': 'مبيعات',
  'detail.quantity': 'كمية',
  'detail.pieces.available': 'متاح',
  'detail.delivery': 'توصيل',
  'detail.free.shipping': 'ًالشحن مجانا',
  'detail.estimated.delivery': 'التوصيل المتوقع',
  'detail.days': 'أيام',
  'detail.buy.now': 'يشتري',
  'detail.add.to.cart': 'أضف إلى السلة',
  'detail.buyer.protection': 'حماية المشتري',
  'detail.money.guarantee': 'ضمان استعادة الاموال',
  'detail.refund.desc': 'احصل على رد كامل إذا لم يتم وصف العنصر أو لم يتم تسليمه ',
  'detail.description': 'وصف المنتج',
  'detail.customer.reviews': 'آراء العملاء',
  'detail.specifications': 'مواصفات المنتج',
  'detail.top.selling.products': 'أفضل المنتجات مبيعًا ',
  'detail.recommended.for.you': 'موصى به لك',
  'detail.sold': 'مبيعات',
  'detail.receipt': 'تأكيد استلام',
  'detail.receipt.title': 'Cتأكيد استلام الطلب؟ ',
  'detail.receipt.content': 'بعد التأكيد ، يكتمل أمر المعاملة',
  'detail.comment': 'تعليق',

  'confirm.shipping.address': 'عنوان المستلم',
  'confirm.change': 'يتغير',
  'confirm.payment.methods': 'طرق الدفع ',
  'confirm.summary': 'مستعمرة',
  'confirm.total.item.costs': 'مجموع الاشياء',
  'confirm.total': 'المجموع',
  'confirm.checkout': 'الدفع',
  'confirm.place.order': 'ضع طلبًا الآن ',
  'confirm.pay.now': 'ادفع الآن',
  'confirm.order.desc': 'بعد النقر على "تقديم الطلب" ، أؤكد أنني قرأت وأؤكد',
  'confirm.order.policy': 'جميع الشروط والسياسات ',
  'confirm.payment': 'يضمن التسوق العالمي أمان معلوماتك والدفع ',

  'address.title': 'عنوان المستلم',
  'address.default': 'تقصير',
  'address.edit': 'يحرر',
  'address.delete': 'يمسح',
  'address.new': 'أنشئ عنوانًا جديدًا ',
  'address.contact': 'اتصال',
  'address.address': 'عنوان',
  'address.phone': 'رقم التليفون',
  'address.set.default': 'الوضع الإفتراضي',
  'address.confirm': 'يتأكد',
  'address.cancel': 'يلغي',
  'address.del.title': 'هل تريد التأكيد لحذف العنوان؟ ',
  'address.del.content': 'هل أنت متأكد أنك تريد حذف عنوان الشحن هذا؟ ',

  'payment.method': 'طريقة الدفع او السداد',

  'shopping.title': 'عربة التسوق',
  'shopping.back.buy': 'العودة إلى التسوق الخاص ',
  'shopping.start.shopping': 'ابدأ التسوق ',

  'member.account': 'المركز الشخصي ',
  'member.overview': 'ملخص',
  'member.orders': 'طلبات',
  'member.payment': 'محفظتى',
  'member.address': 'عنوان الشحن',
  'member.wishlist': 'قائمة الرغبات',
  'member.followlist': 'قائمة المتابعة ',
  'member.message.center': 'مركز الرسائل',
  'member.setting': 'اعدادات شخصية',
  'member.shop.info': 'معلومات المتجر ',
  'member.shop.setting': 'إعدادات المتجر ',
  'member.order.notify': 'إخطار الطلب الجديد ',
  'member.order.tips': 'عن طريق إرسال بريد إلكتروني إلى البريد الإلكتروني لحساب التاجر ',

  'member.order.title': 'طلبي',
  'member.order.view.all': 'عرض الكل ',
  'member.order.all': 'الجميع',
  'member.order.unpaid': 'غير مدفوعة الأجر ',
  'member.order.paid': 'مدفوع',
  'member.order.procurement': 'المشتريات المعلقة ',
  'member.order.seller.paid': 'مشتراة ',
  'member.order.processing': 'قيد الانتظار',
  'member.order.shipped': 'شحنها',
  'member.order.return': 'عائد',
  'member.order.completed': 'مكتمل',
  'member.order.refund': 'تم رد الأموال ',
  'member.order.all.time': 'الجميع',
  'member.order.empty': 'لا يوجد طلب حتى الآن ',
  'member.order.date': 'تاريخ',
  'member.order.purchase.date': 'شراء',
  'member.order.cpoy': 'ينسخ',
  'member.order.id': 'طلب',
  'member.order.detail': 'تفاصيل الطلب',
  'member.order.logistics': 'المعلومات اللوجستية',

  'member.payment.title': 'محفظتى',
  'member.wallet.balance': 'رصيد المحفظة ',
  'member.crypto.recharge': 'تعبئة رصيد',
  'member.crypto.withdrawal': 'ينسحب',
  'member.crypto.bank': 'بطاقة مصرفية',
  'member.wallet.record': 'سجل إيداع العملات ',
  'member.bankcard.record': 'سجل البطاقة المصرفية ',
  'member.withdrawal.record': 'سجل الانسحاب ',
  'member.income.record': 'سجل الدخل ',
  'member.transaction.record': 'سجل المعاملات',
  'member.wallet.freeze': 'تجميد الأموال',
  'member.wallet.profit': 'الأرباح المقدرة',

  'recharge.currency': 'عملة',
  'recharge.protocol': 'بروتوكول',
  'recharge.qrcode': 'رمز الاستجابة السريعة',
  'recharge.address': 'عنوان المحفظة ',
  'recharge.copy': 'نسخ العنوان ',
  'recharge.ok.text': 'تأكيد',
  'recharge.cancel.text': 'يلغي',

  'bank.recharge.title': 'إعادة شحن البطاقة المصرفية ',
  'bank.title': 'اسم البنك',
  'bank.name': 'اسم',
  'bank.account': 'الحساب',
  'bank.routing': 'التوجيه',
  'bank.code': 'رمز',
  'bank.bankname': 'اسم البنك',
  'bank.bankaddress': 'عنوان البنك',
  'bank.ok.text': 'التالي',
  'bank.ok.prev': 'خطوة سابقة',
  'bank.submit': 'إرسال الآن ',
  'bank.amount': 'مبلغ إعادة الشحن ',
  'bank.amount.desc': 'الرجاء إدخال مبلغ إعادة الشحن ',
  'bank.type': 'يكتب',
  'bank.usd': 'دولار أمريكي',
  'bank.eur': 'اليورو',
  'bank.receipt.number': 'عدد إيصال',
  'bank.receipt.number.desc': 'الرجاء إدخال رقم إيصال المعاملة ',
  'bank.credential.picture': 'صورة الاعتماد ',
  'bank.credential.picture.desc': 'يرجى تحميل صورة الاعتماد ',
  'bank.remark': 'ملاحظة ',
  'bank.upload': 'تحميل الصورة',
  'bank.text.title': 'ملحوظات',
  'bank.text.t1': 'لا تحتاج إلى أي ملاحظات لنقلها , اتركها فارغة ',
  'bank.text.t2': 'بالنسبة للأسر المعيشية في هونغ كونغ ، حدد هونغ كونغ بدلاً من الصين',
  'bank.text.t3': 'الحوالات الأمريكية ، في أيام الأسبوع ، تحويل الحوالات الأوروبية قبل الساعة 3:00 مساءً ، التحويل متاح خلال ساعات عمل البنك!',
  'bank.text.t4': 'وقت الدفع هو T + 1 ، وآخر دفعة هي T + 3 في أوروبا',
  'bank.text.t5': 'قم بإبلاغ المنصة قبل تحويل الأموال لتأكيد ما إذا كان الحساب متاحًا. إذا تم إغلاق الحساب ، فلن يتم دفع أي تعويض.',
  'bank.text.t6': 'الإيداع عن طريق البطاقة المصرفية. تسود معلومات خدمة العملاء.',

  'bank.list.title': 'اسم البنك',
  'bank.list.code': 'رمز بنكي',
  'bank.list.amount': 'مبلغ التحويل ',
  'bank.list.number': 'عدد إيصال',
  'bank.list.img': 'صورة قسيمة ',
  'bank.list.status': 'حالة',
  'bank.list.time': 'وقت',
  'bank.list.status1': 'قيد المراجعة',
  'bank.list.status2': 'اجتاز',
  'bank.list.status3': 'مرفوض',

  'withdrawal.address.desc': 'الرجاء إدخال عنوان المحفظة! ',
  'withdrawal.number': 'رقم',
  'withdrawal.real.number': 'الوصول الفعلي',
  'withdrawal.number.desc': 'الرجاء إدخال مبلغ السحب! ',
  'withdrawal.btn.all': 'الجميع',
  'withdrawal.balance': 'توازن',
  'withdrawal.commission': 'عمولة',
  'withdrawal.actual.amount': 'حساب',
  'withdrawal.notice': 'يلاحظ',
  'withdrawal.notice.text': 'قبل التحويل ، يرجى التأكد من صحة معلومات عنوان الاستلام. بمجرد نقل الأصول ، لا يمكن إعادتها.',
  'withdrawal.notice.content': 'عمولة {name} ({cp_name}): القيمة السوقية الحالية {fee_rate}٪ / معاملة ، الحد الأدنى القياسي: {fee_min} {name} / معاملة',
  'withdrawal.submit': 'يُقدِّم',
  'withdrawal.choice': ' اختر عملة مشفرة ',
  'withdrawal.yzm': 'كود التحقق',
  'withdrawal.fs': 'إرسال',
  'withdrawal.qsryzm': 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',

  'recive.method': 'بروتوكول',
  'recive.amount': 'المبلغ',
  'recive.address': 'عنوان',
  'recive.date': 'وقت',
  'recive.status': 'حالة',
  'recive.create.at': 'وقت المعاملة ',
  'recive.type': 'يكتب',
  'recive.befor': 'قبل المعاملة ',
  'recive.balance': 'توازن',
  'recive.freeze': 'تجميد ',
  'recive.review': 'قيد المراجعة',
  'recive.success': 'ناجح',
  'recive.reject ': ' مرفوض ',

  'advertise.title': 'مركز الترويج ',
  'advertise.shop.title': 'ترويج المتجر ',
  'advertise.shop.status': 'حالة',
  'advertise.shop.expired': 'منتهي الصلاحية',
  'advertise.shop.promotion': 'تعزيز',
  'advertise.shop.expire.date': 'تاريخ انتهاء الصلاحية',
  'advertise.shop.renew': 'تجديد الإعلان ',
  'advertise.shop.payable': 'المبلغ المستحق',
  'advertise.shop.explanation': 'شرح الإعلان ',
  'advertise.shop.text': 'شارك في الترويج الإعلاني للمنصة ، وزد من تعرض المتجر ، وقم بالترويج لأوامر المعاملات',
  'advertise.shop.paynow': 'التجديد على الفور ',
  'advertise.shop.modal.title': 'تأكيد الدفع',
  'advertise.shop.modal.desc': 'تم التأكيد على دفع رسوم الترقية ',
  'advertise.shop.modal.btn': 'تأكيد الدفع',

  'wishlist.title': 'قائمة الرغبات',
  'wishlist.delete': 'يمسح',
  'wishlist.orders': 'مبيعات',

  'followlist.title': 'قائمة المتابعة ',
  'followlist.delete': 'يمسح',
  'followlist.follow': 'يتبع',

  'store.dashboard': 'لوحة القيادة',
  'store.products': 'منتجات',
  'store.products.list': 'قائمة المنتجات ',
  'store.products.reviews': 'تعليقات المنتج',
  'store.orders': 'طلبات',
  'store.wallet': 'محفظة',
  'store.message': 'مركز الرسائل',
  'store.setting': 'إعدادات',
  'store.order.total.profit': 'إجمالي الدخل التشغيلي المقدر',

  'dashboard.store.hour.views': 'زيارات في الوقت الفعلي',
  'dashboard.store.today.views': 'مشاهدات اليوم',
  'dashboard.product.total': 'إجمالي المنتج',
  'dashboard.product.today': 'تمت إضافته اليوم ',
  'dashboard.order.total': 'الطلب الكلي',
  'dashboard.sales.total': 'حجم التداول المقدر',
  'dashboard.sales.real': 'مبيعات حقيقية',
  'dashboard.sales.pay': 'إجمالي المدفوعات',
  'dashboard.sales.profit': 'إجمالي الربح',
  'dashboard.commission.total': 'إجمالي الإيرادات',
  'dashboard.commission.today': 'أرباح اليوم',
  'dashboard.order.sales': 'مبيعات المنتجات ',
  'dashboard.sales.list': 'ترتيب المبيعات ',
  'dashboard.goods.cate.rate': 'نسبة فئة المنتج ',
  'dashboard.goods.wish': 'مثل ترتيب البضائع ',
  'dashboard.january': 'يناير',
  'dashboard.february': 'شهر فبراير',
  'dashboard.march': 'يمشي',
  'dashboard.april': 'أبريل',
  'dashboard.may': 'يمكن',
  'dashboard.june': 'يونيه',
  'dashboard.july': 'يوليو',
  'dashboard.august': 'أغسطس',
  'dashboard.september': 'سبتمبر',
  'dashboard.october': 'اكتوبر',
  'dashboard.november': 'شهر نوفمبر',
  'dashboard.december': 'ديسمبر',

  'products.add.new': 'إضافة منتج جديد ',
  'products.add.from.warehouse': 'إضافة منتجات من المستودع ',
  'products.delete': 'يمسح',
  'products.add': 'يضيف',
  'products.table.img': 'صورة',
  'products.table.name': 'اسم',
  'products.table.category': 'فئة',
  'products.table.wish': 'like',
  'products.table.stock': 'مخزون',
  'products.table.price': 'سعر',
  'products.table.profit': 'ربح',
  'products.table.action': 'فعل',
  'products.search.category': 'بحث حسب الفئة ',
  'products.back.product': 'رجوع إلى قائمة المنتجات ',
  'products.total': 'إجمالي المنتجات ',
  'products.yes': 'نعم',
  'products.no': 'يلغي',
  'products.batch.add': 'إضافة دفعة ',
  'products.ask.add': 'هل أنت متأكد أنك تريد إضافة منتجات؟ ',
  'products.batch.delete': 'حذف دفعة ',
  'products.ask.delete': 'هل أنت متأكد أنك تريد حذف المنتج؟ ',
  'products.top': 'قمة',
  'products.syzd': 'الصفحة الرئيسية أعلى',
  'products.zdwz': 'المركز الأول',
  'products.t1': 'ترقية',
  'products.t2': 'المفضل',
  'products.t3': 'بوتيك',
  'products.t4': 'لا شيء',

  'reviews.title': 'تعليقات المنتج',
  'reviews.product.name': 'منتج',
  'reviews.user.name': 'مستخدم',
  'reviews.star': 'تقييم',
  'reviews.comment': 'مراجعة المحتوى ',
  'reviews.sku': 'مواصفات المنتج',
  'reviews.imgs': 'معرض الصور',
  'reviews.created': 'تاريخ',

  'store.order.purchase': 'شراء الآن ',
  'store.order.purchase.desc': 'هل أنت متأكد أنك تريد الدفع مقابل هذا المنتج؟ ',
  'store.order.purchase.yes': 'ادفع على الفور',
  'store.order.purchase.no': 'يلغي',
  'store.order.desc': 'يلزم الدفع لشراء هذا الطلب ، وستحصل على مزايا بعد إتمام الطلب.',
  'store.order.no': 'رقم الأمر',
  'store.order.number': 'كمية',
  'store.order.buyer': 'مشتر',
  'store.order.total': 'المبلغ الإجمالي',
  'store.order.will.earning': 'كسب',
  'store.order.profit': 'ربح',
  'store.order.dividends': 'توزيعات الأرباح',
  'store.order.payment.status': 'حالة السداد',
  'store.order.seller.buy.status': 'حالة الشراء ',
  'store.order.status': 'حالة الطلب',
  'store.order.date': 'تاريخ الطلب',
  'store.order.purchase.date': 'تاريخ الشراء',
  'store.order.action': 'فعل',
  'store.order.purchase.amount': 'مبلغ الشراء',

  'income.create.at': 'وقت قياسي ',
  'income.order.sn': 'رقم الأمر',
  'income.realpay': 'كمية الطلب',
  'income.profit': 'دخل',

  'setting.avatar': 'الصورة الرمزية للأعمال ',
  'setting.upload': 'رفع',
  'setting.shop.name': 'اسم المحل',
  'setting.shop.phone': 'هاتف الاتصال',
  'setting.shop.address': 'عنوان المحل ',
  'setting.shop.save': 'يحفظ',
  'setting.upload.pic': 'تحميل الصورة',
  'setting.send.pic': 'إرسال صورة',

  'beseller.title': 'تقدم بطلب لتصبح تاجرًا ',
  'beseller.account': 'معلومات الحساب',
  'beseller.store': 'معلومات المتجر',
  'beseller.store.name': 'اسم المتجر ',
  'beseller.store.address': 'عنوان المتجر ',

  'store.home.title': 'إعدادات الصفحة الرئيسية ',
  'store.home.topimg': 'الصورة العلوية ',
  'store.home.banner': 'دائري ',
  'store.home.up3': 'ملاحظات: 3 تحميلات على الأقل ',
  'store.upload.more': 'تحميل صور متعددة ',
  'store.home.columns': 'أعمدة ',
  'store.home.bgimg': 'الصورة الخلفية',
  'store.goods.remark': 'ملاحظة: 5 عناصر في كل سطر ، بحد أقصى 10 عناصر لكل قسم',
  'store.home.select': 'حدد المنتج',
  'store.home.add': 'إضافة عمود ',

  //معلومات شخصية
  'setting.update.user': 'المعلومات الشخصية',
  'setting.update.info': 'تعديل المعلومات',
  'setting.user.avatar': 'الصورة الرمزية للمستخدم',
  'setting.user.nickname': 'لقب المستخدم',
  'setting.user.nickname.desc': 'الرجاء إدخال اسم المستخدم المستعار',

  'setting.safe.title': 'معلومات الأمان',
  'setting.user.passwd': 'تغيير كلمة المرور',
  'setting.passwd.title1': 'كلمة المرور القديمة',
  'setting.passwd.desc1': 'الرجاء إدخال كلمة المرور القديمة',
  'setting.passwd.title2': 'كلمة المرور الجديدة',
  'setting.passwd.desc2': 'الرجاء إدخال كلمة مرور جديدة',
  'setting.passwd.title3': 'تأكيد كلمة المرور',
  'setting.passwd.desc3': 'الرجاء إدخال كلمة مرور التأكيد',
  'setting.invitation': 'دعوة الأصدقاء',
  'setting.copy': 'نسخ',

  'adv.add.products': 'إضافة منتجات ترويجية',
  'adv.list.title': 'ترويج المنتج',
  'adv.type': 'نوع الترويج',
  'adv.begin.time': 'وقت الترويج',
  'adv.end.time': 'وقت الانتهاء',
  'adv.status': 'الحالة',
  'adv.status.s1': 'ترويج',
  'adv.status.s2': 'End',
  'adv.add.back': 'الرجوع إلى القائمة التي تمت ترقيتها',
  'adv.recharge': 'إعادة شحن نقاط الترويج',
  'adv.select.goods': 'تحديد البضائع',
  'adv.select.btn': 'المنتجات المروّجة',
  'adv.recharge.title': 'إعادة شحن نقاط الترويج',
  'adv.recharge.balance': 'رصيد النقاط',
  'adv.point': 'نقطة',
  'adv.point.rate': 'نسبة النقطة',
  'adv.recharge.amount': 'مبلغ الشحن',
  'adv.recharge.input.amount': 'أدخل مبلغ إعادة الشحن',
  'adv.select.mod': 'تحديد لوحة',
  'adv.mod1': 'الأعمدة الموصى بها',
  'adv.mod2': 'العمود المفضل',
  'adv.mod3': 'عمود البيع الأكثر شيوعًا',
  'adv.mod4': 'قائمة ممتازة',
  'adv.mod5': 'قائمة التصنيف',
  'adv.mod6': 'عرض ترويجي مفصل',
  'adv.ge': 'كمية',
  'adv.xs': 'ساعة',
  'adv.xzbk': 'تحديد لوحة',
  'adv.syme': 'الحصة المتبقية',
  'adv.tgsc': 'مدة العرض الترويجي',
  'adv.tgjg': 'سعر الترويج',
  'adv.syye': 'الرصيد الحالي',
  'adv.tgye': 'رصيد الترويج',
  'adv.yj1': 'متوقع',
  'adv.yj2': 'ابدأ الترويج',
  'adv.pay.point': 'نقاط الاستهلاك',

  'wallet.ykcsxf': 'تم خصم رسوم المناولة',
  'wallet.sxf': 'رسوم الخدمة',
  'wallet.zxje': 'الحد الأدنى للمبلغ',
  'wallet.24hxe': 'حد 24 ساعة',
  'wallet.desc.text': 'تحت أي ظرف من الظروف ، لن يطلب منك المسؤول تحويل الأموال إلى حساب ، ولن يطلب منك رمز التحقق. لا تشارك في أنشطة غير قانونية مثل الشراء نيابة عن الآخرين وغسيل الأموال وجمع الأموال غير القانوني ، واحذر من الاحتيال عبر الإنترنت ',
  'wallet.cunbi.text': 'يمكنك إيداع {name} فقط في هذا العنوان ، ولن يتم استرداد الأصول الأخرى',

  'credit.title': 'حد الائتمان',
  'credit.ed': 'الحصة',
  'credit.hk': 'السداد',
  'credit.kyye': 'الرصيد المتاح',
  'credit.dqqk': 'المتأخرات الحالية',
  'credit.hkje': 'مبلغ السداد',
  'credit.hkje.desc': 'الرجاء إدخال مبلغ السداد',
  'credit.cgje': 'مبلغ الشراء',
  'credit.qrz': 'شهادة',
  'credit.srxyj': 'أدخل مبلغ الدفعة الائتمانية',

  'store.gzl': 'متابعة',
  'store.wxz': 'غير محدود',

  'auth.smrz': 'مصادقة الاسم الحقيقي',
  'auth.rzlx': 'يكتب',
  'auth.sfzm': 'واجهة بطاقة الهوية',
  'auth.sffm': 'الجانب الآخر من بطاقة الهوية',
  'auth.zsxm': 'الاسم الحقيقي',
  'auth.zjhm': 'رقم المستند',
  'auth.yyzz': 'رخصة تجارية',
  'auth.gsmc': 'اسم الشركة',
  'auth.shz': 'تدقيق',
  'auth.ytg': 'ناجح',
  'auth.ybh': 'مرفوض',
  'auth.zt': 'ولاية',
  'auth.gr': 'شخصي',
  'auth.gs': 'مَشرُوع',
  'auth.ljtj': 'التقدم بطلب للمصادقة',
  'auth.wrz': 'غير مصدق',

  'credit.qts': 'عرض الوصف',
  'store.spss': 'الحد الأعلى للسلعة',
  'store.dpfh': 'تخزين الأرباح',
  'store.qydj': 'مستوى حقوق الملكية',
  'store.level': 'level',
  'store.jian': 'قطع',

  'order.notify.email': 'نظرًا للتحكم في مخاطر البريد الإلكتروني ، يتم إرسال رسائل البريد الإلكتروني للتذكير بأمر النظام الأساسي بشكل متكرر وسيتم اعتراضها. من أجل استلامها في الوقت المناسب ، يوصى بإضافة {e} إلى تسجيل الدخول القائمة البيضاء للبريد الإلكتروني ',

  'setting.sjxx': 'معلومات العمل',
  'setting.smrz': 'مصادقة الاسم الحقيقي',
  'setting.aqzx': 'مركز الأمن',
  'setting.tzxx': 'معلومات الإعلام',
  'setting.dpzx': 'تزيين الصفحة الرئيسية',
  'setting.sjqy': 'المصالح التجارية',
  'setting.sjdjqy': 'وصف مزايا مستوى التاجر',

  'setting.fhbky': 'العائد الحالي غير متوفر',
  'setting.spgzyzy': 'قواعد وإرشادات المتجر',

  'desc.dqqy': 'حقوق الملكية الحالية',
  'desc.xjqy': 'المصالح التابعة',
  'desc.sjqy_0': 'حد ائتمان المتجر',
  'desc.sjqy_1': 'توزيع أرباح المتجر',
  'desc.sjqy_2': 'عدد المنتجات التي يمكن نشرها',

  'share.yqlb': 'قائمة الدعوة',
  'share.zmj': 'إجمالي البائع',
  'share.zdd': 'الترتيب الإجمالي',
  'share.zsy': 'إجمالي دخل الوساطة',
  'share.wcl': 'غير معالج',
  'share.ycl': 'معالجة',
  'share.ywc': 'مكتمل',
  'share.dpmc': 'اسم المتجر',
  'share.ddl': 'كمية الطلب',
  'share.jjsy': 'دخل السمسرة',
  'share.zts': 'إجمالي عدد العناصر',

  'chat.state': 'حالة',
  'chat.zx': 'عبر الإنترنت',
  'chat.ljz': 'جارٍ الاتصال',

  'bind.wallet.title': 'عنوان السحب',
  'bind.address.title': 'إضافة عنوان',
  'bind.bj': 'تحرير',
  'bind.sc': 'حذف',
  'bind.qrsc': 'هل أنت متأكد أنك تريد حذف هذا العنوان؟',
  'bind.qbdz': 'عنوان المحفظة',
  'bind.tjsj': 'أضف الوقت',
  'bind.cz': 'عملية',
  'pull.address.choice': 'الرجاء اختيار عنوان السحب!',

  'order.sxsj': 'الوقت الفعلي',
  'order.ljsj': 'ساري المفعول على الفور',
  'order.dssj': 'توقيت فعال',

  'store.st.zt': 'حالة المتجر',
  'store.st.zc': 'عادي',
  'store.st.zc.desc': 'متجرك عادي , يرجى الاستمرار فيه',
  'store.st.yc': 'استثناء',
  'store.st.yc.desc': 'يحتوي متجرك على بعض الطلبات غير الطبيعية أو تلقي شكاوى من العملاء',
  'store.st.dj': 'تجميد',
  'store.st.dj.desc': 'متجرك به مخاطر تشغيل معينة والأموال مجمدة',
  'store.st.jy': 'معطل',
  'store.st.jy.desc': 'لقد انتهك متجرك الاتفاقيات والقواعد المتعلقة بالمنصة وتم تعطيله',

  'task.sy': 'الصفحة الرئيسية',
  'task.rwlbo': 'قائمة المهام',
  'task.rwxqo': 'تفاصيل المهمة',
  'task.ljcy': 'شارك الآن',
  'task.gdrw': 'مزيد من المهام',
  'Task.rwlb': ' قائمة مهام الاستيلاء',
  'task.qbrw': 'كافة المهام',
  'task.wcyd': 'شاركت في',
  'Task.qdz': ' الحصول على الطلبات',
  'task.ywc': 'مكتمل',
  'task.djy': 'يتم تداولها',
  'task.ddje': 'Order Amount',
  'task.cyrs': 'عدد المشاركين',
  'task.ewsy': 'مزايا إضافية',
  'task.jzsj': 'الموعد النهائي',
  'task.ckxq': 'عرض التفاصيل',
  'task.rwxq': 'تفاصيل المهمة',
  'task.cylb': 'قائمة المشاركة',
  'task.qdcyrw': 'هل أنت متأكد أنك تريد المشاركة في مهمة الترتيب هذه؟',
  'task.sd': 'نعم',
  'task.bue': 'لا',

  'task.wdsy': 'الإيرادات',
  'task.wdfh': 'توزيعات الأرباح',
  'task.wdqd': 'الحصول على طلب',
  'task.dd.leixin': 'نوع الطلب',
  'task.dd.qdrw': 'مهمة ترتيب سريع',
  'task.dd.ptdd': 'أمر عادي',

  'flow.lltg': 'ترويج التدفق',
  'flow.gmll': 'شراء حزمة البيانات',
  'flow.dqtc': 'الحزمة الحالية',
  'flow.tc1': 'Package',
  'flow.sy2': 'المتبقي',
  'flow.tian3': 'يوم',
  'flow.jlzs': 'العدد الإجمالي للسجلات',
  'flow.lltc': 'حزمة التدفق',
  'flow.ll': 'flow',
  'flow.gmsj': 'وقت الشراء',
  'flow.tcjg': 'سعر الحزمة',
  'flow.sjzf': 'الدفع الفعلي',
  'flow.tcsm': 'وصف الحزمة',
  'flow.tcsm.sm': ' سيؤدي شراء هذه الحزمة إلى زيادة تعرض المتجر واكتساب المزيد من حركة مرور العملاء. من أجل الحصول على المزيد من الطلبات وزيادة إيرادات المتجر. ',

  'flow.tcmc': 'اسم الحزمة',
  'flow.gmsc': 'مدة الشراء',
  'flow.zje': 'المبلغ الإجمالي',
  'flow.jzsj': 'الموعد النهائي',
  'flow.gmrq': 'تاريخ الشراء',
  'flow.tian1': 'يوم',
  'flow.zhou1': 'أسبوع',
  'flow.yue1': 'شهر',

  'share.dj': 'مستوى',

  'share.hz': 'نقل',
  'share.hzd': 'نقل إلى',
  'share.qbye': 'رصيد المحفظة',
  'share.tgje': 'مبلغ الترويج',
  'share.hzje': 'مبلغ التحويل',
  'share.qsrhzje': 'الرجاء إدخال مبلغ التحويل',

  '_inv._title': 'وصف الدعوة',
  '_inv._t1': '1. دعوة وعلاقة مدعوة',
  '_inv._desc1': 'A يدعو B ، B يدعو C ، C يدعو D. أعلى مستوى هو 4 ، إذا دعا D E مرة أخرى ، فلا علاقة لـ E بـ A. ',
  '_inv._t2': '2. يحصل الداعي على مكافأة',
  '_inv._desc2': 'A يحصل على 4٪ من إجمالي المبلغ المكتمل حسب الأمر B ، يحصل A على 2٪ من إجمالي المبلغ المكتمل بالأمر C ، ويحصل A على 1٪ من إجمالي المبلغ المكتمل بواسطة الأمر D',

  '_index._gm': 'شراء',
  '_index._sm': 'الوصف: زيادة عرض المتجر وجذب حركة المرور',

  '_shop._update': '{n} مرة / شهر ، تم التعديل {m} مرة في الشهر الحالي',
  '_chat._hc': 'انسحاب',

  '_footer._suom': 'وصف السياسة',

  '_footer._platform._qualification': 'مؤهلات الشركة',
  '_store._ptzz': 'المؤهلات المتعلقة بمنصة ShopifyOutlets Global',

  '_abt._gywm': 'نبذة عنا',
  '_abt._syms': 'نموذج العمل',
  '_abt._qyzr': 'مسؤولية الشركة',
  '_abt._lxwm': 'اتصل بنا',

  '_wsj._wsjms': 'احتفل بعيد الهالوين بأناقة في متجر الهالوين المخيف الخاص بنا. سواء كنت تبحث عن خدعة أو مكافأة خلال هذا الموسم المرعب، فإن مجموعتنا من أزياء الهالوين والديكورات والإكسسوارات تحتوي على ما يناسب جميع أفراد العائلة. "أضف لمسة تقشعر لها الأبدان مع مجموعاتنا النسائية والرجالية، أو أطلق العنان لخيال طفلك مع الأزياء الفاخرة. بدءًا من الأدوات المنزلية والألعاب اللطيفة التي لا تُنسى، إلى مستحضرات التجميل ومستحضرات التجميل الرائعة، يمكنك العثور على القطع المثالية للاحتفال بأكثر العطلات إثارة لهذا العام. ',

  '_wsj._b1': 'ديكور المنزل الخريفي',
  '_wsj._b2': 'قرعة',
  '_wsj._b3': 'الصفحة الرئيسية',
  '_wsj._b4': 'الأطفال',
  '_wsj._b5': 'الطفل',
  '_wsj._b6': 'سيدات',
  '_wsj._b7': 'فستان فاخر',
  '_wsj._b8': 'مخصص',

  '_wsj._cj': 'مشاركة',
  '_wsj._gz': 'قواعد النشاط',
  '_wsj._flss': 'بحث عن فئة',
  '_wsj._wsnz': 'ملابس الهالوين النسائية',
  '_wsj._wsn': 'ملابس الهالوين للرجال',
  '_wsj._wst': 'ملابس أطفال الهالوين',
  '_wsj._wszs': 'زينة الهالوين',
  '_wsj._wslp': 'هدية الهالوين',

  '_wsj._desc1': '"خاص بالهالوين، خدعة أم حلوى!" في شهر أكتوبر هذا العام، أعددنا لك حفلة كرنفال! تعال واحصل على جميع أنواع المنتجات المخيفة والصادمة، خصومات كبيرة في انتظارك! ',
  '_wsj._lkm': 'اذهب للتسوق',

  '_wsj._wsj': 'الهالوين',
  '_wsj._wsjjz': 'الهالوين قادم',
  '_wsj._mzbt': 'تقويمات مجيء الجمال',
  '_wsj._mznr': 'لقد بدأ العد التنازلي لأروع وقت في السنة مع تقاويم قدوم تناسب الجميع.',

  '_chr._tt1': 'تسوق كل منتجات عيد الميلاد',
  '_chr._tt2': 'زينة عيد الميلاد',
  '_chr._tt3': 'تسوق جميع الهدايا',
  '_chr._tt4': 'تسوق جميع ملابس نوم عيد الميلاد',

  '_chr._dt1': 'الفترة التي تسبق عيد الميلاد هي سحرية تمامًا مثل اليوم الكبير. لذا قم بترتيب هداياك، وتزيين قاعاتك، وتجهيز ملابس النوم المطابقة لسحر عيد الميلاد.',

  '_chr._dtt1': 'ملابس عيد الميلاد',
  '_chr._dtt2': 'مطابقة عيد الميلاد العائلي',
  '_chr._dtt3': 'تقويمات المجيء',
  '_chr._dtt4': 'عيد الميلاد في المنزل',
  '_chr._dtt5': 'متجر مريح',
  '_chr._dtt6': 'الأثاث في الوقت المناسب لعيد الميلاد',

  '_chr._tre1': 'رائج الآن',
  '_chr._tre2': 'أضف القليل من المرح الاحتفالي إلى منزلك من خلال الصيحات التي يحبها الجميع.',
  '_chr._tre3': 'خبز الزنجبيل',
  '_chr._tre4': 'حلوى عيد الميلاد',
  '_chr._tre5': 'عيد الميلاد',

  '_chr._ttre1': 'بطاقات وورق تغليف',
  '_chr._ttre2': 'الألعاب والألعاب',
  '_chr._ttre3': 'هدايا الأطعمة والمشروبات',
  '_chr._ttre4': 'حشوات التخزين',
  '_chr._ttre5': 'سانتا السري',
  '_chr._ttre6': 'عيد الميلاد الأول للطفل',
  '_chr._ttre7': 'هدايا الجمال',
  '_chr._ttre8': 'هدايا مميزة',
  '_chr._ttre9': 'هدايا مخصصة',

  '_sdj._sdnz': 'ملابس نسائية',
  '_sdj._sdn': 'ملابس رجالية',
  '_sdj._sdetlw': 'هدية',
  '_sdj._sdzs': 'زخرفة',
  '_sdj._sdjj': 'لعبة',

  '_sdj._title1': 'عيد الميلاد قادم',
  '_sdj._desc1': '"عيد الميلاد: موسم العطاء والامتنان." في شهر ديسمبر من هذا العام، أعددنا لك حفلة عائلية دافئة ومزينة بألوان زاهية! تعال واحصل على مجموعة متنوعة من المنتجات المشرقة والدافئة مع خصومات كبيرة في انتظارك! ',

  '_ssd._lhpp': 'دفع مرن',
  '_ssd._lzhq': 'المصدر العالمي',
  '_ssd._pwyx': 'تم تقييمه على أنه ممتاز',
  '_ssd._qsth': 'إرجاع سهل',
  '_ssd._jgppcn': 'مطابقة الأسعار والالتزام',
  '_ssd._ztbt': 'إنشاء مركز تسوق شامل عالمي',
  '_ssd._yzspl': 'توريد المخزون مباشرة من أكثر من 8000 علامة تجارية مستقلة',
  '_ssd._zcgm': 'سجل للشراء',
  '_ssd._zccs': 'التسجيل للبيع',

  '_ssd._dlwz': 'تسجيل الدخول والتسجيل',

  '_ssd._qjrgwc': 'أضف الكل إلى سلة التسوق',

  '_zddy._gzlb': 'قائمة المراقبة',
  '_zddy._zsersxs': 'تم بيعه في shopifyoutlets',
  '_zddy._zccwsj': 'سجل كتاجر',
  '_zddy._dlyh': 'تسجيل الدخول',
  '_zddy._zcyh': 'تسجيل',
  '_zddy._xyh': 'مستخدم جديد',
  '_zddy._gwc': 'عربة التسوق',
  '_zddy._znxx': 'رسالة',
  '_zddy._zshy': 'الأكثر شعبية',
  '_zddy._ddl': 'طلب',
  '_zddy._mysj': 'لا توجد بيانات',
  '_zddy._ckgd': 'شاهد المزيد',
  '_zddy._tjsp': 'المنتجات الموصى بها',
  '_zddy._rmsp': 'منتجات رائجة، اتجاهات جديدة، المزيد من فرص الشراء',
  '_zddy._xsp': 'منتج جديد',
  '_zddy._jlzzr': 'أهم العناصر في الأسبوعين الماضيين',
  '_zddy._ljgm': 'اشتر الآن',
  '_zddy._gdsp': 'المنتجات الأكثر شهرة',
  '_zddy._ssbt': 'بحث',
  '_zddy._ssgjz': 'أنا أبحث عن..',

  '_zddy._bdhb': 'العملة المحلية',
  '_zddy._xzbdhb': 'اختر العملة المحلية',
  '_zddy._zje': 'المبلغ الإجمالي',
  '_zddy._xzzffs': 'يرجى اختيار طريقة الدفع التي تناسبك',
  '_zddy._jmhb': 'عملة مشفرة',

  '_zddy._cp': 'منتج',
  '_zddy._sj': 'رجل أعمال',
  '_zddy._mj': 'البائع',
  '_zddy._fhsy': 'العودة إلى الصفحة الرئيسية',

  '_sjsy._ddpl': 'قائمة الطلبات',
  '_yhqd._czbz': 'يرجى الاتصال بخدمة العملاء المخصصة لتأكيد معلومات إعادة الشحن قبل إعادة الشحن.',
  '_yhqd._lxkf': 'اتصل بخدمة العملاء',
  '_yhqd._qsrje': 'الرجاء إدخال مبلغ إعادة الشحن',
  '_yhqd._wrhyhtd': 'لا الوصول إلى البنك',
  '_yhqd._yhzz': 'التحويل المصرفي',
  '_yhbt._yhlx': 'نوع البنك',
  '_zcyz._sjdxyz': 'التحقق من الرسائل القصيرة للهاتف المحمول',
  '_zcyz._sjhm': 'رقم التليفون',
  '_zcyz._srsjhm': 'أدخل رقم الجوال',
  '_zzsd._qsrzzje': 'الرجاء إدخال مبلغ التحويل',
  '_zzsd._zzje': 'مبلغ التحويل',
  '_txcd._qsrtxje': 'الرجاء إدخال مبلغ السحب',
  '_txcd._skr': 'المستفيد',
  '_txcd._skr._ms': 'الرجاء إدخال اسم حامل البطاقة',
  '_txcd._skzh': 'رقم حساب الدفع',
  '_txcd._skzh._ms': 'الرجاء إدخال رقم حساب الدفع',
  '_txcd._tjyhk': 'إضافة البطاقة المصرفية',
  '_txcd._txje': 'كمية السحب',
  '_txcd._wdyhdz': 'عنوان البنك',
  '_txcd._yhdm': 'شفرة',
  '_txcd._yhdm._ms': 'الرجاء إدخال رمز البنك',
  '_txcd._yhdz': 'عنوان',
  '_txcd._yhdz._ms': 'الرجاء إدخال عنوان البنك',
  '_txcd._yhlx': 'يكتب',
  '_txcd._yhlx._ms': 'الرجاء إدخال نوع البطاقة المصرفية',
  '_txcd._yhly': 'التوجيه',
  '_txcd._yhly._ms': 'الرجاء إدخال توجيه البنك',
  '_txcd._yhmc': 'بنك',
  '_txcd._yhmc._ms': 'الرجاء إدخال اسم البنك',
  '_txcd._yhtx': 'السحب النقدي من البنك',

  '_jys._gmjmhb': 'شراء العملة المشفرة',
  '_jys._gmzy': 'يمكنك تسجيل حساب وشراء العملة المشفرة عن طريق الذهاب إلى البورصات التالية ووفقًا لسياسات بلدك الحالية. ثم قم بإعادة الشحن إلى حساب المنصة! ',

  '_kdydj._bt': 'افتح متجرًا واربح جوائز كبيرة',
  '_kdydj._xyzbt': 'الحظ يستمر في الدوران',
  '_kdydj._yx': 'ضخ {n} مرات',
  '_kdydj._xh': 'الاستهلاك {n} نقطة',

  '_kdydj._jf': 'النقاط:',
  '_kdydj._zjjl': 'السجل الفائز',

  '_kdydj._hdgz': 'قواعد النشاط',
  '_kdydj._qx': 'من فضلك',
  '_kdydj._dl': 'تسجيل الدخول',

  '_kdydj._mjdl': 'تسجيل دخول البائع',
  '_kdydj._yxh': 'البريد الإلكتروني',
  '_kdydj._yxhsm': 'الرجاء إدخال بريدك الإلكتروني',
  '_kdydj._yxhmm': 'الرجاء إدخال كلمة المرور',
  '_kdydj._rgmyzh': 'إذا لم يكن لديك حساب',
  '_kdydj._djzc': 'انقر للتسجيل',
  '_kdydj._wjmm': 'نسيت كلمة المرور',

  '_kdydj._wdjp': 'جائزتي',
  '_kdydj._zjts': 'نصائح فائزة',
  '_kdydj._jx': 'متابعة',
  '_kdydj._czl': 'فاز',

  '_kdydj._ljjl': 'مكافأة تراكمية',
  '_kdydj._ycy': 'المشاركة: {n} مرة',
  '_kdydj._cy': 'شاركت {n} مرة',
  '_kdydj._lq': 'استلام',
  '_kdydj._ylq': 'تم الاستلام',
  '_kdydj._wdd': 'لم يتم الوصول إليه',

  '_dk._dkbt1': 'قرض ريادة الأعمال يحل مشكلة اقتراض المال',
  '_dk._dkbt2': 'تقديم قروض ريادة الأعمال وخدمات تداول رأس المال',
  '_dk._dkbt3': 'سنساعدك على حل مشاكل القروض مثل ضيق الأموال، وعدم وجود مكان لاقتراض الأموال، والحرج من مطالبة الأصدقاء باقتراض الأموال. نحن نقدم خدمات احترافية طوال العملية ونجيب على أسئلتك في أي وقت الهدف هو جعل كل قروض القروض شفافة، مما يسمح للعملاء باستخدامها بسرعة وبثقة. ',

  '_dk._zxsq': 'التقديم عبر الإنترنت',
  '_dk._wddk': 'قرضي',
  '_dk._aqwy': 'أمان وخالي من القلق',
  '_dk._lhed': 'حصة مرنة',
  '_dk._lhedb': 'المبلغ: من 5,00 إلى 500,000، فائدة منخفضة، طرق سداد مرنة، بدون رسوم مقدمة، إقراض عبر الإنترنت، بدون مكالمات مراجعة، سرية المعلومات، والإقراض في نفس اليوم. ',
  '_dk._jsfk': 'قرض سريع',
  '_dk._jsfkb': 'اجتياز المراجعة في أسرع ساعة، وسيتم الدفع فورًا بعد المراجعة. ',
  '_dk._aqww1': 'آمن وخالي من القلق',
  '_dk._aqww1b': 'لا يوجد قرض رهن عقاري، ولا يلزم إيداع، ولا توجد مكالمات رد، هذه مسألة خاصة. ',

  '_dk._gywm': 'نبذة عنا',
  '_dk._gywm1': 'نحن ملتزمون بتقديم خدمات القروض المالية، استنادًا إلى فلسفة العمل المتمثلة في "العميل أولاً" ومع مهمة حل مشكلات دوران رأس المال للعملاء، منذ تأسيسها، ساعدت الشركة عددًا لا يحصى من العملاء على حل دوران رأس المال مشاكل. ',
  '_dk._gywm2': 'المبلغ: من 500 إلى 500,000، فائدة منخفضة، طرق سداد مرنة، بدون رسوم مقدمة، إقراض عبر الإنترنت، بدون مكالمات مراجعة، سرية المعلومات، والإقراض في نفس اليوم. ',
  '_dk._gywm3': 'إذا كنت لا ترغب في اقتراض المال من زملاء الدراسة والأصدقاء، يرجى الحضور إلينا. ',
  '_dk._gywm4': 'ملتزمون بخدمات قروض رواد الأعمال',
  '_dk._gywm5': 'حل مشاكلك المالية',
  '_dk._hzhb': 'شريك - منصة الإقراض عبر الإنترنت',
  '_dk._smwda': 'يأتي مستخدمو محطة shopifyoutlets Business العالمية من 112 دولة حول العالم ويستخدمون العملة المشفرة USDT/ETH/BTC/TRX للتسوية. العملة المشفرة هي وسيلة للتداول بلا حدود، مما يتيح إجراء معاملات فورية منخفضة التكلفة حول العالم دون انتظار أو رسوم دولية. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 جميع الحقوق محفوظة',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) مرخصة ومنظمة من قبل هيئة السلوك المالي كوسيط ائتماني وتعمل حصريًا مع المقرض Home Retail Group CardServices Limited، والتي يتم تداولها باسم shopifyoutlets Business Financial Services، shopifyoutlets Business Financial الخدمات مرخصة ومنظمة من قبل هيئة السلوك المالي لتوفير الائتمان. Home Retail GroupCard Services Limited مسجلة في إنجلترا (04007072)، Avebury، 489-499Avebury Boulevard، Milton Keynes، المملكة المتحدة. إم كيه 9 2 إن دبليو. ',

  '_dk._cyzjsq': 'املأ طلب قرض الأعمال',
  '_dk._dkll': 'سعر فائدة القرض',
  '_dk._dkll2': 'وصف سعر فائدة القرض (انقر للعرض)',
  '_dk._gs1': 'الصيغة',
  '_dk._gs2': 'الاهتمام',
  '_dk._gs3': 'مبلغ القرض',
  '_dk._gs4': 'سعر الفائدة السنوي',
  '_dk._gs5': 'عدد أيام القرض',
  '_dk._gs6': 'على سبيل المثال',
  '_dk._gs7': 'مبلغ القرض',
  '_dk._gs8': 'دورة القرض',
  '_dk._gs9': 'يوم',
  '_dk._fkfs': 'طريقة الإقراض',
  '_dk._zhye': 'رصيد الحساب',
  '_dk._dkqx': 'فترة القرض',
  '_dk._dkqx1': 'اختر فترة الإعارة',
  '_dk._sqje': 'مبلغ الطلب',
  '_dk._qsrdk': 'الرجاء إدخال القرض',
  '_dk._dkje': 'مبلغ القرض',
  '_dk._gj': 'الجنسية',
  '_dk._qxzgj': 'الرجاء تحديد الجنسية',
  '_dk._sqr': 'مقدم الطلب',
  '_dk._qsrsqsm': 'الرجاء إدخال اسم مقدم الطلب',
  '_dk._zjhm': 'رقم الهوية',
  '_dk._qsrzjhm': 'الرجاء إدخال رقم الهوية',
  '_dk._zjzp': 'صورة الهوية',
  '_dk._zjzp1': 'يُرجى تحميل صورة للجزء الأمامي من بطاقة هويتك',
  '_dk._zjfm1': 'يُرجى تحميل صورة للجانب الخلفي من بطاقة هويتك',
  '_dk._zjsc1': 'يُرجى تحميل صورة لبطاقة الهوية الخاصة بك',
  '_dk._zjzp2': 'اختر صورة',
  '_dk._pzslt': 'مثال للتصوير الفوتوغرافي',
  '_dk._wytyb': 'لقد قرأت ووافقت',
  '_dk._jkxy': 'اتفاقية القرض',
  '_dk._tjsq': 'إرسال الطلب',

  '_dk._wddk2': 'قرضي',
  '_dk._ydk': 'معار',
  '_dk._cslx': 'توليد الفائدة',
  '_dk._yjhk': 'السداد المقدر',
  '_dk._dkjl': 'سجل القرض',
  '_dk._hkjl': 'سجل السداد',
  '_dk._sqrq': 'تاريخ تقديم الطلب',
  '_dk._hkzq': 'دورة السداد',
  '_dk._gdll': 'سعر الفائدة الثابت',
  '_dk._hkz': 'جاري السداد',
  '_dk._yhk': 'مسدد',
  '_dk._sqz': 'جاري التقديم',
  '_dk._ytg': 'تم النجاح',
  '_dk._ybh': 'مرفوض',
  '_dk._tqhk': 'السداد المبكر',

  '_dk._ljhk': 'السداد فورًا',
  '_dk._qrytqhk': 'هل أنت متأكد من رغبتك في التقدم بطلب السداد مقدمًا؟',
  '_dk._qrytqhk1': 'ملاحظة: بعد نجاح الطلب، سيتم إرجاع المبلغ المودع مباشرة إلى القرض بالأولوية',
  '_dk._dkxq': 'تفاصيل القرض',
  '_dk._dkje1': 'مبلغ القرض',
  '_dk._yhlx': 'الفائدة المستحقة',
  '_dk._hkze': 'إجمالي السداد',
  '_dk._sqhkz': 'التقدم بطلب السداد',
  '_dk._zxydbty': 'يُرجى القراءة بعناية ووضع علامة في المربع للموافقة على "اتفاقية القرض"',
  '_dk._zzsqz': 'جاري التقديم',

  '_dk._dksq': 'رصيد',

  '_dk._cs': 'المعلمات',
  '_dk._nllsm': 'يتم التعبير عنه كنسبة مئوية، على سبيل المثال: 6.25%',
  '_dk._dktssm': 'إجمالي عدد الأيام من بداية الموافقة على القرض إلى نهايتها',
  '_dk._yndts': 'عدد أيام السنة: عادةً 365 يومًا',
  '_dk._zlj': 'رسوم التأخير',

  '_xbb._xz': 'تنزيل',
  '_xbb._xzapp': 'تنزيل تطبيق ShopifyOutlets',
  '_xbb._smewm': 'امسح رمز QR للتنزيل',
  '_xbb._syfl': 'جميع الفئات',
  '_xbb._gdfl': 'المزيد',
  '_xbb._sbbt1': 'توصيل سريع',
  '_xbb._sbbt2': 'المبالغ المستردة متاحة في حالة وجود أية مشكلات',
  '_xbb._sbbt3': 'إرجاع مجاني',
  '_xbb._sbbt4': 'خلال 90 يومًا',
  '_xbb._tjsp1': 'المنتجات الموصى بها',
  '_xbb._tjsp2': 'خصومات كثيرة',
  '_xbb._tjsp3': 'خصم لفترة محدودة',

  '_xbb._asjgw': 'التسوق حسب المتجر',
  '_xbb._gdsp': 'المزيد من المنتجات',
  '_xbb._ljgm': 'اشتر الآن',
  '_xbb._ptbz': 'ضمان ShopifyOutlets',
  '_xbb._aqzf': 'الدفع الآمن',
  '_xbb._aqzf1': 'طريقة دفع يستخدمها العديد من المتسوقين الدوليين',
  '_xbb._aqys': 'الأمان والخصوصية',
  '_xbb._aqys1': 'نحن نحترم خصوصيتك, لذا فإن معلوماتك الشخصية آمنة',
  '_xbb._mjbh': 'حماية المشتري',
  '_xbb._mjbh1': 'إذا لم يتم تسليم طلبك قبل التاريخ المتوقع أو لم تكن راضيًا عن الطلب, فيمكنك استرداد أموالك',
  '_xbb._mzsm': 'تحذير/إخلاء المسؤولية',

  '_xbb._sjxx': 'رجل أعمال',
  '_xbb._ggms': 'المواصفات',
  '_xbb._mswa': 'وصف',
  '_xbb._khpl': 'تعليقات العملاء',
  '_xbb._zmfk': 'تعليقات إيجابية',
  '_xbb._gzrs': 'متابعة',
  '_xbb._gzrsan': 'متابعة',
  '_xbb._ygzrsan': 'تمت المتابعة بالفعل',
  '_xbb._fsxx': 'رسالة',
  '_xbb._myrpl': 'لا توجد تعليقات بعد',
  '_xbb._llgd': 'عرض المزيد',
  '_xbb._pdcl': 'وعد',
  '_xbb._myf': 'شحن مجاني',
  '_xbb._sdsj': 'يتم التسليم خلال حوالي 5-10',
  '_xbb._ksjh': 'توصيل سريع',
  '_xbb._bgdstk': 'استرداد قيمة الحزمة المفقودة',
  '_xbb._shbgtk': 'إذا كانت السلعة تالفة, قم باسترداد أموالك',
  '_xbb._s30gtk': 'المبالغ المستردة إذا لم يتم شحنها خلال 30 يومًا',
  '_xbb._aqyys': 'الأمان والخصوصية',
  '_xbb._wjtrxk': 'الدفع الآمن: لن نشارك معلوماتك الشخصية مع أي طرف ثالث دون موافقتك. ',
  '_xbb._sfgrxx': 'تأمين التفاصيل الشخصية: نحن نحمي خصوصيتك ونحافظ على بياناتك الشخصية آمنة ومأمونة. ',
  '_xbb._sl': 'الكمية',
  '_xbb._kcld': 'المخزون',

  '_xbb._sjrz': 'استقر التاجر',
  '_xbb._hezjh': 'يوفر لك برنامج الشركاء الخاص بنا مجموعة كاملة من الدعم والخدمات التسويقية، وسيقدم فريق خدمة العملاء لدينا الدعم والمشورة الاحترافية لمساعدتك على تحسين عرض منتجك واستراتيجية المبيعات. انضم إلينا الآن! دعونا نخلق فرص عمل أكبر وننمو معًا! ',
  '_xbb._xsje': 'معلومات العمل',
  '_xbb._rgsqjs': 'إذا كنت بائعًا بالفعل',
  '_xbb._qni': 'من فضلك',
  '_xbb._djdl': 'انقر لتسجيل الدخول',
  '_xbb._dplog': 'شعار المتجر',
  '_xbb._dpmc': 'اسم المتجر',
  '_xbb._srdpmc': 'الرجاء إدخال اسم المتجر',
  '_xbb._zjhm': 'رقم الهوية',
  '_xbb._srzjhm': 'الرجاء إدخال رقم الهوية',
  '_xbb._zsxm': 'الاسم الحقيقي',
  '_xbb._srzsxm': 'الرجاء إدخال اسمك الحقيقي',
  '_xbb._srgj': 'البلد',
  '_xbb._srsrgj': 'الرجاء إدخال البلد',
  '_xbb._zjz': 'صورة الهوية',
  '_xbb._zjzm': 'أمام المستند',
  '_xbb._zjfm': 'الجانب الخلفي للشهادة',
  '_xbb._sczjz': 'احتفظ بصورة الهوية',
  '_xbb._pssl': 'مثال للتصوير الفوتوغرافي',
  '_xbb._dlyx': 'البريد الإلكتروني لتسجيل الدخول',
  '_xbb._srdlyx': 'الرجاء إدخال البريد الإلكتروني لتسجيل الدخول',
  '_xbb._dlmm': 'كلمة مرور تسجيل الدخول',
  '_xbb._srdlmm': 'الرجاء إدخال كلمة مرور تسجيل الدخول الخاصة بك',
  '_xbb._qrmm': 'تأكيد كلمة المرور',
  '_xbb._srqrmm': 'الرجاء إدخال كلمة مرور التأكيد',
  '_xbb._yqm': 'رمز الدعوة',
  '_xbb._sryqm': 'أدخل رمز الدعوة',
  '_xbb._yxyzm': 'رمز التحقق من البريد الإلكتروني',
  '_xbb._sryzm': 'أدخل رمز التحقق من البريد الإلكتروني',
  '_xbb._yydbty': 'لقد قرأت ووافقت',
  '_xbb._rzxy': 'اتفاقية التسوية',

  '_xbb._qgxty': 'يُرجى قراءة اتفاقية التسوية بعناية ووضع علامة في المربع للموافقة',
  '_xbb._sryqmb': 'الرجاء إدخال رمز الدعوة'
}
