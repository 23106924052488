<template>
  <div class="pc-sale-subject--right--35AGkQR">
    <div class="loop-container--loopWrap--3ZcoevZ" style="height: 100%">
      <div class="loop-container--loopBtn--3gWQkZj loop-container--prev--ICO9fI1 pc-sale-subject--prevBtn--3XcftAQ prev"></div>
      <div class="es--root--3PUGUTI loop-container--container--3Jbe2M5" style="width: 100%; height: 100%; display: flex; flex-direction: row">
        <swiper
          :modules="modules"
          :slidesPerView="1"
          :spaceBetween="0"
          :pagination="{
            el: '.banner-slick-dots',
            type: 'custom',
            renderCustom: onRenderCustom
          }"
          :navigation="{ nextEl: '.next', prevEl: '.prev' }"
          :loop="true"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false
          }"
          style="width: 100%; height: 100%"
        >
          <swiper-slide v-for="(item, i) in banner" :key="i" @click.stop="onUrl(item.url)">
            <div style="border-radius: 10px; overflow: hidden; height: 100%; width: 100%">
              <img :src="item.banner" class="imgBox link" style="height: 100%; width: 100%; display: inline-block" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="loop-container--loopBtn--3gWQkZj loop-container--next--3Piz8g5 pc-sale-subject--prevBtn--3XcftAQ next"></div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      total: 0,
      current: 0,
      //轮播图
      banner: []
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    onUrl(url) {
      window.open(url, '_blank')
    },
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    //获取轮播图
    getBanner() {
      let that = this
      this.$api.home.getBanner().then((res) => {
        that.banner = res.data
      })
    }
  }
}
</script>

<style></style>
