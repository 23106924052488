import request from '@/utils/request'

const api = {
  getAuth: 'store/getAuth',
  saveAuth: 'store/saveAuth',
  getProfit: 'store/getProfit',
  getOrder: 'store/getOrder',
  getProduct: 'store/getProduct',
  getView: 'store/getView',
  getSales: 'store/getSales',
  getIndex: 'store/getIndex',
  getChartData: 'store/getChartData',
  getWishGoods: 'store/getWishGoods',
  getStoreInfo: 'store/getStoreInfo',
  getStoreProducts: 'store/getStoreProducts',
  getStoreProductsReviews: 'store/getStoreProductsReviews',
  getHouseProducts: 'store/getHouseProducts',
  addNewHouseProduct: 'store/addNewHouseProduct',
  rmStoreProduct: 'store/rmStoreProduct',
  getStoreOrders: 'store/getStoreOrders',
  getOrderDetail: 'store/getOrderDetail',
  saveShop: 'store/saveShop',
  getHome: 'store/getHome',
  saveHome: 'store/saveHome',
  getAdvList: 'store/getAdvList',
  payAdv: 'store/payAdv',
  saveSetting: 'store/saveSetting',
  getShop: 'store/getShop',
  getStoreReceive: 'store/getStoreReceive',
  getStoreBankReceive: 'store/getStoreBankReceive',
  getStoreTransfer: 'store/getStoreTransfer',
  getStoreIncomeLog: 'store/getStoreIncomeLog',
  getStoreWalletlog: 'store/getStoreWalletlog',
  getShopWallet: 'store/getShopWallet',
  transfer: 'store/transfer',
  transferBankCard: 'store/transferBankCard',
  transferWallet: 'store/transferWallet',
  purchase: 'store/purchase',
  getAdvStoreGoods: 'store/getAdvStoreGoods',
  getMyAdvStoreGoods: 'store/getMyAdvStoreGoods',
  rechargeAdv: 'store/rechargeAdv',
  saveGoodsTop: 'store/saveGoodsTop',
  repayment: 'store/repayment',
  saveHomeTop: 'store/saveHomeTop',
  getShareList: 'store/getShareList',
  getStoreAddress: 'store/getStoreAddress',
  saveStoreAddress: 'store/saveStoreAddress',
  delStoreAddress: 'store/delStoreAddress',
  saveStoreBankAddress: 'store/saveStoreBankAddress',
  getStoreBankAddress: 'store/getStoreBankAddress',
  delStoreBankAddress: 'store/delStoreBankAddress',
  transferUserBankCard: 'store/transferUserBankCard',
  transferAward: 'store/transferAward',
  getStoreBankTransfer: 'store/getStoreBankTransfer',
  applyDeposit: 'store/applyDeposit'
}

export default {
  applyDeposit(parameter) {
    return request({
      url: api.applyDeposit,
      method: 'post',
      data: parameter
    })
  },
  transferUserBankCard(parameter) {
    return request({
      url: api.transferUserBankCard,
      method: 'post',
      data: parameter
    })
  },
  getStoreBankTransfer(parameter) {
    return request({
      url: api.getStoreBankTransfer,
      method: 'post',
      data: parameter
    })
  },
  getAuth(parameter) {
    return request({
      url: api.getAuth,
      method: 'post',
      data: parameter
    })
  },
  delStoreBankAddress(parameter) {
    return request({
      url: api.delStoreBankAddress,
      method: 'post',
      data: parameter
    })
  },
  getStoreBankAddress(parameter) {
    return request({
      url: api.getStoreBankAddress,
      method: 'post',
      data: parameter
    })
  },
  saveStoreBankAddress(parameter) {
    return request({
      url: api.saveStoreBankAddress,
      method: 'post',
      data: parameter
    })
  },
  transferAward(parameter) {
    return request({
      url: api.transferAward,
      method: 'post',
      data: parameter
    })
  },
  saveStoreAddress(parameter) {
    return request({
      url: api.saveStoreAddress,
      method: 'post',
      data: parameter
    })
  },
  delStoreAddress(parameter) {
    return request({
      url: api.delStoreAddress,
      method: 'post',
      data: parameter
    })
  },
  getStoreAddress(parameter) {
    return request({
      url: api.getStoreAddress,
      method: 'post',
      data: parameter
    })
  },
  saveAuth(parameter) {
    return request({
      url: api.saveAuth,
      method: 'post',
      data: parameter
    })
  },
  saveHomeTop(parameter) {
    return request({
      url: api.saveHomeTop,
      method: 'post',
      data: parameter
    })
  },
  getShareList(parameter) {
    return request({
      url: api.getShareList,
      method: 'post',
      data: parameter
    })
  },
  repayment(parameter) {
    return request({
      url: api.repayment,
      method: 'post',
      data: parameter
    })
  },
  getProfit(parameter) {
    return request({
      url: api.getProfit,
      method: 'post',
      data: parameter
    })
  },
  getOrder(parameter) {
    return request({
      url: api.getOrder,
      method: 'post',
      data: parameter
    })
  },
  getProduct(parameter) {
    return request({
      url: api.getProduct,
      method: 'post',
      data: parameter
    })
  },
  getSales(parameter) {
    return request({
      url: api.getSales,
      method: 'post',
      data: parameter
    })
  },
  saveGoodsTop(parameter) {
    return request({
      url: api.saveGoodsTop,
      method: 'post',
      data: parameter
    })
  },
  rechargeAdv(parameter) {
    return request({
      url: api.rechargeAdv,
      method: 'post',
      data: parameter
    })
  },
  getMyAdvStoreGoods(parameter) {
    return request({
      url: api.getMyAdvStoreGoods,
      method: 'post',
      data: parameter
    })
  },
  getAdvStoreGoods(parameter) {
    return request({
      url: api.getAdvStoreGoods,
      method: 'post',
      data: parameter
    })
  },
  getIndex(parameter) {
    return request({
      url: api.getIndex,
      method: 'post',
      data: parameter
    })
  },
  transferWallet(parameter) {
    return request({
      url: api.transferWallet,
      method: 'post',
      data: parameter
    })
  },
  getChartData(parameter) {
    return request({
      url: api.getChartData,
      method: 'post',
      data: parameter
    })
  },
  getHome(parameter) {
    return request({
      url: api.getHome,
      method: 'post',
      data: parameter
    })
  },
  saveHome(parameter) {
    return request({
      url: api.saveHome,
      method: 'post',
      data: parameter
    })
  },
  getView(parameter) {
    return request({
      url: api.getView,
      method: 'post',
      data: parameter
    })
  },
  getWishGoods(parameter) {
    return request({
      url: api.getWishGoods,
      method: 'post',
      data: parameter
    })
  },
  getStoreInfo(parameter) {
    return request({
      url: api.getStoreInfo,
      method: 'post',
      data: parameter
    })
  },
  getStoreProducts(parameter) {
    return request({
      url: api.getStoreProducts,
      method: 'post',
      data: parameter
    })
  },
  getAdvList(parameter) {
    return request({
      url: api.getAdvList,
      method: 'post',
      data: parameter
    })
  },
  getStoreProductsReviews(parameter) {
    return request({
      url: api.getStoreProductsReviews,
      method: 'post',
      data: parameter
    })
  },
  getHouseProducts(parameter) {
    return request({
      url: api.getHouseProducts,
      method: 'post',
      data: parameter
    })
  },

  addNewHouseProduct(parameter) {
    return request({
      url: api.addNewHouseProduct,
      method: 'post',
      data: parameter
    })
  },

  rmStoreProduct(parameter) {
    return request({
      url: api.rmStoreProduct,
      method: 'post',
      data: parameter
    })
  },
  getStoreOrders(parameter) {
    return request({
      url: api.getStoreOrders,
      method: 'post',
      data: parameter
    })
  },
  getOrderDetail(parameter) {
    return request({
      url: api.getOrderDetail,
      method: 'post',
      data: parameter
    })
  },

  saveShop(parameter) {
    return request({
      url: api.saveShop,
      method: 'post',
      data: parameter
    })
  },
  payAdv(parameter) {
    return request({
      url: api.payAdv,
      method: 'post',
      data: parameter
    })
  },
  saveSetting(parameter) {
    return request({
      url: api.saveSetting,
      method: 'post',
      data: parameter
    })
  },
  getShop(parameter) {
    return request({
      url: api.getShop,
      method: 'post',
      data: parameter
    })
  },
  getStoreReceive(parameter) {
    return request({
      url: api.getStoreReceive,
      method: 'post',
      data: parameter
    })
  },
  getStoreBankReceive(parameter) {
    return request({
      url: api.getStoreBankReceive,
      method: 'post',
      data: parameter
    })
  },
  getStoreTransfer(parameter) {
    return request({
      url: api.getStoreTransfer,
      method: 'post',
      data: parameter
    })
  },
  getStoreIncomeLog(parameter) {
    return request({
      url: api.getStoreIncomeLog,
      method: 'post',
      data: parameter
    })
  },
  getStoreWalletlog(parameter) {
    return request({
      url: api.getStoreWalletlog,
      method: 'post',
      data: parameter
    })
  },
  getShopWallet(parameter) {
    return request({
      url: api.getShopWallet,
      method: 'post',
      data: parameter
    })
  },
  transfer(parameter) {
    return request({
      url: api.transfer,
      method: 'post',
      data: parameter
    })
  },
  transferBankCard(parameter) {
    return request({
      url: api.transferBankCard,
      method: 'post',
      data: parameter
    })
  },
  purchase(parameter) {
    return request({
      url: api.purchase,
      method: 'post',
      data: parameter
    })
  }
}
