<template>
  <div id="_global_header_23_" style="height: 72px; display: block; position: sticky; top: 0px; z-index: 10">
    <div class="header-dir-ltr fixed-container--default--301iFsz" style="display: flex; align-items: center; justify-content: center">
      <div class="pc-header--fullContainer--2Fazi-Y" id="_full_container_header_23_" style="width: 1600px">
        <div class="pc-header--logo--1H9dOEh pc-header--withMargin--1h5riqJ" data-spm="logo">
          <a class="pc-header--logoImg--mDbiT4k" href="/">
            <span>ShopifyOutlets</span>
          </a>
          <div data-spm="allcategoriespc" class="category--category--bsXkyIx category-btn">
            <div class="" style="position: relative">
              <div class="Categoey--categoryIcon--1rIROec">
                <img class="Categoey--icon--1SqId_b" src="@/static/css5/imgs/36x36.png" />
              </div>

              <category-list-6></category-list-6>
            </div>
          </div>
        </div>
        <div class="pc-header--right--2cV7LB8">
          <div class="pc-header--rightWrap--HYqeULJ">
            <div class="pc-header--search--3hnHLKw">
              <div class="search--search--C_fO_MU">
                <input class="search--keyword--15P08Ji" @keyup.enter="onSearch()" v-model="searchVal" @input="onInput" type="text" placeholder="electric scooters" autocomplete="off" maxlength="50" id="search-words" value="" />
                <input class="search--submit--2VTbd-T" @click="onSearch()" type="button" />
                <div :class="['search--active--20ny0_q', searchShop.length > 0 ? 'active' : '']">
                  <div class="src--active--dSviezj src--autoPanel--52V_44o" data-spm="auto_suggest" data-spm-max-idx="11">
                    <ul class="src--auto--2EvPUd1">
                      <li v-for="(item, i) in searchShop" :key="i" @click="onShop(item)">
                        <a data-spm-anchor-id="a2g0o.home.auto_suggest.1">
                          <span class="src--autoItem--9yCOcGQ">
                            <!-- <span style="font-weight: 700">23</span>  -->
                            {{ item.name }}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="pc-header--items--tL_sfQ4" data-spm="header">
              <!-- 信贷业务 -->
              <head-loan></head-loan>

              <!-- 下载app -->
              <head-download></head-download>

              <!-- 语言框 -->
              <head-language></head-language>

              <!-- 专人客服 -->
              <head-customer></head-customer>

              <!-- 用户菜单 -->
              <head-account></head-account>

              <!-- 购物车 -->
              <head-cart></head-cart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadAccount from './HeadAccount.vue'
import HeadCart from './HeadCart.vue'
import HeadCustomer from './HeadCustomer.vue'
import HeadDownload from './HeadDownload.vue'
import HeadLanguage from './HeadLanguage.vue'
import HeadLoan from './HeadLoan.vue'
import CategoryList6 from '../category/CategoryList6.vue'
export default {
  components: { HeadLanguage, HeadCustomer, HeadAccount, HeadCart, HeadDownload, CategoryList6, HeadLoan },
  name: 'HeadVue',
  data() {
    return {
      searchVal: '',
      searchShop: [],
      searching: false
    }
  },
  created() {},
  methods: {
    onSearch() {
      this.searchShop = []
      this.$router.push({ name: 'category', query: { id: 0, level: 0, keyword: this.searchVal } })
    },
    onInput() {
      //通知上级
      console.log(this.searchVal)
      // 搜索符合的店铺名称
      this.searching = true

      let that = this
      this.$api.home.searchShop({ search: this.searchVal }).then((res) => {
        that.searching = false
        that.searchShop = res.data
      })
    },
    onShop(val) {
      this.$router.push({ name: 'storeHome', query: { id: val.id } })
    }
  }
}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style>
.category-btn:hover .category-show {
  display: block;
}

.search--active--20ny0_q li {
  list-style: none;
}

.search--active--20ny0_q {
  width: 888px !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in-out;
}

.search--active--20ny0_q.active {
  opacity: 1;
  visibility: visible;
}
</style>
