<template>
  <!-- 内容 -->
  <StoreHead>
    <template v-slot:banner="{ banner }">
      <img v-if="banner != ''" :src="banner + '?r=' + Math.floor(Math.random() * 1000)" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      <div v-else style="color: #fff; min-height: 80px">
        <img :src="require('../../static/temp/banner/b1.jpg')" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      </div>
    </template>
    <template #content>
      <div id="content">
        <div id="bd">
          <div id="bd-inner">
            <!-- 导航标签 -->
            <div class="layout grid-c">
              <div class="col-main">
                <div class="main-wrap" data-modules="main" data-width="h950">
                  <div class="module m-sop m-sop-crumb" data-spm="100004">
                    {{ $t('detail.store.home') }}
                    &gt; {{ $t('detail.sale.items') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="detail-page layout row-c2-s7" id="extend">
              <div class="col-main">
                <div class="main-wrap">
                  <div id="node-gallery" class="node gallery-mode">
                    <!-- 商品列表 -->
                    <div class="module m-o m-o-large-all-detail" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore" infinite-scroll-distance="5">
                      <div class="ui-box ui-box-wrap">
                        <div class="ui-box-body">
                          <ul class="items-list util-clearfix ibox-main">
                            <li class="item item-box" v-for="(item, i) in goodsData" :key="i">
                              <div class="img">
                                <div class="pic">
                                  <router-link class="pic-rind" :to="{ name: 'detail', query: { id: item.id } }">
                                    <img class="picCore lazy-load" :src="item.img" style="visibility: visible" />
                                  </router-link>
                                </div>
                              </div>
                              <div class="detail">
                                <h3>
                                  <router-link class="pic-rind" :to="{ name: 'detail', query: { id: item.id } }">{{ item.name }}</router-link>
                                </h3>
                                <div class="cost">
                                  <b class="notranslate">$ {{ item.price }}</b>
                                </div>
                                <div class="recent-order">{{ $t('detail.orders') }}({{ item.orders }})</div>
                              </div>
                            </li>
                          </ul>
                          <!-- 加载更多 -->
                          <div class="loadmore-div" v-show="loadmore">
                            <img class="_2O2Fm" src="@/static/img/loading.gif" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->

              <div class="col-sub">
                <div class="j-module" data-componentid="16028" data-title="商品推荐">
                  <div class="module m-sop m-sop-top-selling">
                    <div class="ui-box ui-box-wrap">
                      <div class="ui-box-title">
                        <h3>{{ $t('detail.recommend') }}</h3>
                      </div>
                      <div class="ui-box-body">
                        <ul class="items-list">
                          <li class="item util-clearfix" v-for="(item, i) in recommendList" :key="i">
                            <div class="img">
                              <router-link class="img" :to="{ name: 'detail', query: { id: item.id } }">
                                <img width="50" class="lazy-load" :src="item.img" style="visibility: visible" />
                              </router-link>
                            </div>
                            <div class="detail">
                              <div class="pro-name">
                                <router-link class="img" :to="{ name: 'detail', query: { id: item.id } }">{{ item.name }}</router-link>
                              </div>
                              <div class="price">
                                <span class="notranslate">$ {{ item.price }}</span>
                              </div>
                              <div class="order-no">{{ $t('detail.orders') }}({{ item.orders }})</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GoTop></GoTop>
    </template>
  </StoreHead>
</template>

<script>
import GoTop from '@/components/GoTop.vue'
import StoreHead from '@/components/StoreHead.vue'
export default {
  name: 'storeVue',
  components: { GoTop, StoreHead },
  data() {
    return {
      store_id: 0,

      goodsList: [],
      recommendList: [],

      //加载更多
      scrollDisabled: false,
      goodsData: [],
      page: 1,
      size: 8,
      loadmore: false
    }
  },
  created() {
    this.store_id = this.$route.query.id

    this.$api.storeInfo.getStoreGoodsRecommendList({ store_id: this.store_id }).then((res) => {
      this.recommendList = res.data
    })
  },
  methods: {
    getStoreGoodsList() {
      let that = this
      this.$api.storeInfo
        .getStoreGoodsList({
          id: this.store_id,
          page: this.page,
          size: this.size
        })
        .then((res) => {
          that.page++

          res.data.forEach(function (item) {
            that.goodsData.push(item)
          })
          that.loadmore = false
        })
    },
    // 触底加载
    loadMore() {
      if (this.loadmore) {
        return
      }

      // if (this.page > 2) {
      //   this.scrollDisabled = true;
      //   return;
      // }
      this.loadmore = true
      this.getStoreGoodsList()
    }
  }
}
</script>
<style scoped src="@/static/css/store-index.css"></style>
<style scoped src="@/static/css/newindex.css"></style>
<style scoped src="@/static/css/storesell.css"></style>
<style scoped>
#content {
  background-color: #fff;
}

/* #content {
    background: -webkit-gradient(linear,0 0,0 100%,from(#c4e8e8),color-stop(.33,#fff),color-stop(.67,#fff),to(#fff));
    background: -webkit-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: -moz-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: -o-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: linear-gradient(#c4e8e8,#fff,#fff,#fff)
} */

.official-content {
  background-color: inherit !important;
  background-image: inherit !important;
  background-repeat: inherit !important;
}

#hd .layout {
  background-color: #fff;
}

#bd {
  background-color: #fff;
}

.ui-box-wrap .ui-box-title {
  background-color: #b2eced;
}

.m-sop-shop-nav {
  background-color: #40b1b6;
}

.m-sop-discount {
  background-color: rgba(178, 232, 233, 0.3);
}

.m-sop-custom h3 {
  background-color: #b2eced;
}

.ae-m-sop-seller-coupon h2 {
  background-color: #b2eced;
}

.loadmore-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-box {
  box-shadow: 0px 0px 8px 0px #e3e3e3;
  border-radius: 5px;
}

.ibox-main {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
</style>
