<template>
  <div class="specification--wrap--lxVQ2tj" id="nav-specification" data-pl="product-specs">
    <div class="title--wrap--NWOaiSp">
      <div class="title--text--Otu0bLr"><h2 class="title--title--O6xcB1q">{{$t('_xbb._ggms')}}</h2></div>
    </div>
    <ul class="specification--list--GZuXzRX">
      <li class="specification--line--IXeRJI7" v-for="(v, k) in len" :key="k">
        <div class="specification--prop--Jh28bKu">
          <div class="specification--title--SfH3sA8">
            <span>{{ specs[k * 2].attrName }}</span>
          </div>
          <div class="specification--desc--Dxx6W0W" title="None">
            <span>{{ specs[k * 2].attrValue }}</span>
          </div>
        </div>
        <div class="specification--prop--Jh28bKu">
          <div class="specification--title--SfH3sA8">
            <span>{{ specs[k * 2 + 1].attrName }}</span>
          </div>
          <div class="specification--desc--Dxx6W0W" title="10 inch">
            <span>{{ specs[k * 2 + 1].attrValue }}</span>
          </div>
        </div>
      </li>
    </ul>
    <!-- <button type="button" class="comet-v2-btn comet-v2-btn-slim comet-v2-btn-large specification--btn--Y4pYc4b comet-v2-btn-important" style="min-width: 160px"><span>View more </span></button> -->
  </div>
</template>

<script>
export default {
  props: {
    specs: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      len: 0
    }
  },
  created() {
    let that = this
    this.$watch('specs', () => {
      that.len = parseInt(this.specs.length / 2)
    })
  }
}
</script>

<style></style>

<style scoped src="@/static/css5/index/2.css"></style>
<style scoped src="@/static/css5/detail/slider-kTGCl.css"></style>
<style scoped src="@/static/css5/detail/gallery-9g91h.css"></style>
<style scoped src="@/static/css5/detail/214.css"></style>
<style scoped src="@/static/css5/detail/navigation.css"></style>
