<template>
  <div id="root" class="mui-zebra-page" data-page-id="2437169" data-page-schema-version="a3fb796b-1426-4ee8-a834-4fd57b557606">
    <div class="rax-view-v2" style="position: static; inset: 0vw; background-color: rgb(255, 255, 255); padding-top: var(--safe-area-inset-top, 0)">
      <div class="rax-view-v2 rax-scrollview rax-scrollview-vertical" style="display: flex; flex-direction: column; flex: 1 1 0%; overflow: hidden">
        <div class="rax-view-v2 rax-scrollview-webcontainer" style="display: block">
          <div id="5705737420" mod-name="gmod-h5-rax-float-cart" data-spm="5705737420" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="72" data-appeared="true" data-has-appeared="true"></div>
          </div>
          <div id="1980217650" mod-name="gmod-h5-rax-global-cart" data-spm="1980217650" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="72" data-appeared="true" data-has-appeared="true"></div>
          </div>
          <div id="1088716420" mod-name="gmod-h5-rax-scenes-card" data-spm="1088716420" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="72" data-appeared="true" data-has-appeared="true"></div>
            <div class="rax-view-v2" style="margin: 0px auto; width: 100%">
              <div data-fsp-analyser-nofsp="true" class="rax-view-v2 h5-rax-scenes-card--wrapper--9Li1zoJ" style="margin-top: 0px; margin-bottom: 0px">
                <div class="rax-view-v2 h5-rax-scenes-card--imageBox--3NzNq0Z" style="height: 200px; max-width: 1920px">
                  <img id="ssrFormatBase64_1088716420" data-ssr-format-base64="true" src="../../static/css5/halloween/banner2.gif" loading="eager" style="height: 200px; max-width: 1920px; object-position: center top; object-fit: cover" />
                  <div class="rax-view-v2 h5-rax-scenes-card--titleWrapper--2SMOour">
                    <div class="rax-view-v2" style="margin-bottom: 4px"><span class="rax-text-v2 h5-rax-scenes-card--titleText--2ba-_5m rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25)"></span></div>
                  </div>
                  <div class="rax-view-v2" style="position: absolute; height: 200px; max-width: 1920px"><div class="rax-view-v2" style="margin: 0px auto; width: 1104px; height: 200px"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div id="9949572400" mod-name="gmod-h5-rax-item-mod" data-spm="9949572400" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="272" data-appeared="true" data-has-appeared="true"></div>
            <div class="rax-view-v2 aec-mod-container c" style="margin: 0px auto; position: relative; width: 1808px; direction: ltr">
              <div spm="a2g0o.tm1000014489.9949572400.title" class="rax-view-v2 AUC-container" style="position: relative; background-color: rgb(255, 255, 255); width: 1776px; padding-top: 16px; padding-bottom: 0vw; margin: 0px 16px; border-radius: 16px">
                <div class="rax-view-v2 AUC-pcTitleContainer" style="position: relative; display: flex; flex-direction: row; z-index: 1; justify-content: space-between; width: 100%; padding-right: 0px; padding-left: 0px">
                  <span class="rax-text-v2 AUC-pcMainTitle rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 32px; line-height: 38px; height: 38px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; width: 80%"> Recommended for you</span>
                </div>
              </div>
              <div target="_blank" href="" class="rax-view-v2 item-mod-wrapper" style="width: 1776px; margin: 16px auto 0px" data-appeared="true" data-has-appeared="true">
                <div class="rax-view-v2 lineOfProd" style="gap: 16px; display: flex; flex-direction: row; margin-bottom: 16px">
                  <div class="bksea" v-for="(item, i) in activity[7]" :key="i">
                    <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer sse">
                      <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 208px; height: 208px; border-radius: 8px">
                        <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 208px; height: 208px; object-fit: cover" />
                        <div class="AIC-MI-mask" style="background-color: rgba(0, 0, 0, 0.04); height: 208px; width: 208px" data-spm-anchor-id="a2g0o.tm1000014489.d0.i1.4c8221aekvE3nL"></div>

                        <!-- <img class="AIC-cartImg" src="https://ae01.alicdn.com/kf/S55347fa25aae4be0813b3cc3a7a6c70an/128x128.png_.webp" loading="lazy" style="width: 48px; height: 48px; position: absolute; bottom: 12px; right: 12px; object-fit: cover" /> -->
                      </div>
                      <div id="9949572400.info_container.3256804235629857" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                        <div class="rax-view-v2 AIC-RPI-container" style="height: 58px; margin-top: 4px; order: 5">
                          <img src="../../static/css5/halloween/1.4e0a8fad.png" style="width: 192px; height: 58px; object-fit: fill" />
                          <span class="b1ss rax-text-v2 rax-text-v2--overflow-hidden rax-text-v2--singleline" style="">${{ item.price }}</span>
                          <span class="b2ss rax-text-v2 AIC-RPI-saveText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="">Save </span>
                          <span class="b3ss rax-text-v2 AIC-RPI-saveText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="">{{ $common.cutPriceRate(item.price, item.cost_price) }}</span>
                          <span class="b4ss rax-text-v2 AIC-RPI-oriPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="">${{ item.cost_price }}</span>
                        </div>
                      </div>
                      <div class="AIC-quickView" style="height: 0vw">
                        <div class="AIC-quickViewBtnContainer" style="height: 36px; background-color: black; margin-top: 12px; border-radius: 24px; width: 142px">
                          <span class="AIC-quickViewBtnText" style="display: block; text-align: center; font-size: 12px; line-height: 36px; color: white; font-weight: 700; margin: 12px auto">See preview</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="8950740570" mod-name="gmod-h5-rax-atmosphere-item-mod" data-spm="8950740570" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="628" data-appeared="false" data-has-appeared="true" data-has-disappeared="true"></div>
            <div class="rax-view-v2 aec-mod-container c" style="margin: 0px auto; position: relative; width: 1808px; direction: ltr">
              <div spm="a2g0o.tm1000014489.8950740570.title" class="rax-view-v2 AUC-container" style="position: relative; background-color: rgb(255, 255, 255); width: 1776px; padding-top: 16px; padding-bottom: 0vw; margin: 0px 16px; border-radius: 16px">
                <div class="rax-view-v2 AUC-pcTitleContainer" style="position: relative; display: flex; flex-direction: row; z-index: 1; justify-content: space-between; width: 100%; padding-right: 0px; padding-left: 0px">
                  <span class="rax-text-v2 AUC-pcMainTitle rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 32px; line-height: 38px; height: 38px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; width: 80%">Scary costumes</span>
                </div>
              </div>
              <div target="_blank" href="" class="rax-view-v2 atmosphere_box" data-before-current-y="0" style="margin-top: 0px; margin-bottom: 0px">
                <div class="rax-view-v2 atmosphere_content_box" style="width: 1776px">
                  <div class="rax-view-v2 atmosphere_content" :style="'height: 544px; background-image: url(' + require('../../static/css5/halloween/h1.png') + '); border-radius: 16px; background-position: left center'">
                    <div class="rax-view-v2 atmosphere_title_box"></div>
                    <div class="rax-view-v2 atmosphere_list_box" style="width: 1328px">
                      <div horizontal="true" showshorizontalscrollindicator="false" scrolleventthrottle="50" showsverticalscrollindicator="true" class="rax-view-v2 rax-scrollview rax-scrollview-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex: 1 1 0%; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: -16px -16px -64px; padding: 16px 16px 64px 0px; min-width: 0vw; direction: ltr; border-radius: 16px; position: absolute; top: 24px; width: 1360px; overflow: scroll hidden">
                        <div class="rax-view-v2 rax-scrollview-content-container-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex-shrink: 0; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: 0vw; padding: 0vw; min-width: 0vw">
                          <div class="rax-view-v2 atmosphere_list_content_box_mod">
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 0px">
                              <!-- 第二栏 -->
                              <div class="rax-view-v2" v-for="(item, i) in activity[1]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="8950740570.info_container.1005007484083546" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">${{ item.price }}</span>
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(127, 127, 127); max-width: 176px; font-weight: 400; font-size: 12px; text-overflow: ellipsis; overflow: hidden; margin-left: 4px; vertical-align: bottom; text-decoration: line-through">${{ item.cost_price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 18px">
                              <div class="rax-view-v2" v-for="(item, i) in activity[2]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" id="1005007582140020" data-before-current-y="0" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="8950740570.info_container.1005007582140020" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">${{ item.price }}</span>
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(127, 127, 127); max-width: 176px; font-weight: 400; font-size: 12px; text-overflow: ellipsis; overflow: hidden; margin-left: 4px; vertical-align: bottom; text-decoration: line-through">${{ item.cost_price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="rax-view-v2 atmosphere_but" style="right: calc(50% - 880px); transform: rotate(180deg)">
                        <span class="rax-text-v2 aec-icon" style="color: rgb(255, 255, 255); font-size: 24px; width: 24px; height: 24px; line-height: 24px; text-align: center; font-family: aecDoneFont101"></span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="first-screen-end-row" mod-name="first-screen-end-row" data-spm="first-screen-end-row" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="0"></div>
          </div>

          <!-- 第三块 -->
          <div id="7060365280" mod-name="gmod-h5-rax-atmosphere-item-mod" data-spm="7060365280" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="0"></div>
            <div class="rax-view-v2 aec-mod-container c" style="margin: 0px auto; position: relative; width: 1808px; direction: ltr">
              <div spm="a2g0o.tm1000014489.7060365280.title" class="rax-view-v2 AUC-container" style="position: relative; background-color: rgb(255, 255, 255); width: 1776px; padding-top: 16px; padding-bottom: 0vw; margin: 0px 16px; border-radius: 16px">
                <div class="rax-view-v2 AUC-pcTitleContainer" style="position: relative; display: flex; flex-direction: row; z-index: 1; justify-content: space-between; width: 100%; padding-right: 0px; padding-left: 0px">
                  <span class="rax-text-v2 AUC-pcMainTitle rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 32px; line-height: 38px; height: 38px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; width: 80%">Accessories</span>
                </div>
              </div>
              <div target="_blank" href="" class="rax-view-v2 atmosphere_box" data-before-current-y="0" style="margin-top: 0px; margin-bottom: 0px">
                <div class="rax-view-v2 atmosphere_content_box" style="width: 1776px">
                  <div class="rax-view-v2 atmosphere_content" :style="'height: 544px; background-image: url(' + require('../../static/css5/halloween/h2.png') + '); border-radius: 16px; background-position: left center'">
                    <div class="rax-view-v2 atmosphere_title_box"></div>
                    <div class="rax-view-v2 atmosphere_list_box" style="width: 1328px">
                      <div horizontal="true" showshorizontalscrollindicator="false" scrolleventthrottle="50" showsverticalscrollindicator="true" class="rax-view-v2 rax-scrollview rax-scrollview-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex: 1 1 0%; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: -16px -16px -64px; padding: 16px 16px 64px 0px; min-width: 0vw; direction: ltr; border-radius: 16px; position: absolute; top: 24px; width: 1360px; overflow: scroll hidden">
                        <div class="rax-view-v2 rax-scrollview-content-container-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex-shrink: 0; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: 0vw; padding: 0vw; min-width: 0vw">
                          <div class="rax-view-v2 atmosphere_list_content_box_mod">
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 0px">
                              <div class="rax-view-v2" v-for="(item, i) in activity[3]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" id="1005007614064802" data-before-current-y="0" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="7060365280.info_container.1005007614064802" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">USD {{ item.price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 18px">
                              <div class="rax-view-v2" v-for="(item, i) in activity[4]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" id="1005007573533098" data-before-current-y="0" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="7060365280.info_container.1005007573533098" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">USD {{ item.price }}</span>
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(127, 127, 127); max-width: 176px; font-weight: 400; font-size: 12px; text-overflow: ellipsis; overflow: hidden; margin-left: 4px; vertical-align: bottom; text-decoration: line-through">USD {{ item.cost_price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="rax-view-v2 atmosphere_but" style="right: calc(50% - 880px); transform: rotate(180deg)"><span class="rax-text-v2 aec-icon" style="color: rgb(255, 255, 255); font-size: 24px; width: 24px; height: 24px; line-height: 24px; text-align: center; font-family: aecDoneFont101"></span></div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第4板块 -->
          <div id="3527342320" mod-name="gmod-h5-rax-atmosphere-item-mod" data-spm="3527342320" class="rax-view-v2 module-item mui-zebra-module aec-default">
            <div class="rax-view-v2" data-before-current-y="0"></div>
            <div class="rax-view-v2 aec-mod-container c" style="margin: 0px auto; position: relative; width: 1808px; direction: ltr">
              <div spm="a2g0o.tm1000014489.3527342320.title" class="rax-view-v2 AUC-container" style="position: relative; background-color: rgb(255, 255, 255); width: 1776px; padding-top: 16px; padding-bottom: 0vw; margin: 0px 16px; border-radius: 16px">
                <div class="rax-view-v2 AUC-pcTitleContainer" style="position: relative; display: flex; flex-direction: row; z-index: 1; justify-content: space-between; width: 100%; padding-right: 0px; padding-left: 0px">
                  <span class="rax-text-v2 AUC-pcMainTitle rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 32px; line-height: 38px; height: 38px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; width: 80%">Party supplies</span>
                </div>
              </div>
              <div target="_blank" href="" class="rax-view-v2 atmosphere_box" data-before-current-y="0" style="margin-top: 0px; margin-bottom: 0px">
                <div class="rax-view-v2 atmosphere_content_box" style="width: 1776px">
                  <div class="rax-view-v2 atmosphere_content" :style="'height: 544px; background-image: url(' + require('../../static/css5/halloween/h3.png') + '); border-radius: 16px; background-position: left center'">
                    <div class="rax-view-v2 atmosphere_title_box"></div>
                    <div class="rax-view-v2 atmosphere_list_box" style="width: 1328px">
                      <div horizontal="true" showshorizontalscrollindicator="false" scrolleventthrottle="50" showsverticalscrollindicator="true" class="rax-view-v2 rax-scrollview rax-scrollview-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex: 1 1 0%; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: -16px -16px -64px; padding: 16px 16px 64px 0px; min-width: 0vw; direction: ltr; border-radius: 16px; position: absolute; top: 24px; width: 1360px; overflow: scroll hidden">
                        <div class="rax-view-v2 rax-scrollview-content-container-horizontal" style="box-sizing: border-box; display: flex; flex-direction: row; flex-shrink: 0; align-content: flex-start; border-width: 0vw; border-style: solid; border-color: black; margin: 0vw; padding: 0vw; min-width: 0vw">
                          <div class="rax-view-v2 atmosphere_list_content_box_mod">
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 0px">
                              <div class="rax-view-v2" v-for="(item, i) in activity[5]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" id="1005007277563853" data-before-current-y="0" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="3527342320.info_container.1005007277563853" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">USD {{ item.price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                            <div class="rax-view-v2 atmosphere_list" style="direction: ltr; margin-top: 18px">
                              <div class="rax-view-v2" v-for="(item, i) in activity[6]" :key="i">
                                <div data-spm="d0" style="font-family: 'TT Norms Pro'; border-radius: 8px">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer" id="1005004497031296" data-before-current-y="0" style="text-decoration: none; color: inherit; box-sizing: border-box; display: flex; flex-direction: column; flex-shrink: 0; align-content: flex-start; border: 0px solid black; margin: 0vw 0px 0vw 16px; padding: 8px 8px 16px; direction: ltr; border-radius: 8px; background-color: rgb(255, 255, 255); overflow: hidden; width: 208px; position: relative">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 192px; height: 192px; border-radius: 6px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 192px; height: 192px; object-fit: cover" />
                                    </div>
                                    <div id="3527342320.info_container.1005004497031296" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px">
                                      <div class="rax-view-v2 AIC-PI-mobPriceContainer AIC-PI-priceContainer undefined" style="margin-top: 4px; order: 5; height: 24px; max-width: 176px">
                                        <span class="rax-text-v2 AIC-PI-MobPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="color: rgb(25, 25, 25); max-width: 176px; font-weight: 700; font-size: 22px; line-height: 24px; height: 24px; text-overflow: ellipsis; overflow: hidden">USD {{ item.price }}</span>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="rax-view-v2 atmosphere_but" style="right: calc(50% - 880px); transform: rotate(180deg)"><span class="rax-text-v2 aec-icon" style="color: rgb(255, 255, 255); font-size: 24px; width: 24px; height: 24px; line-height: 24px; text-align: center; font-family: aecDoneFont101"></span></div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第五款 -->
          <div id="7067545710" mod-name="gmod-h5-rax-item-list" data-spm="7067545710" class="rax-view-v2 module-item mui-zebra-module aec-default" style="padding-bottom: 20px">
            <div class="rax-view-v2" data-before-current-y="0"></div>
            <div class="rax-view-v2 aec-mod-container c" style="margin: 0px auto; width: 1808px; direction: ltr">
              <div spm="a2g0o.tm1000014489.7067545710.title" class="rax-view-v2 AUC-container" style="position: relative; background-color: rgb(255, 255, 255); width: 1776px; padding-top: 16px; padding-bottom: 0vw; margin: 0px 16px; border-radius: 16px">
                <div class="rax-view-v2 AUC-pcTitleContainer" style="position: relative; display: flex; flex-direction: row; z-index: 1; justify-content: space-between; width: 100%; padding-right: 0px; padding-left: 0px">
                  <span class="rax-text-v2 AUC-pcMainTitle rax-text-v2--overflow-hidden rax-text-v2--singleline" style="font-size: 32px; line-height: 38px; height: 38px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; width: 80%">More to love</span>
                </div>
              </div>
              <div class="rax-view-v2" style="margin-top: 16px">
                <div id="aec-list-container" class="rax-view-v2 aec-list-container-pc" style="width: 1808px; font-family: 'TT Norms Pro'">
                  <div class="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden" style="width: auto; margin: -17px -1px; padding: 17px 9px; z-index: 2">
                    <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px)">
                      <div class="swiper-slide swiper-slide-active" style="width: 1792px">
                        <div class="rax-view-v2 src-components-TabPanel-index-module__tabPanel--2Exxi" style="min-height: 100vh; height: auto">
                          <div class="rax-view-v2 src-components-TabPanel-index-module__virtualListWrapper--939Y6">
                            <div class="rax-view-v2">
                              <div class="rax-view-v2 src-components-VirtualList-index-module__virtualListPlaceholder--1KlsU" style="height: 0px"></div>
                              <div class="rax-view-v2" style="display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; flex-wrap: wrap">
                                <div class="sss1" v-for="(item, i) in goodsList" :key="i">
                                  <router-link :to="{ name: 'detail', query: { id: item.id } }" class="productContainer ssa">
                                    <div class="rax-view-v2 item-card-img-container AIC-MI-container undefined" style="width: 208px; height: 208px; border-radius: 8px">
                                      <img class="item-card-img undefined" :src="item.img" loading="lazy" style="width: 208px; height: 208px; object-fit: cover" />
                                      <div class="AIC-MI-mask" style="background-color: rgba(0, 0, 0, 0.04); height: 208px; width: 208px" data-spm-anchor-id="a2g0o.tm1000014489.d0.i2.4c8221aekvE3nL"></div>
                                    </div>
                                    <div id="waterfallfortopN.info_container.1005007461555833" class="rax-view-v2" style="display: flex; flex-direction: column; height: fit-content; margin-left: 8px; margin-right: 8px" data-spm-anchor-id="a2g0o.tm1000014489.d0.i4.4c8221aekvE3nL">
                                      <div class="rax-view-v2 AIC-RPI-container" style="height: 58px; margin-top: 4px; order: 5">
                                        <img src="../../static/css5/halloween/1.4e0a8fad.png" style="width: 192px; height: 58px; object-fit: fill" data-spm-anchor-id="a2g0o.tm1000014489.d0.i5.4c8221aekvE3nL" />
                                        <span class="rax-text-v2 rax-text-v2--overflow-hidden rax-text-v2--singleline" style="text-align: center; line-height: 19px; height: 19px; color: rgb(253, 56, 79); text-overflow: ellipsis; overflow: hidden; font-weight: 700; position: absolute; bottom: 7px; left: 0vw; max-width: 99px; font-size: 15px">USD {{ item.price }}</span>
                                        <span class="rax-text-v2 AIC-RPI-saveText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="width: 103px; font-size: 12px; line-height: 16px; height: 16px; top: 0px; right: 6px" data-spm-anchor-id="a2g0o.tm1000014489.d0.i3.4c8221aekvE3nL">Save </span>
                                        <span class="rax-text-v2 AIC-RPI-saveText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="width: 103px; font-size: 15px; line-height: 19px; height: 19px; font-weight: 700; top: 14px; right: 6px">{{ $common.cutPriceRate(item.price, item.cost_price) }}</span>
                                        <span class="rax-text-v2 AIC-RPI-oriPriceText rax-text-v2--overflow-hidden rax-text-v2--singleline" style="max-width: 82px; font-size: 12px; line-height: 16px; height: 16px; top: 5px; left: 0vw">USD {{ item.cost_price }}</span>
                                      </div>

                                      <div class="AIC-ATM-container undefined" style="margin-top: 8px; order: 1; max-width: 192px; max-height: 18px">
                                        <span class="AIC-ATM-multiLine" style="line-height: 18px; font-size: 14px; color: rgb(25, 25, 25); -webkit-line-clamp: 1; max-width: 192px">
                                          <span style="line-height: 18px; font-size: 14px; font-weight: 600">{{ item.name }}</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="AIC-quickView" style="height: 0vw">
                                      <div class="AIC-quickViewBtnContainer" style="height: 36px; background-color: black; margin-top: 12px; border-radius: 24px; width: 142px"><span class="AIC-quickViewBtnText" style="display: block; text-align: center; font-size: 12px; line-height: 36px; color: white; font-weight: 700; margin: 12px auto">See preview</span></div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="rax-view-v2 src-components-TabPanel-index-module__listPreloadDetection--JWPeN" style="bottom: 1000px; height: 2px" data-before-current-y="0"></div>
                          <div class="rax-view-v2 src-components-TabPanel-index-module__listEndDetection--1aA53" style="height: 48px" data-before-current-y="0"></div>
                          <!-- 加载更多 -->
                          <div v-show="loadmore" class="loadmore" style="padding-bottom: 20px">
                            <img class="_2O2Fm" src="@/static/img/loading.gif" />
                          </div>
                          <!-- <div class="rax-view-v2 src-components-LoadState-global-index-module__loading--2HaX6" style="padding: 16px 64px 64px; align-items: center" data-before-current-y="0"><img src="../../static/css5/halloween/120x40.gif" style="width: 24px; height: 24px" /></div> -->
                          <!-- <div class="rax-view-v2" style="width: 100%; height: 0px"></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="rax-view-v2"><div class="rax-view-v2" style="width: 100vw; height: 0vw"></div></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'halloweenVue',
  components: {},
  data() {
    return {
      activity: [],

      goodsList: [],
      page: 1
    }
  },
  created() {
    this.getHalloweenAct()

    this.page = 1
    this.getHalloween()
  },
  methods: {
    getHalloweenAct() {
      let that = this
      this.$api.activity.getHalloweenAct().then((res) => {
        that.activity = res.data
      })
    },
    getHalloween() {
      if (this.loadmore) {
        return
      }
      this.loadmore = true

      let that = this
      this.$api.activity.getHalloween({ page: this.page, size: 32 }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsList.push(item)
        })
        that.loadmore = false
      })
    }
  }
}
</script>

<style>
.bksea {
  font-family: 'TT Norms Pro';
  border-radius: 8px;
}

.bksea:hover {
  position: relative;
  background-color: white;
  height: 270px;
  width: 208px;
}

.bksea:hover .sse {
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0vw 0px;
  padding: 16px;
  direction: ltr;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  width: auto;
  position: absolute;
  inset: -17px -17px -64px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.bksea .sse {
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-content: flex-start;
  border: 0px solid black;
  /* margin: 0vw 0px 0vw 16px; */
  padding: 0px;
  direction: ltr;
  border-radius: 8px 8px 0vw 0vw;
  background-color: transparent;
  overflow: hidden;
  width: 208px;
  position: relative;
}

.b1ss {
  text-align: center;
  line-height: 22px;
  height: 22px;
  color: rgb(253, 56, 79);
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
  position: absolute;
  bottom: 7px;
  left: 0vw;
  max-width: 103px;
  font-size: 18px !important;
}

.b2ss {
  width: 86px;
  font-size: 12px !important;
  line-height: 16px;
  height: 16px;
  top: 0px;
  right: 9px;
}

.b3ss {
  width: 86px;
  font-size: 18px !important;
  line-height: 22px;
  height: 22px;
  font-weight: 700;
  top: 14px;
  right: 9px;
}

.b4ss {
  max-width: 100px;
  font-size: 12px !important;
  line-height: 16px;
  height: 16px;
  top: 5px;
  left: 0vw;
}

.sss1 {
  font-family: 'TT Norms Pro';
  border-radius: 8px;
}

.sss1 .ssa {
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-content: flex-start;
  border: 0px solid black;
  margin: 12px 8px 0vw;
  padding: 0px;
  direction: ltr;
  border-radius: 8px 8px 0vw 0vw;
  background-color: transparent;
  overflow: hidden;
  width: 208px;
  position: relative;
}

.sss1:hover {
  position: relative;
  background-color: white;
  height: 308px;
  width: 224px;
}

.sss1:hover .ssa {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  background-color: white;
  width: auto;
  position: absolute;
  inset: -17px -17px -64px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.sss2 {
  font-family: 'TT Norms Pro';
  border-radius: 8px;
  position: relative;
  background-color: white;
  height: 308px;
  width: 224px;
}

.sssa2 {
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 12px 8px 0vw;
  padding: 16px;
  direction: ltr;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  width: auto;
  position: absolute;
  inset: -17px -17px -64px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}
</style>
<style scoped src="../../static/css5/halloween/1.css"></style>
