<template>
  <div class="group-floor" style="padding-top: 32px; padding-bottom: 0" data-component-id="7588276" data-floor-name="us-two-backup">
    <div class="row-container--root--33oSY1n">
      <div class="us-two--box--bdxEdPp">
        <!-- 左侧 -->
        <div data-spm="3fornn" class="us-two--left--95zrwBu" style="background-color: #fdf9d4">
          <a class="nn--titleW--3w9nOYe" href="javascript:" target="_blank">
            <div class="us-title--hTitle--3ytY5rK">
              <span class="us-title--mainTitle--Zg3IkcB">{{ $t('_xbb._tjsp1') }}</span>
              <div class="us-title--hSubBox--2RCT72v">
                <div class="us-title--iconTitleWrapper--3-yDZd7">
                  <img class="us-title--subIcon--bHuDPoE" src="@/static/css5/imgs/154x64.png" alt="" style="height: 27px" />
                  <span class="us-title--hprefix--3OBs5Ky">{{ $t('_xbb._tjsp2') }}</span>
                </div>
              </div>
            </div>
            <div class="magic-pocket--arrowBox--2M5VtdA us-title--minArraw--1NlsjZc" style="background: #ffeb71">
              <img src="@/static/css5/svg/S3347f8236df444fbb7ab8b25afd3d992J.svg" alt="icon" />
            </div>
          </a>
          <!-- 商品 -->
          <div class="loop-container--loopWrap--3ZcoevZ nn--loop--irNc14t">
            <div class="loop-container--loopBtn--3gWQkZj loop-container--prev--ICO9fI1 ps-prev"></div>
            <div class="es--root--3PUGUTI loop-container--container--3Jbe2M5" style="display: flex; flex-direction: row">
              <!--    :autoplay="{
                        delay: 3000,
                        disableOnInteraction: false
                      }" -->
              <swiper :modules="modules1" :slidesPerGroup="4" :slidesPerView="4" :spaceBetween="10" :navigation="{ nextEl: '.ps-next', prevEl: '.ps-prev' }" :loop="true" style="width: 100%; height: 100%">
                <swiper-slide v-for="(item, i) in plusSale" :key="i">
                  <div class="src--wrap--12fYVOX nn--card--2ruyzAH src--small--u_SOtwA">
                    <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                      <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f src--image--4g7R_DX" title="">
                        <img class="rc-modules--image--juUYhtJ" :src="item.goods && item.goods.img" alt="" />
                      </div>
                      <section class="src--btm--1TKWuOm">
                        <div class="src--title--1H6RJWf">{{ item.goods && item.goods.name }}</div>
                        <div class="src--mid--3y36YZQ">
                          <span class="src--sold--z9CFUg3">{{ item.orders }} sold</span>
                          <span class="src--reviewStar--Bkcw5ub">
                            <span class="src--icon--fV4GdfQ"></span>
                            {{ item.star }}
                          </span>
                        </div>
                        <div class="src--priceW--1wqv71o">
                          <span class="src--priceArraw--2I9jMsE"></span>
                          <span class="src--priceSMin--2U5NyJu rc-modules--price--1NNLjth" title="">
                            <span>$</span>
                            <span>{{ item.goods?.price }}</span>
                          </span>
                          <span class="src--priceSOrigin--2ZClpti rc-modules--price--1NNLjth" title="">
                            <span>$</span>
                            <span>{{ item.goods?.cost_price }}</span>
                          </span>
                        </div>
                      </section>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="loop-container--loopBtn--3gWQkZj loop-container--next--3Piz8g5 ps-next"></div>
          </div>
        </div>

        <!-- 右侧 -->
        <div data-spm="superdeal" class="us-two--right--2zqMUCE" style="background-color: #ffeadf">
          <a class="super-deals--titleW--PmzLBlm" href="javascript:" target="_blank">
            <div class="us-title--hTitle--3ytY5rK">
              <img src="@/static/css5/imgs/S3992461214d048a3ba0701bf7c7746fbO.png" alt="" class="us-title--img--2odFav_" />
              <div class="us-title--hSubBox--2RCT72v">
                <div class="us-title--iconTitleWrapper--3-yDZd7"><span class="us-title--hprefix--3OBs5Ky">{{$t('_xbb._tjsp3')}}</span></div>
              </div>
            </div>
            <div class="magic-pocket--arrowBox--2M5VtdA us-title--minArraw--1NlsjZc" style="background: #ff1f0026">
              <img src="@/static/css5/svg/S3347f8236df444fbb7ab8b25afd3d992J.svg" alt="icon" />
            </div>
          </a>
          <!-- 商品 -->
          <div class="loop-container--loopWrap--3ZcoevZ super-deals--loop--3c-mmsI">
            <div class="loop-container--loopBtn--3gWQkZj loop-container--prev--ICO9fI1 ps2-prev"></div>
            <div class="es--root--3PUGUTI loop-container--container--3Jbe2M5" style="display: flex; flex-direction: row">
              <!-- :autoplay="{
                        delay: 30000,
                        disableOnInteraction: false
                      }" -->
              <swiper :modules="modules1" :slidesPerGroup="4" :slidesPerView="4" :spaceBetween="0" :loopFillGroupWithBlank="true" :navigation="{ nextEl: '.ps2-next', prevEl: '.ps2-prev' }" :loop="true" style="width: 100%; height: 100%">
                <swiper-slide v-for="(item, i) in superDeals" :key="i">
                  <div class="src--wrap--1mE1PXf super-deals--card--1UV-te8 src--small--3RBAtiR">
                    <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                      <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f src--image--oJ3wiD3" title="">
                        <img class="rc-modules--image--juUYhtJ" :src="item.goods.img" :alt="item.goods.name" />
                      </div>
                      <section class="src--btm--UwzIpEn">
                        <div class="src--title--1IrEcKz">{{ item.goods && item.goods.name }}</div>
                        <div class="src--discount--b1gx8Os">-{{ (item.goods && parseInt((1 - item.goods.price / item.goods.cost_price) * 100)) || 10 }}%</div>
                        <div class="src--priceW--1N8HoPf">
                          <span class="src--priceMin--2ycpb_0 rc-modules--price--1NNLjth" title="">
                            <span>$</span><span>{{ item.goods?.price }}</span>
                          </span>
                          <span class="src--priceSOrigin--3HW7IBw rc-modules--price--1NNLjth" title="">
                            <span>$</span><span>{{ item.goods?.cost_price }}</span>
                          </span>
                        </div>
                      </section>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="loop-container--loopBtn--3gWQkZj loop-container--next--3Piz8g5 ps2-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      modules1: [Navigation, Pagination],
      //活动板块
      superDeals: {},
      plusSale: {}
    }
  },
  created() {
    this.getTopGoods()
  },
  methods: {
    getTopGoods() {
      //获取super
      this.$api.home.getTopGoods({ type: 2 }).then((res) => {
        this.superDeals = res.data
      })

      //获取plus
      this.$api.home.getTopGoods({ type: 3 }).then((res) => {
        this.plusSale = res.data
      })
    }
  }
}
</script>

<style></style>
