<template>
  <a-modal v-model:visible="RechargeVisible" :style="{ width: '800px' }" @ok="hide()" :footer="null" :bodyStyle="{ padding: '30px' }">
    <!-- 充值金额 -->
    <div :style="{ padding: '40px 20px 0px 20px' }" v-if="step == STEP.SelectCurrency">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <!-- type -->
        <a-form-item :label="$t('_zddy._bdhb')">
          <a-select ref="select" v-model:value="hbIndex" style="width: 100%" @change="onHbChange" :placeholder="$t('_zddy._xzbdhb')">
            <a-select-option :value="i" v-for="(item, i) in hbList" :key="i">
              <div class="hb-sert">
                <div class="hb-91dfs">
                  <img :src="item.src" />
                </div>
                <div class="hb-tit">
                  {{ item.name }}
                </div>
              </div>
            </a-select-option>
          </a-select>
        </a-form-item>

        <!-- amount -->
        <a-form-item :label="$t('bank.amount')" name="amount" :rules="[{ required: true, message: 'Please input your username!' }]">
          <a-input v-model:value="params.amount" @input="onAmount" :suffix="currencyHb && currencyHb.name" type="number" :step="0.00000001" :placeholder="$t('bank.amount.desc')" />
        </a-form-item>

        <!-- amount -->
        <div class="ant-row ant-form-item" style="row-gap: 0px">
          <div class="ant-col ant-col-6 ant-form-item-label">
            <div for="basic_amount" class="ant-form-item-required sfejc">≈</div>
          </div>
          <div class="ant-col ant-col-12 ant-form-item-control sfejc">{{ params.show_usd }} USD</div>
        </div>

        <!-- 按钮 -->
        <div class="ant-row ant-form-item" style="margin-bottom: 0">
          <div class="ant-col ant-col-24 ant-form-item-label">
            <div for="basic_amount" class="ant-form-item-required" style="display: flex; align-items: center; justify-content: center; gap: 10px">
              <a-button type="primary" @click="onStep(STEP.SelectChannel)" v-if="step == STEP.SelectCurrency">{{ $t('bank.ok.text') }}</a-button>
              <a-button key="back" @click="onStep(STEP.SelectCurrency)" v-if="step == STEP.SelectChannel">{{ $t('bank.ok.prev') }}</a-button>
            </div>
          </div>
        </div>
      </a-form>
    </div>

    <!-- 选择充值渠道 -->
    <div :style="{ padding: '0 20px' }" v-if="step == STEP.SelectChannel">
      <div class="hb-sdfqe">
        <div class="hb-sdijfi">USD {{ params.show_usd }}</div>
        <div class="hb-tit1asd">{{ $t('_zddy._zje') }}</div>
      </div>
      <div class="hb0zdfs">{{ $t('_zddy._xzzffs') }}</div>
      <div class="hb-zzdsf" style="margin-bottom: 24px">
        <template v-for="(item, i) in channelList" :key="i">
          <div class="hb-zz1" @click="onStepOptions(STEP.HideOptions, item.type)" v-if="(item.key == 'bank' && isBank == 2) || item.key !== 'bank'">{{ item.title }}</div>
        </template>
      </div>
    </div>

    <!-- 加密货币 -->
    <CryptoCurrencyBox ref="crypto" :ShowParams="params" @onStep="onStep" v-if="channel == CHANNEL.CryptoCurrency"></CryptoCurrencyBox>

    <!-- 银行转账 -->
    <BankCardBox ref="bank" :ShowParams="params" :currencyHb="currencyHb" @onStep="onStep" @BankCardSuccess="BankCardSuccess" v-if="channel == CHANNEL.BankTransfer"> </BankCardBox>

    <!-- <component :is="componentId"></component> -->
  </a-modal>
</template>

<script>
import CryptoCurrencyBox from './CryptoCurrencyBox.vue'
import BankCardBox from './BankCardBox.vue'

const STEP = {
  HideOptions: 0,
  SelectCurrency: 1,
  SelectChannel: 2
}

const CHANNEL = {
  HideOptions: 0,
  CryptoCurrency: 1,
  BankTransfer: 2
}

export default {
  name: 'RechargeVue',
  components: {
    CryptoCurrencyBox,
    BankCardBox
  },
  data() {
    return {
      // componentId: 'CryptoCurrencyBox',

      //步骤
      STEP,
      step: STEP.SelectCurrency,

      //渠道
      CHANNEL,
      channelList: [
        {
          type: CHANNEL.CryptoCurrency,
          key: 'crypto',
          title: this.$t('_zddy._jmhb')
        },
        {
          type: CHANNEL.BankTransfer,
          key: 'bank',
          title: this.$t('_yhqd._yhzz')
        }
      ],
      channel: CHANNEL.HideOptions,
      isBank: 1,

      //选择货币
      hbIndex: 0,
      hbList: [
        {
          id: 1,
          name: 'USD',
          value: 1,
          src: require('../../static/hb/USD.png')
        },
        {
          id: 2,
          name: 'KRW',
          value: 1366.57,
          src: require('../../static/hb/KRW.png')
        },
        {
          id: 3,
          name: 'JPY',
          value: 157.13,
          src: require('../../static/hb/JPY.png')
        },
        // {
        //   id: 4,
        //   name: 'DEE',//德国
        //   value: 7.13,
        //   src: require('../../static/hb/DEE.png')
        // },
        {
          id: 5,
          name: 'SAR',
          value: 3.7506,
          src: require('../../static/hb/SAR.png')
        },
        {
          id: 6,
          name: 'RUB',
          value: 89.135,
          src: require('../../static/hb/RUB.png')
        },
        {
          id: 7,
          name: 'RON',
          value: 4.5917,
          src: require('../../static/hb/RON.png')
        },
        // {
        //   id: 8,
        //   name: 'FRF',法国
        //   value: 7.13,
        //   src: require('../../static/hb/FRF.png')
        // },
        {
          id: 9,
          name: 'TRY',
          value: 32.244,
          src: require('../../static/hb/TRY.png')
        },
        {
          id: 10,
          name: 'EUR',
          value: 0.9226,
          src: require('../../static/hb/EUR.png')
        },
        {
          id: 11,
          name: 'GBP',
          value: 0.7838,
          src: require('../../static/hb/GBP.png')
        },
        // {
        //   id: 10,
        //   name: 'FIM',//EUR 芬兰
        //   value: 7.13,
        //   src: require('../../static/hb/FIM.png')
        // },
        // {
        //   id: 11,
        //   name: 'EUR',//EUR
        //   value: 7.13,
        //   src: require('../../static/hb/EUR.png')
        // }
        {
          id: 12,
          name: 'DKK',
          value: 6.8845,
          src: require('../../static/hb/DKK.png')
        },
        {
          id: 13,
          name: 'ANG',
          value: 1.8023,
          src: require('../../static/hb/ANG.png')
        },
        {
          id: 14,
          name: 'VND',
          value: 25455,
          src: require('../../static/hb/VND.png')
        },
        // {
        //   id: 15,
        //   name: 'EUR',
        //   value: 7.13,
        //   src: require('../../static/hb/EUR.png')
        // },
        {
          id: 16,
          name: 'IDR',
          value: 16160,
          src: require('../../static/hb/IDR.png')
        },
        {
          id: 17,
          name: 'HKD',
          value: 7.8129,
          src: require('../../static/hb/HKD.png')
        }
      ],
      currencyHb: {},
      show_usd: 0,

      //存币
      RechargeVisible: false,

      // 提交信息
      params: {
        type: 1,
        amount: '',
        show_usd: 0
      }
    }
  },
  created() {},
  methods: {
    BankCardSuccess() {
      this.hide()
    },
    onStep(step) {
      //切换步骤
      if (step == STEP.SelectChannel) {
        if (this.params.amount <= 0 || this.params.amount == undefined) {
          this.$message.error({ content: this.$t('_yhqd._qsrje') })
          return
        }
      }

      this.step = step
      if (step > 0) {
        if (this.channel > 0) {
          this.channel = CHANNEL.HideOptions
        }
      }
    },
    onStepOptions(step, channel) {
      this.step = step
      this.channel = channel
    },
    onAmount() {
      console.log(this.params.amount)

      if (this.params.amount <= 0) {
        this.params.show_usd = 0
        return
      }

      //换算
      let usd = parseFloat((parseFloat(this.params.amount) / parseFloat(this.currencyHb.value)).toFixed(2))
      if (usd) {
        this.params.show_usd = usd
      } else {
        this.params.show_usd = 0
      }
    },
    onHbChange() {
      this.currencyHb = this.hbList[this.hbIndex]
      this.onAmount()
      console.log(this.hbIndex, this.currencyHb)
    },
    // 充值类型切换
    onType() {
      console.log(this.params.type)
    },
    onUpload(img) {
      this.imageUrl = img
      this.img = img
    },
    /**
     * 存币
     */
    show(isBank) {
      this.isBank = isBank

      this.step = STEP.SelectCurrency
      this.channel = CHANNEL.HideOptions

      this.hbIndex = 0
      this.currencyHb = this.hbList[0]

      this.imageUrl = ''
      this.img = ''
      this.params = {
        type: 1,
        number: '',
        show_usd: 0,
        img: ''
      }

      this.RechargeVisible = true
    },
    hide() {
      this.RechargeVisible = false
    },
    onNext() {
      this.step = 2
    },
    onPrev() {
      this.step = 1
    }
  }
}
</script>

<style scoped>
.aabb {
  background-color: #000;
}
.ant-modal-content {
  position: relative;
  background-color: #000 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  border-radius: 10px !important;
  padding: 40px !important;
}

.hb-sert {
  display: flex;
  align-items: center;
  gap: 10px;
}
.hb-91dfs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
}
.hb-tit {
  font-weight: 700;
}
.hb-sdfqe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}
.hb-sdijfi {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
}
.hb-tit1asd {
  font-size: 16px;
  font-weight: 700;
  color: var(--m-color1);
}

.hb-zzdsf {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.hb-zz1 {
  padding: 5px 20px;
  border: 1px solid var(--m-color1);
  border-radius: 10px;
  cursor: pointer;
  background-color: #5fd5b629;
}

.hb-zz1:hover {
  border: 1px solid var(--m-color2);
}

.hb0zdfs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 700;
}

.sfejc {
  font-weight: 700;
  font-size: 17px !important;
}

.safag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
