<template>
  <div class="Categoey--categoryRight--2uIfSd3" style="max-width: 1360px">
    <div class="Categoey--cateContainer--1Bg1frj">
      <div class="Categoey--cateContent--3cv0qyy">
        <!-- 1层 -->
        <div style="padding-left: 24px; width: 160px" v-for="(vo, ii) in category" :key="ii">
          <!-- 2层 -->
          <span class="Categoey--cateItem--2c4rOz0" v-for="(voo, iii) in vo" :key="iii">
            <div class="Categoey--cateItemLv2Title--1tw0jft">{{ voo.name }}</div>
            <!-- 3层 -->
            <router-link :to="{ name: 'category', query: { level: vooo.level, id: vooo.id } }" style="color: rgb(117, 117, 117)" v-for="(vooo, iiii) in voo.category" :key="iiii">
              <div class="Categoey--cateItemLv3Title--1mjlI-5" style="font-size: 14px; line-height: 18px">{{ vooo.name }}</div>
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="Categoey--cateWithLink--1iXrA5K">
      <div class="Categoey--cateWithLinkTitle--2AZ7oDP">More Ways to Shop</div>
      <div class="Categoey--cateWithLinkContent--34Zb9z3">
        <a href="https://www.aliexpress.com/p/calp-plus/index.html?itemTabId=0&amp;categoryNeedP4p=false&amp;osf=category_navigate_newTab2&amp;searchBizScene=openSearch&amp;style=list&amp;nasa_tag_ids=1002186175&amp;categoryTab=us_jewelry%252C_watches_%2526_accessories&amp;isAnchor=y"><div class="Categoey--linkTitle--3vOOhW3">Trending Items</div></a><a href="https://www.aliexpress.com/p/calp-plus/index.html?itemTabId=1&amp;categoryNeedP4p=false&amp;osf=category_navigate_newTab2&amp;searchBizScene=openSearch&amp;style=list&amp;nasa_tag_ids=1002186185&amp;categoryTab=us_jewelry%252C_watches_%2526_accessories&amp;isAnchor=y"><div class="Categoey--linkTitle--3vOOhW3">Free shipping</div></a
        ><a href="https://www.aliexpress.com/p/calp-plus/index.html?itemTabId=2&amp;categoryNeedP4p=false&amp;osf=category_navigate_newTab2&amp;searchBizScene=openSearch&amp;style=list&amp;nasa_tag_ids=1002187228&amp;categoryTab=us_jewelry%252C_watches_%2526_accessories&amp;isAnchor=y"><div class="Categoey--linkTitle--3vOOhW3">5-Star Rated</div></a>
      </div>
    </div> -->
    <div class="sddkek-box">
      <div v-for="(vvo, ii) in top" :key="ii" class="sddkek">
        <router-link :to="{ name: 'category', query: { id: vvo.id, level: vvo.level } }">
          <img :src="vvo.url" :alt="vvo.name" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: [],
      top: []
    }
  },
  methods: {
    setCurrent(item) {
      this.category = item.category
      this.top = item.top
    }
  }
}
</script>
<style scoped src="@/static/css5/index.css"></style>
<style>
.sddkek-box {
  display: flex;
  gap: 10px;
  padding: 0 20px;
}
.sddkek {
  width: 15%;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
</style>
