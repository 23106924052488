<template>
  <div class="my-account--menuItem--1GDZChA down-box">
    <span class="comet-icon comet-icon-myaccount my-account--accountIcon--ECZEGeo">
      <svg style="fill: var(--nav-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
        <path d="M512 126.357333a189.866667 189.866667 0 1 0 189.866667 189.866667 189.866667 189.866667 0 0 0-189.866667-189.866667z m-125.866667 189.866667a125.866667 125.866667 0 1 1 251.733334 0 125.866667 125.866667 0 0 1-251.733334 0zM512 650.666667c138.026667 0 236.074667 72.448 273.152 192H238.848c37.077333-119.552 135.146667-192 273.152-192z m0-64c-171.541333 0-298.325333 96.981333-339.349333 254.805333-9.002667 34.666667 18.346667 65.194667 51.093333 65.194667h576.512c32.768 0 60.096-30.506667 51.093333-65.194667C810.325333 683.648 683.52 586.666667 512 586.666667z"></path>
      </svg>
    </span>
    <div class="my-account--text--2Yt_prE">
      <span v-if="!isLogin" class="my-account--small--3ni1QHm">Welcome</span>
      <span v-else class="my-account--small--3ni1QHm my-account--limitName--1zJ2KsG my-account--one-line--13EsxPb">Hi, {{ sp_info.nickname }}</span>
      <b>
        <span v-if="!isLogin" style="color: var(--nav-color1)">Sign in / Register </span>
        <span v-else style="color: var(--nav-color1)">Account </span>
        <span class="comet-icon comet-icon-chevrondown32 my-account--centerIcon--2yqLRkj base--chevronIcon--25sHdop">
          <svg style="fill: var(--nav-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
            <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
          </svg>
        </span>
      </b>
    </div>
    <!-- <span class="comet-icon comet-icon-chevrondown32 base--responseIcon--3et2x1Z">
      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
        <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
      </svg>
    </span> -->

    <!-- 下拉框 -->
    <div class="comet-v2-popover comet-v2-popover-show-arrow show-box" data-popper-placement="bottom-start" style="position: absolute; inset: 45px 0 auto auto; margin: 0px">
      <div class="comet-v2-popover-arrow" data-popper-arrow="true" style="position: absolute; right: 81px"></div>

      <div class="comet-v2-popover-body" style="border-radius: 24px">
        <!-- 已登录 -->
        <div class="my-account--userInfo--8cACzqQ" v-if="isLogin">
          <div class="my-account--infoWrap--1u8t6Uy">
            <div class="my-account--avator--328j6T1">
              <img :src="sp_info.avatar" alt="" @load="avatarSuccess" @error="avatarError" v-show="isAvatar" />
              <img src="@/static/css5/head/avatar.png" alt="" v-show="!isAvatar" />
            </div>
            <div class="my-account--textBox--2CoO_Q7">
              <div class="my-account--textCenter--2K0hniX">
                <div>
                  <span>Welcome,</span>
                  <span class="my-account--name--CwUoFMi my-account--one-line--13EsxPb">{{ sp_info.nickname }}</span>
                </div>
              </div>
              <a class="my-account--out--3b--Hjv" @click="onSignout()"> {{ $t('signup.sign.out') }}</a>
            </div>
          </div>
          <span class="my-account--line--QONK0VF"></span>
        </div>
        <!-- 未登录 -->
        <div class="my-account--userInfo--8cACzqQ" v-else>
          <button class="my-account--signin--RiPQVPB" @click="onSignin()">{{ $t('_zddy._dlyh') }}</button>
          <p @click="onSignup()">{{ $t('_zddy._zcyh') }}</p>
          <span class="my-account--line--QONK0VF"></span>
        </div>
        <div class="comet-v2-popover-content tmenu" style="padding: 20px 0">
          <div class="menu-box">
            <!-- 用户 -->
            <template v-if="sp_info.type == 1 || sp_info.type == 0">
              <!-- 概述 -->
              <router-link :to="{ name: 'overview' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-orders my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i5.77f776dbPqhQNm">
                          <path d="M189.866667 266.666667a76.8 76.8 0 0 1 76.8-76.8h70.250666a34.133333 34.133333 0 1 1 0 68.266666H266.666667a8.533333 8.533333 0 0 0-8.533334 8.533334v618.666666c0 4.693333 3.818667 8.533333 8.533334 8.533334h490.666666a8.533333 8.533333 0 0 0 8.533334-8.533334v-618.666666a8.533333 8.533333 0 0 0-8.533334-8.533334h-70.250666a34.133333 34.133333 0 1 1 0-68.266666H757.333333a76.8 76.8 0 0 1 76.8 76.8v618.666666a76.8 76.8 0 0 1-76.8 76.8h-490.666666a76.8 76.8 0 0 1-76.8-76.8v-618.666666z" data-spm-anchor-id="a2g0o.home.headerAcount.i4.77f776dbPqhQNm"></path>
                          <path d="M328.533333 202.666667A76.8 76.8 0 0 1 405.333333 125.866667h213.333334a76.8 76.8 0 0 1 76.8 76.8v42.666666a76.8 76.8 0 0 1-76.8 76.8H405.333333a76.8 76.8 0 0 1-76.8-76.8v-42.666666z m76.8-8.533334a8.533333 8.533333 0 0 0-8.533333 8.533334v42.666666c0 4.693333 3.818667 8.533333 8.533333 8.533334h213.333334a8.533333 8.533333 0 0 0 8.533333-8.533334v-42.666666a8.533333 8.533333 0 0 0-8.533333-8.533334H405.333333z"></path>
                          <path d="M362.666667 629.333333a32 32 0 0 1 32-32H554.666667v18.282667C554.666667 640.874667 534.186667 661.333333 508.949333 661.333333H394.666667a32 32 0 0 1-32-32z"></path>
                          <path d="M352 469.333333m32 0l256 0q32 0 32 32l0 0q0 32-32 32l-256 0q-32 0-32-32l0 0q0-32 32-32Z" data-spm-anchor-id="a2g0o.home.headerAcount.i6.77f776dbPqhQNm"></path>
                        </svg>
                      </span>
                      {{ $t('member.overview') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 订单 -->
              <router-link :to="{ name: 'orderAll' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.orders') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 钱包 -->
              <router-link :to="{ name: 'payment' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.payment') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 收货地址 -->
              <router-link :to="{ name: 'address' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.address') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 喜欢列表 -->
              <router-link :to="{ name: 'wishlist' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.wishlist') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 关注列表 -->
              <router-link :to="{ name: 'follow' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.followlist') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 消息中心 -->
              <router-link :to="{ name: 'message' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.message.center') }}
                      <span style="color: var(--main-color2)">({{ msgCenter.msg }})</span>
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 设置 -->
              <router-link :to="{ name: 'setting' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('member.setting') }}
                    </span>
                  </div>
                </div>
              </router-link>
            </template>
            <!-- 商家 -->
            <template v-if="sp_info.type == 2">
              <!-- 仪表盘 -->
              <router-link :to="{ name: 'storeDashboard' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.dashboard') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 产品列表 -->
              <router-link :to="{ name: 'storeProductsList' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.products.list') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 订单列表 -->
              <router-link :to="{ name: 'storeOrderList' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.orders') }}
                      <span style="color: var(--main-color2)">({{ msgCenter.order }})</span>
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 钱包 -->
              <router-link :to="{ name: 'storeWallet' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.wallet') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 商品推广 -->
              <router-link :to="{ name: 'storeAdvertiseList' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('advertise.title') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 商家消息 -->
              <router-link :to="{ name: 'storeMessage' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.message') }}
                      <span style="color: var(--main-color2)">({{ msgCenter.msg }})</span>
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 邀请好友 -->
              <router-link :to="{ name: 'storeShare' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('setting.invitation') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 商家设置 -->
              <router-link :to="{ name: 'storeStore' }">
                <div class="langs-item">
                  <div class="langs-box">
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('store.setting') }}
                    </span>
                  </div>
                </div>
              </router-link>
              <!-- 注销 -->
              <!-- <a @click="onSignout()">
                <div class="langs-item">
                  <div class="langs-box" >
                    <span class="lang-item" style="padding: 0">
                      <span class="comet-icon comet-icon-coins my-account--icon--16yzkFW">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.headerAcount.i7.77f776dbPqhQNm">
                          <path d="M512 938.666667C277.333333 938.666667 85.333333 746.666667 85.333333 512S277.333333 85.333333 512 85.333333s426.666667 192 426.666667 426.666667-192 426.666667-426.666667 426.666667z m0-789.333334C311.466667 149.333333 149.333333 311.466667 149.333333 512s162.133333 362.666667 362.666667 362.666667 362.666667-162.133333 362.666667-362.666667S712.533333 149.333333 512 149.333333z"></path>
                          <path
                            d="M512 721.066667c-27.733333 0-51.2-17.066667-59.733333-42.666667l-27.733334-78.933333-74.666666-25.6c-25.6-8.533333-42.666667-34.133333-42.666667-61.866667 0-27.733333 17.066667-51.2 44.8-59.733333L426.666667 426.666667l23.466666-74.666667c8.533333-27.733333 32-44.8 59.733334-44.8 27.733333 0 51.2 17.066667 59.733333 44.8L597.333333 426.666667l76.8 25.6c25.6 8.533333 42.666667 32 44.8 59.733333 0 27.733333-17.066667 51.2-42.666666 61.866667l-74.666667 25.6-27.733333 76.8c-10.666667 27.733333-34.133333 44.8-61.866667 44.8z m0-347.733334L488.533333 448c-6.4 19.2-21.333333 34.133333-40.533333 40.533333L371.2 512l74.666667 25.6c19.2 6.4 32 21.333333 40.533333 40.533333l27.733333 78.933334 27.733334-76.8c6.4-19.2 21.333333-32 38.4-38.4l74.666666-25.6-76.8-25.6c-19.2-6.4-34.133333-21.333333-40.533333-40.533334L512 373.333333z"
                          ></path>
                        </svg>
                      </span>
                      {{ $t('signup.sign.out') }}
                    </span>
                  </div>
                </div>
              </a> -->
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 注册 -->
  <Signup ref="signup" v-show="isShowSignin" @onCloseSignin="onCloseSignin" @tokenStatus="tokenStatus"></Signup>
</template>

<script>
import { mapState } from 'vuex'
import Signup from '@/components/common/Signup.vue'
export default {
  components: { Signup },
  name: 'HeadVue',
  emits: ['tokenUpdate'],
  data() {
    return {
      isShowSignin: false,

      sp_info: {},
      isLogin: false,

      //是否显示头像
      isAvatar: false
    }
  },
  created() {
    if (this.$route.query.token) {
      let token = this.$route.query.token
      this.onAutoLogin(token)
    } else {
      this.tokenStatus()
    }

    //获取基本信息
    this.$store.dispatch('base/getBase', {})
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  methods: {
    /**
     * 去登陆
     */
    onSignin() {
      this.$refs.signup.onInit(2)
      this.isShowSignin = true
    },
    /**
     * 去注册
     */
    onSignup() {
      this.$refs.signup.onInit(1)
      this.isShowSignin = true
    },
    /**
     * 关闭
     */
    onCloseSignin() {
      this.isShowSignin = false
    },
    onAutoLogin(token) {
      let that = this
      localStorage.setItem(that.$Const.KEY_TOKEN, token)
      that.$api.auth.autoLogin({ token: token }).then((res) => {
        //存入缓存
        let info = res.data
        delete info.token
        localStorage.setItem(that.$Const.KEY_INFO, JSON.stringify(info))
        that.tokenStatus()
        setTimeout(function () {
          that.$router.push({ name: 'index' })
        }, 1000)
      })
    },
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
      this.$emit('tokenStatus', [])
    },
    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem('sp_token')
      localStorage.removeItem('sp_info')
      try {
        this.$socket.logout()
      } catch (err) {
        console.log(err)
      }

      this.tokenStatus()
      // this.$socket.close()
      this.$emit('tokenUpdate', [])
    },
    avatarSuccess() {
      this.isAvatar = true
      // console.log(e, '加载头像成功')
    },
    avatarError() {
      this.isAvatar = false
      // console.log(e, '加载头像失败')
    }
  }
}
</script>

<style scoped src="@/static/css5/index.css"></style>
<style scoped>
.down-box {
  position: relative;
}

.down-box:hover .show-box {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.show-box {
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.13s ease-in-out;
  z-index: 10;
}

.langs-item {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  /* width: 100%; */
  padding: 0px 10px;
  margin: 0 10px;
  border-radius: 10px;
  font-size: 15px;
}

.langs-item:hover,
.tmenu a:hover {
  background: #f6f6f6;
  color: var(--m-color1);
}

.langs-item a,
.tmenu a {
  color: #000;
}

.tmenu .menu-box {
  height: 300px;
  overflow-y: scroll;
}

.my-account--icon--16yzkFW {
  margin-top: -2px;
  margin-right: 6px;
  font-size: 16px;
}
</style>

<style></style>
