<template>
  <!-- 广告图 -->
  <!-- <div class="top-banner-container" v-if="homeTop.id">
    <a href="javascript:" :style="'background-image: url(' + homeTop.banner + ');background-size: 100% 100%;'">&nbsp;</a>
  </div> -->
  <!-- top -->
  <!-- <HeadNav ref="topnav" @tokenStatus="tokenStatus" @onSignout="onSignout" @notify="languageNotify"></HeadNav> -->
  <HeadNav ref="topnav"></HeadNav>
  <div class="index-page" style="-margin-top: 121px">
    <!-- 顶部搜索框  -->
    <!-- <SearchBox></SearchBox> -->

    <!-- 内容 -->
    <!-- <router-view @onSignup="onSignup" @onSignin="onSignin" @signinEvent="signinEvent" v-slot="{ Component }"> -->
    <router-view v-slot="{ Component }">
      <component ref="viewBox" :is="Component" />
    </router-view>
  </div>

  <!-- footer -->
  <!-- <Footer ref="footer" @onLogout="footerToLogout"></Footer> -->
  <Footer ref="footer"></Footer>
</template>

<script>
import Footer from '@/components/v5/head/FooterVue.vue'
import HeadNav from '@/components/v5/head/HeadNav.vue'
import SearchBox from '@/components/common/SearchBox.vue'
export default {
  name: 'IndexLayout',
  components: {
    HeadNav,
    SearchBox,
    Footer
  },
  data() {
    return {
      //顶部活动
      homeTop: {
        id: 0,
        banner: ''
      }
    }
  },
  created() {
    //获取轮播图
    let that = this

    //获取活动
    this.$api.home
      .getActivity({
        key: ['home_top']
      })
      .then((res) => {
        that.homeTop = res.data.home_top
      })
  },
  beforeUnmount() {
    // this.$refs.topnav.clearAjax()
  },
  methods: {
    // signinEvent() {
    //   //转调用HeadNav
    //   this.$refs.topnav.signinEvent()
    // },
    /**
     * 切换语言通知
     */
    // languageNotify(e) {
    //   console.log(e, this.$refs.view, 'index layout')
    //   this.$nextTick(function () {
    //     this.$refs.viewBox.languageNotify(e)
    //   })
    // },
    /**
     * 登录状态
     * 由topnav 内部调用父类触发
     */
    // tokenStatus() {
    //   this.isLogin = this.$common.isLogin()
    //   this.user = this.$common.getInfo()
    //   this.$nextTick(function () {
    //     if (typeof this.$refs.viewBox.tokenStatus === 'function') {
    //       this.$refs.viewBox.tokenStatus()
    //       this.$refs.footer.tokenStatus()
    //     }
    //   })
    // },
    /**
     * 退出登录
     */
    // onSignout() {
    //   this.isLogin = false
    //   this.user = this.$common.getInfo()
    //   this.$nextTick(function () {
    //     if (typeof this.$refs.viewBox.onSignout === 'function') {
    //       this.$refs.viewBox.onSignout()
    //       this.$refs.footer.tokenStatus()
    //     }
    //   })
    // },
    /**
     * 脚部
     */
    // footerToLogout() {
    //   this.$nextTick(function () {
    //     if (typeof this.$refs.topnav.onSignout === 'function') {
    //       this.$refs.topnav.onSignout()
    //     }
    //   })
    // },
    /**
     * 注册
     */
    // onSignup() {
    //   this.$refs.topnav.onSignup()
    // },
    /**
     * 登录
     */
    // onSignin() {
    //   this.$refs.topnav.onSignin()
    // }
  }
}
</script>

<style scoped src="@/static/css/ae-header.css"></style>
