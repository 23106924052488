<template>
  <!-- 客服列表 -->
  <div class="item11 sadfe1esr" v-if="isLogin">
    <span class="tit1">{{ $t('base.server') }} </span>
    <right-outlined style="font-size: 13px; color: var(--m-color1)" />
    <!-- <i class="iconfont ic_arrow_down_14" style="color: #fff"></i> -->
    <div class="nav-show1">
      <ul class="nav-show-list1">
        <li class="item1">
          <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''">
            <div class="ng-item ng-mobile icon-ant" style="width: 100%">
              <IconWhatsApp />
              WhatsApp
            </div>
          </a>
        </li>
        <li class="item1">
          <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''">
            <div class="ng-item ng-mobile icon-ant" style="width: 100%">
              <IconTelegram />
              Telegram
            </div>
          </a>
        </li>
        <li class="item1">
          <a v-if="sp_info.line" :href="sp_info.line" :target="sp_info.line ? '_blank' : ''">
            <div class="ng-item ng-mobile icon-ant" style="width: 100%">
              <IconLine />
              line
            </div>
          </a>
          <div class="line-qrcode1" v-else-if="sp_info.line_qrcode">
            <div class="ng-item ng-mobile icon-ant" style="width: 100%; position: relative">
              <IconLine />
              Line
              <div class="line-box1">
                <img :src="sp_info.line_qrcode + '?r=' + Math.random() * 1000" alt="line" />
              </div>
            </div>
          </div>
          <a v-else href="javascript:">
            <div class="ng-item ng-mobile icon-ant" style="width: 100%">
              <IconLine />
              Line
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { RightOutlined } from '@ant-design/icons-vue'
export default {
  name: 'CustomerVue',
  components: {
    RightOutlined
  },
  data() {
    return {
      sp_info: {},
      isLogin: false
    }
  },
  created() {
    this.tokenStatus()
  },
  methods: {
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
      this.$emit('tokenStatus', [])
    }
  }
}
</script>

<style>
.ic_arrow_down_14:before {
  content: '\e640';
}

.tit1 {
  color: #2d2d2d;
  font-size: 12px;
  margin-right: 4px;
}

.item11 {
  display: flex;
  align-items: center;
  cursor: default;
  margin-right: 24px;
  position: relative;
}

.item11:hover .nav-show1 {
  overflow: visible;
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.nav-show1 {
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: 33px;
  transition: all 0.15s ease-in-out;
  transform: translateY(5px);
  opacity: 0;
  visibility: hidden;
  width: 170px;
  z-index: 98;
}

.nav-show-list1 {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  padding: 10px 0;
}

.item1 {
  height: 32px;
  line-height: 32px;
  width: 100%;
}

.nav-show-list1 .item1 a {
  display: block;
  height: 100%;
  width: 100%;
}
.nav-show-list1 .item1 > a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-show-list1 a,
.nav-show-list1 .line-qrcode1 {
  cursor: pointer;
  font-size: 12px;
  line-height: 32px;
  padding: 0 20px;
}

.nav-show-list1 li {
  list-style: none;
}
.nav-show-list1 .item1:hover {
  background: #f6f6f6;
}

.sadfe1esr {
  height: 30px;
  background: #5fd5b64f;
  padding: 0 10px;
  border-radius: 5px;
  line-height: 14px;
}

.line-box1 {
  position: absolute;
  background: #fff;
  padding: 10px;
  top: 50px;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
